import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { GET, POST, DELETE, LIST, PATCH } from "../api";

import {
  AUTO_ITEMS_REQUEST,
  deleteSupplierError,
  deleteSupplierSuccess,
  DELETE_MEDICINE_CATEGORY_REQUEST,
  DELETE_MEDICINE_DOSAGE_REQUEST,
  DELETE_SUPPLIER_REQUEST,
  manageMedicineCategoryError,
  manageMedicineCategorySuccess,
  MANAGE_MEDICINE_CATEGORY_REQUEST,
  MANAGE_MEDICINE_DOSAGE_REQUEST,
  MANAGE_SUPPLIER_REQUEST,
  MEDICINE_CATEGORY_LIST_REQUEST,
  MEDICINE_DOSAGE_LIST_REQUEST,
  supplierDetailsError,
  supplierDetailsSuccess,
  SUPPLIER_DETAILS_REQUEST,
  SUPPLIER_LIST_REQUEST,
  ADMIN_UTILS_LIST_REQUEST,
  adminUtilsSuccess,
  adminUtilsError,
  CLIENT_UTILS_LIST_REQUEST,
  CLEAR_ALL_DEFAULTS,
  clearUserDefaults,
  AUTO_SUGGEST_REQUEST,
  MANAGE_ITEM_CATEGORY_RQUEST,
  manageItemCategoryError,
  manageItemCategorySuccess,
  ITEM_CATEGORY_LIST_REQUEST,
  itemCategoryListError,
  itemCategoryListSuccess,
  MANAGE_LEAVETYPE_REQUEST,
  MANAGE_DEPARTMENT_REQUEST,
  MANAGE_DESIGNATION_REQUEST,
  LEAVETYPE_LIST_REQUEST,
  DEPARTMENT_LIST_REQUEST,
  DESIGNATION_LIST_REQUEST,
  MANAGE_SPECIALIZATION_REQUEST,
  SPECIALIZATION_LIST_REQUEST,
  REQUEST,
} from "../actions";
import {
  autoItemsError,
  autoItemsSuccess,
  autoSuggestError,
  autoSuggestSuccess,
  clearSetupDefaults,
  clientUtilsError,
  clientUtilsSuccess,
  deleteMedicineCategoryError,
  deleteMedicineCategorySuccess,
  deleteMedicineDosageError,
  deleteMedicineDosageSuccess,
  departmentListError,
  departmentListSuccess,
  designationListError,
  designationListSuccess,
  leaveTypeListError,
  leaveTypeListSuccess,
  manageDepartmentError,
  manageDepartmentSuccess,
  manageDesignationError,
  manageDesignationSuccess,
  manageLeaveTypeError,
  manageLeaveTypeSuccess,
  manageMedicineDosageError,
  manageMedicineDosageSuccess,
  manageSpecializationError,
  manageSpecializationSuccess,
  manageSupplierError,
  manageSupplierSuccess,
  medicineCategoryListError,
  medicineCategoryListSuccess,
  medicineDosageListError,
  medicineDosageListSuccess,
  setRequestSuccess,
  specializationListError,
  specializationListSuccess,
  supplierListError,
  supplierListSuccess,
} from "./action";
import { clearInventoryDefaults } from "../inventory/action";
import { clearPatientDefaults } from "../patient/action";
import { clearPharmacyDefaults } from "../pharmacy/action";
import { clearStaffDefaults } from "../staff/action";
import { clearSupplierDefaults } from "../supplier/action";
import { clearDoctorDefaults } from "../doctor/action";
export function* watchRequest() {
  yield takeLatest(REQUEST, request);
}
function* request({ payload }) {
  yield put(setRequestSuccess(payload));
}

export function* watchManageMedicineCategory() {
  yield takeLatest(MANAGE_MEDICINE_CATEGORY_REQUEST, manageMedicineCategory);
}

function* manageMedicineCategory({ payload }) {
  const url = "/setup/medicinecategory";
  try {
    const response = yield call(payload.id > 0 ? PATCH : POST, url, payload);
    if (response && response.is_error) {
      yield put(manageMedicineCategoryError(response.message));
    } else {
      yield put(manageMedicineCategorySuccess(response));
    }
  } catch (error) {
    yield put(manageMedicineCategoryError(error));
  }
}

export function* watchDeleteMedicineCategory() {
  yield takeLatest(DELETE_MEDICINE_CATEGORY_REQUEST, deleteMedicineCategory);
}

function* deleteMedicineCategory({ payload }) {
  const url = `/setup/medicinecategory/${payload.id}`;
  try {
    const response = yield call(DELETE, url);
    if (response && response.is_error) {
      yield put(deleteMedicineCategoryError(response.message));
    } else {
      yield put(deleteMedicineCategorySuccess(response));
    }
  } catch (error) {
    yield put(deleteMedicineCategoryError(error));
  }
}

export function* watchMedicineCategoryList() {
  yield takeLatest(MEDICINE_CATEGORY_LIST_REQUEST, medicineCategoryList);
}

function* medicineCategoryList({ payload }) {
  try {
    const url = "/setup/medicinecategory";
    const response = yield call(LIST, url);
    if (response && response.is_error) {
      yield put(medicineCategoryListError(response.message));
    } else {
      yield put(medicineCategoryListSuccess(response));
    }
  } catch (error) {
    yield put(medicineCategoryListError(error));
  }
}

export function* watchManageMedicineSuppier() {
  yield takeLatest(MANAGE_SUPPLIER_REQUEST, manageMedicineSupplier);
}

function* manageMedicineSupplier({ payload }) {
  const url = `/setup/ClientSupplier`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageSupplierError(response.message));
    } else {
      yield put(manageSupplierSuccess(response));
    }
  } catch (error) {
    yield put(manageSupplierError(error));
  }
}

export function* watchDeleteSupplier() {
  yield takeLatest(DELETE_SUPPLIER_REQUEST, deleteSupplier);
}

function* deleteSupplier({ payload }) {
  const url = `/setup/DeleteClientSupplier/${payload}`;
  try {
    const response = yield call(PATCH, url);
    if (response && response.is_error) {
      yield put(deleteSupplierError(response.message));
    } else {
      yield put(deleteSupplierSuccess(response));
    }
  } catch (error) {
    yield put(deleteSupplierError(error));
  }
}

export function* watcheMedicineSupplierList() {
  yield takeLatest(SUPPLIER_LIST_REQUEST, medicineSupplierList);
}

function* medicineSupplierList({ payload }) {
  const url = `/setup/ClientSupplier`;
  try {
    const response = yield call(LIST, url);
    if (response && response.is_error) {
      yield put(supplierListError(response.message));
    } else {
      yield put(supplierListSuccess(response));
    }
  } catch (error) {
    yield put(supplierListError(error));
  }
}

export function* watchSupplierDetails() {
  yield takeLatest(SUPPLIER_DETAILS_REQUEST, supplierDetails);
}

function* supplierDetails({ payload }) {
  const url = `/setup/ClientSupplier/${payload}`;
  try {
    const response = yield call(GET, url);
    if (response && response.is_error) {
      yield put(supplierDetailsError(response.message));
    } else {
      yield put(supplierDetailsSuccess(response));
    }
  } catch (error) {
    yield put(supplierDetailsError(error));
  }
}

export function* watchmanageMedicineDosage() {
  yield takeLatest(MANAGE_MEDICINE_DOSAGE_REQUEST, manageMedicineDosage);
}

function* manageMedicineDosage({ payload }) {
  const url = `/setup/medicinedosage`;
  try {
    const response = yield call(payload.id > 0 ? PATCH : POST, url, payload);
    if (response && response.is_error) {
      yield put(manageMedicineDosageError(response.message));
    } else {
      yield put(manageMedicineDosageSuccess(response));
    }
  } catch (error) {
    yield put(manageMedicineDosageError(error));
  }
}

export function* watchDeleteMedicineDosage() {
  yield takeLatest(DELETE_MEDICINE_DOSAGE_REQUEST, deleteMedicineDosage);
}

function* deleteMedicineDosage({ payload }) {
  const url = `/setup/medicinedosage/${payload.id}`;
  try {
    const response = yield call(DELETE, url);
    if (response && response.is_error) {
      yield put(deleteMedicineDosageError(response.message));
    } else {
      yield put(deleteMedicineDosageSuccess(response));
    }
  } catch (error) {
    yield put(deleteMedicineDosageError(error));
  }
}

export function* watcheMedicineDosageList() {
  yield takeLatest(MEDICINE_DOSAGE_LIST_REQUEST, medicineDosageList);
}

function* medicineDosageList({ payload }) {
  const url = `/setup/medicinedosage`;
  try {
    const response = yield call(LIST, url);
    if (response && response.is_error) {
      yield put(medicineDosageListError(response.message));
    } else {
      yield put(medicineDosageListSuccess(response));
    }
  } catch (error) {
    yield put(medicineDosageListError(error));
  }
}

export function* watchAdminUtils() {
  yield takeLatest(ADMIN_UTILS_LIST_REQUEST, adminUtilsList);
}

function* adminUtilsList({ payload }) {
  const url = `/utils/admin`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(adminUtilsError(response.message));
    } else {
      yield put(adminUtilsSuccess(response));
    }
  } catch (error) {
    yield put(adminUtilsError(error));
  }
}

export function* watchClientUtils() {
  yield takeLatest(CLIENT_UTILS_LIST_REQUEST, clientUtilsList);
}

function* clientUtilsList({ payload }) {
  const url = `/utils/client`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(clientUtilsError(response.message));
    } else {
      yield put(clientUtilsSuccess(response));
    }
  } catch (error) {
    yield put(clientUtilsError(error));
  }
}

export function* watchAutoItems() {
  yield takeLatest(AUTO_ITEMS_REQUEST, autoItems);
}

function* autoItems({ payload }) {
  if (payload) {
    const url = `/utils/autosuggest?search=${payload}&type=items`;
    try {
      const response = yield call(GET, url);

      if (response && response.is_error) {
        yield put(autoItemsError(response.message));
      } else {
        response.map(async (x, i) => {
          x["label"] = x.item_name;
          x["value"] = x.item_id;
          await x;
        });
        yield put(autoItemsSuccess(response));
      }
    } catch (error) {
      yield put(autoItemsError(error));
    }
  }
}

export function* watchAutoSuggest() {
  yield takeLatest(AUTO_SUGGEST_REQUEST, autoSuggest);
}

function* autoSuggest({ payload }) {
  if (payload) {
    const url = `/utils/autosuggest?search=${payload.search}&type=${payload.type}`;
    try {
      const response = yield call(GET, url);
      if (response && response.is_error) {
        yield put(autoSuggestError(response.message));
      } else {
        yield put(autoSuggestSuccess(response));
      }
    } catch (error) {
      yield put(autoSuggestError(error));
    }
  }
}

export function* watchmanageItemCategory() {
  yield takeLatest(MANAGE_ITEM_CATEGORY_RQUEST, manageItemCategory);
}

function* manageItemCategory({ payload }) {
  const url = `/setup/itemcategory`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageItemCategoryError(response.message));
    } else {
      yield put(manageItemCategorySuccess(response));
    }
  } catch (error) {
    yield put(manageItemCategoryError(error));
  }
}

export function* watchmanageItemCategoryList() {
  yield takeLatest(ITEM_CATEGORY_LIST_REQUEST, itemCategoryList);
}

function* itemCategoryList({ payload }) {
  const url = `/setup/itemcategory`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(itemCategoryListError(response.message));
    } else {
      yield put(itemCategoryListSuccess(response));
    }
  } catch (error) {
    yield put(itemCategoryListError(error));
  }
}

export function* watchManageLeaveType() {
  yield takeLatest(MANAGE_LEAVETYPE_REQUEST, manageLeaveType);
}

function* manageLeaveType({ payload }) {
  const url = `/setup/leavetype`;
  try {
    const response = yield call(
      payload && payload.leave_type_id > 0 ? PATCH : POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageLeaveTypeError(response.message));
    } else {
      yield put(manageLeaveTypeSuccess(response));
    }
  } catch (error) {
    yield put(manageLeaveTypeError(error));
  }
}

export function* watchLeaveTypeList() {
  yield takeLatest(LEAVETYPE_LIST_REQUEST, leaveTypeList);
}

function* leaveTypeList({ payload }) {
  const url = `/setup/leavetype`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(leaveTypeListError(response.message));
    } else {
      yield put(leaveTypeListSuccess(response));
    }
  } catch (error) {
    yield put(leaveTypeListError(error));
  }
}

export function* watchManageDepartment() {
  yield takeLatest(MANAGE_DEPARTMENT_REQUEST, manageDepartment);
}

function* manageDepartment({ payload }) {
  const url = `/setup/department`;
  try {
    const response = yield call(
      payload && payload.department_id > 0 ? PATCH : POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageDepartmentError(response.message));
    } else {
      yield put(manageDepartmentSuccess(response));
    }
  } catch (error) {
    yield put(manageDepartmentError(error));
  }
}

export function* watchDepartmentsList() {
  yield takeLatest(DEPARTMENT_LIST_REQUEST, departmentList);
}

function* departmentList({ payload }) {
  const url = `/setup/department`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(departmentListError(response.message));
    } else {
      yield put(departmentListSuccess(response));
    }
  } catch (error) {
    yield put(departmentListError(error));
  }
}

export function* watchManageDesignation() {
  yield takeLatest(MANAGE_DESIGNATION_REQUEST, manageDesignation);
}

function* manageDesignation({ payload }) {
  const url = `/setup/designation`;
  try {
    const response = yield call(
      payload && payload.designation_id > 0 ? PATCH : POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageDesignationError(response.message));
    } else {
      yield put(manageDesignationSuccess(response));
    }
  } catch (error) {
    yield put(manageDesignationError(error));
  }
}

export function* watchDesignationList() {
  yield takeLatest(DESIGNATION_LIST_REQUEST, designationList);
}

function* designationList({ payload }) {
  const url = `/setup/designation`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(designationListError(response.message));
    } else {
      yield put(designationListSuccess(response));
    }
  } catch (error) {
    yield put(designationListError(error));
  }
}

export function* watchManageSpecialization() {
  yield takeLatest(MANAGE_SPECIALIZATION_REQUEST, manageSpecialization);
}

function* manageSpecialization({ payload }) {
  const url = `/setup/specialization`;
  try {
    const response = yield call(
      payload && payload.spec_id > 0 ? PATCH : POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageSpecializationError(response.message));
    } else {
      yield put(manageSpecializationSuccess(response));
    }
  } catch (error) {
    yield put(manageSpecializationError(error));
  }
}

export function* watchSpecializationList() {
  yield takeLatest(SPECIALIZATION_LIST_REQUEST, specializationList);
}

function* specializationList({ payload }) {
  const url = `/setup/specialization`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(specializationListError(response.message));
    } else {
      yield put(specializationListSuccess(response));
    }
  } catch (error) {
    yield put(specializationListError(error));
  }
}

export function* watchClearDefaults() {
  yield takeLatest(CLEAR_ALL_DEFAULTS, clearAllDefaults);
}

function* clearAllDefaults({ payload }) {
  yield put(clearInventoryDefaults());
  yield put(clearPatientDefaults());
  yield put(clearPharmacyDefaults());
  yield put(clearSetupDefaults());
  yield put(clearStaffDefaults());
  yield put(clearSupplierDefaults());
  yield put(clearUserDefaults());
  yield put(clearDoctorDefaults());
}

export default function* rootSaga() {
  yield all([
    fork(watchRequest),
    fork(watchManageMedicineCategory),
    fork(watchMedicineCategoryList),
    fork(watchDeleteMedicineCategory),
    fork(watchManageMedicineSuppier),
    fork(watchDeleteSupplier),
    fork(watcheMedicineSupplierList),
    fork(watchmanageMedicineDosage),
    fork(watchDeleteMedicineDosage),
    fork(watcheMedicineDosageList),
    fork(watchAdminUtils),
    fork(watchClientUtils),
    fork(watchAutoItems),
    fork(watchSupplierDetails),
    fork(watchClearDefaults),
    fork(watchAutoSuggest),
    fork(watchmanageItemCategory),
    fork(watchmanageItemCategoryList),
    fork(watchManageLeaveType),
    fork(watchManageDepartment),
    fork(watchManageDesignation),
    fork(watchLeaveTypeList),
    fork(watchDepartmentsList),
    fork(watchDesignationList),
    fork(watchManageSpecialization),
    fork(watchSpecializationList),
  ]);
}
