import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "../src/assets/vendor/fontawesome/css/font-awesome.css";
import "../src/assets/vendor/metisMenu/dist/metisMenu.css";
import "../src/assets/vendor/animate.css/animate.css";
import "../src/assets/vendor/bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/fonts/pe-icon-7-stroke/css/pe-icon-7-stroke.css";
import "../src/assets/fonts/pe-icon-7-stroke/css/helper.css";
import "react-notifications/lib/notifications.css";
import "../src/assets/styles/hms.css";
import "../src/assets/styles/style.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { configureStore } from "./redux/store";
import { Provider } from "react-redux";
import axios from "axios";

if (window.location.hostname == "localhost") {
  axios.defaults.baseURL = "http://localhost:2020/api/v1";
} else if (window.location.hostname == "dev.ideapharmacy.in") {
  // axios.defaults.baseURL = "http://localhost:2020/api/v1";
  axios.defaults.baseURL = "https://devapi.ideapharmacy.in/api/v1";
} else {
  axios.defaults.baseURL = "https://offlineapi.ideapharmacy.in/api/v1";
}

ReactDOM.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<div className="loading" />}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
