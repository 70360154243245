import {
  AUTO_MEDICINE_SUGGEST_ERROR,
  AUTO_MEDICINE_SUGGEST_REQUEST,
  AUTO_MEDICINE_SUGGEST_SUCCESS,
  AUTO_SUGGEST_ERROR,
  AUTO_SUGGEST_REQUEST,
  AUTO_SUGGEST_SUCCESS,
  BATCHNO_LIST_ERROR,
  BATCHNO_LIST_REQUEST,
  BATCHNO_LIST_SUCCESS,
  BILL_BASIC_LIST_ERROR,
  BILL_BASIC_LIST_REQUEST,
  BILL_BASIC_LIST_SUCCESS,
  BILL_ERROR,
  BILL_REQUEST,
  BILL_SUCCESS,
  CLEAR_BATCHNO_LIST,
  CLEAR_PHARMACY_DEFAULTS,
  DELETE_MEDICINE_ERROR,
  DELETE_MEDICINE_REQUEST,
  DELETE_MEDICINE_SUCCESS,
  EXPIRY_DATE_ERROR,
  EXPIRY_DATE_REQUEST,
  EXPIRY_DATE_SUCCESS,
  MANAGE_BILL_ERROR,
  MANAGE_BILL_REQUEST,
  MANAGE_BILL_SUCCESS,
  MANAGE_CASHSUMMARY_ERROR,
  MANAGE_CASHSUMMARY_REQUEST,
  MANAGE_CASHSUMMARY_SUCCESS,
  MANAGE_MEDICINE_ERROR,
  MANAGE_MEDICINE_REQUEST,
  MANAGE_MEDICINE_SUCCESS,
  MANAGE_ORDER_STATUS_ERORR,
  MANAGE_ORDER_STATUS_REQUEST,
  MANAGE_ORDER_STATUS_SUCCESS,
  MANAGE_PHARMACY_RETURN_ERROR,
  MANAGE_PHARMACY_RETURN_REQUEST,
  MANAGE_PHARMACY_RETURN_SUCCESS,
  MANAGE_PURCHASE_ERROR,
  MANAGE_PURCHASE_REQUEST,
  MANAGE_PURCHASE_SUCCESS,
  MANAGE_REPORT_OVERVIEW_ERROR,
  MANAGE_REPORT_OVERVIEW_REQUEST,
  MANAGE_REPORT_OVERVIEW_SUCCESS,
  MEDICINE_ERROR,
  MEDICINE_LIST_ERROR,
  MEDICINE_LIST_REQUEST,
  MEDICINE_LIST_SUCCESS,
  MEDICINE_REQUEST,
  MEDICINE_SUCCESS,
  PURCHASE_BASIC_LIST_ERROR,
  PURCHASE_BASIC_LIST_REQUEST,
  PURCHASE_BASIC_LIST_SUCCESS,
  PURCHASE_ERROR,
  PURCHASE_REQUEST,
  PURCHASE_SUCCESS,
  SUPPLIER_TRACKING_ERROR,
  SUPPLIER_TRACKING_REQUEST,
  SUPPLIER_TRACKING_SUCCESS,
} from "../actions";

const INIT_STATE = {
  frontdesk_loading: false,
  frontdesk_error: null,
  frontdesk_success: null,
  frontdesk_action: null,
  frontdesk_request_type: null,
  pharmacy_loading: false,
  pharmacy_error: null,
  pharmacy_success: null,
  medicines: [],
  auto_medicines: [],
  batchno_list: [],
  expiry_details: null,
  bill_list: [],
  bill_info: null,
  purchase_list: [],
  purchase: null,
  medicine: null,
  pharmacy_action: null,
  tracking_history: [],
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_PHARMACY_DEFAULTS:
      return {
        ...state,
        pharmacy_loading: false,
        pharmacy_error: null,
        pharmacy_success: null,
        pharmacy_action: null,
      };
    case MANAGE_MEDICINE_REQUEST:
      return {
        ...state,
        pharmacy_loading: true,
        pharmacy_error: null,
        pharmacy_success: null,
        pharmacy_action: action.type,
      };
    case MANAGE_MEDICINE_SUCCESS:
      return {
        ...state,
        pharmacy_loading: false,
        pharmacy_success: action.payload,
      };

    case MANAGE_MEDICINE_ERROR:
      return {
        ...state,
        pharmacy_loading: false,
        pharmacy_error: action.payload,
      };
    case DELETE_MEDICINE_REQUEST:
      return {
        ...state,
        pharmacy_loading: true,
        pharmacy_error: null,
        pharmacy_success: null,
        pharmacy_action: action.type,
      };
    case DELETE_MEDICINE_SUCCESS:
      return {
        ...state,
        pharmacy_loading: false,
        pharmacy_success: action.payload,
      };
    case DELETE_MEDICINE_ERROR:
      return {
        ...state,
        pharmacy_loading: false,
        pharmacy_error: action.payload,
      };
    case MEDICINE_LIST_REQUEST:
      return {
        ...state,
        pharmacy_loading: true,
        pharmacy_error: null,
        pharmacy_success: null,
        medicines: [],
        pharmacy_action: action.type,
      };
    case MEDICINE_LIST_SUCCESS:
      return {
        ...state,
        pharmacy_loading: false,
        medicines: action.payload,
      };
    case MEDICINE_LIST_ERROR:
      return {
        ...state,
        pharmacy_loading: false,
        pharmacy_error: action.payload,
      };

    case MANAGE_PURCHASE_REQUEST:
      return {
        ...state,
        pharmacy_loading: true,
        pharmacy_error: null,
        pharmacy_success: null,
        pharmacy_action: action.type,
      };
    case MANAGE_PURCHASE_SUCCESS:
      return {
        ...state,
        pharmacy_loading: false,
        pharmacy_success: action.payload,
      };

    case MANAGE_PURCHASE_ERROR:
      return {
        ...state,
        pharmacy_loading: false,
        pharmacy_error: action.payload,
      };

    case AUTO_MEDICINE_SUGGEST_REQUEST:
      return {
        ...state,
        pharmacy_loading: true,
        pharmacy_error: null,
        pharmacy_success: null,
        auto_medicines: [],
        pharmacy_action: action.type,
      };
    case AUTO_MEDICINE_SUGGEST_SUCCESS:
      return {
        ...state,
        pharmacy_loading: false,
        auto_medicines: action.payload,
      };
    case AUTO_MEDICINE_SUGGEST_ERROR:
      return {
        ...state,
        pharmacy_loading: false,
        pharmacy_error: action.payload,
      };

    case BATCHNO_LIST_REQUEST:
      return {
        ...state,
        pharmacy_loading: true,
        pharmacy_error: null,
        pharmacy_success: null,
        batchno_list: [],
        pharmacy_action: action.type,
      };
    case BATCHNO_LIST_SUCCESS:
      return {
        ...state,
        pharmacy_loading: false,
        batchno_list: action.payload,
      };
    case CLEAR_BATCHNO_LIST:
      return {
        ...state,
        pharmacy_loading: false,
        batchno_list: [],
      };
    case BATCHNO_LIST_ERROR:
      return {
        ...state,
        pharmacy_loading: false,
        pharmacy_error: action.payload,
      };

    case EXPIRY_DATE_REQUEST:
      return {
        ...state,
        pharmacy_loading: true,
        pharmacy_error: null,
        pharmacy_success: null,
        expiry_details: null,
        pharmacy_action: action.type,
      };
    case EXPIRY_DATE_SUCCESS:
      return {
        ...state,
        pharmacy_loading: false,
        expiry_details: action.payload,
      };
    case EXPIRY_DATE_ERROR:
      return {
        ...state,
        pharmacy_loading: false,
        pharmacy_error: action.payload,
      };

    case MANAGE_BILL_REQUEST:
      return {
        ...state,
        pharmacy_loading: true,
        pharmacy_error: null,
        pharmacy_success: null,
        pharmacy_action: action.type,
      };
    case MANAGE_BILL_SUCCESS:
      return {
        ...state,
        pharmacy_loading: false,
        pharmacy_success: action.payload,
      };
    case MANAGE_BILL_ERROR:
      return {
        ...state,
        pharmacy_loading: false,
        pharmacy_error: action.payload,
      };

    case BILL_BASIC_LIST_REQUEST:
      return {
        ...state,
        pharmacy_loading: true,
        pharmacy_error: null,
        pharmacy_success: null,
        bill_list: [],
        pharmacy_action: action.type,
      };
    case BILL_BASIC_LIST_SUCCESS:
      return {
        ...state,
        pharmacy_loading: false,
        bill_list: action.payload,
      };
    case BILL_BASIC_LIST_ERROR:
      return {
        ...state,
        pharmacy_loading: false,
        pharmacy_error: action.payload,
      };
    case BILL_REQUEST:
      return {
        ...state,
        pharmacy_loading: true,
        pharmacy_error: null,
        pharmacy_success: null,
        bill_info: null,
        pharmacy_action: action.type,
      };
    case BILL_SUCCESS:
      return {
        ...state,
        pharmacy_loading: false,
        bill_info: action.payload,
      };
    case BILL_ERROR:
      return {
        ...state,
        pharmacy_loading: false,
        pharmacy_error: action.payload,
      };

    case PURCHASE_BASIC_LIST_REQUEST:
      return {
        ...state,
        pharmacy_loading: true,
        pharmacy_error: null,
        pharmacy_success: null,
        purchase_list: [],
        pharmacy_action: action.type,
      };
    case PURCHASE_BASIC_LIST_SUCCESS:
      return {
        ...state,
        pharmacy_loading: false,
        purchase_list: action.payload,
      };
    case PURCHASE_BASIC_LIST_ERROR:
      return {
        ...state,
        pharmacy_loading: false,
        pharmacy_error: action.payload,
      };
    case PURCHASE_REQUEST:
      return {
        ...state,
        pharmacy_loading: true,
        pharmacy_error: null,
        pharmacy_success: null,
        purchase: null,
        pharmacy_action: action.type,
      };
    case PURCHASE_SUCCESS:
      return {
        ...state,
        pharmacy_loading: false,
        purchase: action.payload,
      };
    case PURCHASE_ERROR:
      return {
        ...state,
        pharmacy_loading: false,
        pharmacy_error: action.payload,
      };
    case MEDICINE_REQUEST:
      return {
        ...state,
        pharmacy_loading: true,
        pharmacy_error: null,
        pharmacy_success: null,
        medicine: null,
        pharmacy_action: action.type,
      };
    case MEDICINE_SUCCESS:
      return {
        ...state,
        pharmacy_loading: false,
        medicine: action.payload,
      };
    case MEDICINE_ERROR:
      return {
        ...state,
        pharmacy_loading: false,
        pharmacy_error: action.payload,
      };

    case SUPPLIER_TRACKING_REQUEST:
      return {
        ...state,
        pharmacy_loading: true,
        pharmacy_error: null,
        pharmacy_success: null,
        pharmacy_action: action.type,
        tracking_history: [],
      };
    case SUPPLIER_TRACKING_SUCCESS:
      return {
        ...state,
        pharmacy_loading: false,
        tracking_history: action.payload,
      };
    case SUPPLIER_TRACKING_ERROR:
      return {
        ...state,
        pharmacy_loading: false,
        pharmacy_error: action.payload,
      };

    case MANAGE_PHARMACY_RETURN_REQUEST:
      return {
        ...state,
        pharmacy_loading: true,
        pharmacy_error: null,
        pharmacy_success: null,
        pharmacy_action: action.type,
      };
    case MANAGE_PHARMACY_RETURN_SUCCESS:
      return {
        ...state,
        pharmacy_loading: false,
        pharmacy_success: action.payload,
      };
    case MANAGE_PHARMACY_RETURN_ERROR:
      return {
        ...state,
        pharmacy_loading: false,
        pharmacy_error: action.payload,
      };
    case MANAGE_REPORT_OVERVIEW_REQUEST:
      return {
        ...state,
        frontdesk_loading: true,
        frontdesk_error: null,
        frontdesk_success: null,
        frontdesk_action: action.type,
        frontdesk_request_type: action.request_type,
      };
    case MANAGE_REPORT_OVERVIEW_SUCCESS:
      if (action.request_type == "OVERVIEW") {
        return {
          ...state,
          frontdesk_loading: false,
          frontdesk_overview: action.payload,
        };
      } else if (action.request_type == "REPORT_LIST") {
        return {
          ...state,
          frontdesk_loading: false,
          report_list: action.payload,
        };
      }
    case MANAGE_REPORT_OVERVIEW_ERROR:
      return {
        ...state,
        frontdesk_loading: false,
        frontdesk_error: action.payload,
      };

    case MANAGE_CASHSUMMARY_REQUEST:
      return {
        ...state,
        frontdesk_loading: true,
        frontdesk_error: null,
        frontdesk_success: null,
        frontdesk_action: action.type,
        frontdesk_request_type: action.request_type,
      };
    case MANAGE_CASHSUMMARY_SUCCESS:
      if (action.request_type == "ADD") {
        return {
          ...state,
          frontdesk_loading: false,
          frontdesk_success: action.payload,
        };
      } else if (action.request_type == "EDIT") {
        return {
          ...state,
          frontdesk_loading: false,
          frontdesk_success: action.payload,
        };
      } else if (action.request_type == "GET") {
        return {
          ...state,
          frontdesk_loading: false,
          cash_summary: action.payload,
        };
      } else if (action.request_type == "LIST") {
        return {
          ...state,
          frontdesk_loading: false,
          cash_summary_list: action.payload,
        };
      }
    case MANAGE_CASHSUMMARY_ERROR:
      return {
        ...state,
        frontdesk_loading: false,
        frontdesk_error: action.payload,
      };

    default:
      return { ...state };
  }
};
