import {
  MANAGE_SUPPLIER_INVOICE_ERROR,
  MANAGE_SUPPLIER_INVOICE_REQUEST,
  MANAGE_SUPPLIER_INVOICE_SUCCESS,
  MANAGE_SUPPLIER_PAYMENT_ERROR,
  MANAGE_SUPPLIER_PAYMENT_REQUEST,
  MANAGE_SUPPLIER_PAYMENT_SUCCESS,
  MANAGE_SUPPLIER_DELIVERY_ERROR,
  MANAGE_SUPPLIER_DELIVERY_REQUEST,
  MANAGE_SUPPLIER_DELIVERY_SUCCESS,
  MANAGE_SUPPLIER_ORDER_ERROR,
  MANAGE_SUPPLIER_ORDER_REQUEST,
  MANAGE_SUPPLIER_ORDER_SUCCESS,
  SUPPLIER_ORDER_ERROR,
  SUPPLIER_ORDER_LIST_ERROR,
  SUPPLIER_ORDER_LIST_REQUEST,
  SUPPLIER_ORDER_LIST_SUCCESS,
  SUPPLIER_ORDER_REQUEST,
  SUPPLIER_ORDER_SUCCESS,
  MANAGE_SUPPLIER_QUOTATION_ERROR,
  MANAGE_SUPPLIER_QUOTATION_SUCCESS,
  MANAGE_SUPPLIER_QUOTATION_REQUEST,
  SUPPLIER_ORDER_ITEMS_REQUEST,
  SUPPLIER_ORDER_ITEMS_SUCCESS,
  MANAGE_DELIVERY_HISTORY_REQUEST,
  MANAGE_DELIVERY_HISTORY_SUCCESS,
  MANAGE_DELIVERY_HISTORY_ERROR,
  MANAGE_ORDER_STATUS_REQUEST,
  MANAGE_ORDER_STATUS_SUCCESS,
  MANAGE_ORDER_STATUS_ERORR,
  MANAGE_QUOTATION_STATUS_REQUEST,
  MANAGE_QUOTATION_STATUS_SUCCESS,
  MANAGE_QUOTATION_STATUS_ERROR,
  MANAGE_INVOICE_STATUS_REQUEST,
  MANAGE_INVOICE_STATUS_SUCCESS,
  MANAGE_INVOICE_STATUS_ERROR,
  MANAGE_DELIVER_STATUS_REQUEST,
  MANAGE_DELIVER_STATUS_SUCCESS,
  MANAGE_DELIVER_STATUS_ERROR,
  CLEAR_SUPPLIER_DEFAULTS,
} from "../actions";

const INIT_STATE = {
  supplier_loading: false,
  supplier_error: null,
  supplier_success: null,
  supplier_action: null,
  supplier_order_list: [],
  order_items: null,
  utils: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_SUPPLIER_DEFAULTS:
      return {
        ...state,
        supplier_loading: false,
        supplier_error: null,
        supplier_success: null,
        supplier_action: null,
      };
    case MANAGE_SUPPLIER_INVOICE_REQUEST:
      return {
        ...state,
        supplier_loading: true,
        supplier_error: null,
        supplier_success: null,
        supplier_action: action.type,
      };
    case MANAGE_SUPPLIER_INVOICE_SUCCESS:
      return {
        ...state,
        supplier_loading: false,
        supplier_success: action.payload,
      };

    case MANAGE_SUPPLIER_INVOICE_ERROR:
      return {
        ...state,
        supplier_loading: false,
        supplier_error: action.payload,
      };

    case MANAGE_SUPPLIER_PAYMENT_REQUEST:
      return {
        ...state,
        supplier_loading: true,
        supplier_error: null,
        supplier_success: null,
        supplier_action: action.type,
      };
    case MANAGE_SUPPLIER_PAYMENT_SUCCESS:
      return {
        ...state,
        supplier_loading: false,
        supplier_success: action.payload,
      };

    case MANAGE_SUPPLIER_PAYMENT_ERROR:
      return {
        ...state,
        supplier_loading: false,
        supplier_error: action.payload,
      };
    case MANAGE_SUPPLIER_ORDER_REQUEST:
      return {
        ...state,
        supplier_loading: true,
        supplier_error: null,
        supplier_success: null,
        supplier_action: action.type,
      };
    case MANAGE_SUPPLIER_ORDER_SUCCESS:
      return {
        ...state,
        supplier_loading: false,
        supplier_success: action.payload,
      };

    case MANAGE_SUPPLIER_ORDER_ERROR:
      return {
        ...state,
        supplier_loading: false,
        supplier_error: action.payload,
      };

    case SUPPLIER_ORDER_LIST_REQUEST:
      return {
        ...state,
        supplier_loading: true,
        supplier_error: null,
        supplier_success: null,
        supplier_action: action.type,
        supplier_order_list: [],
      };
    case SUPPLIER_ORDER_LIST_SUCCESS:
      return {
        ...state,
        supplier_loading: false,
        supplier_order_list: action.payload,
      };

    case SUPPLIER_ORDER_LIST_ERROR:
      return {
        ...state,
        supplier_loading: false,
        supplier_error: action.payload,
      };

    case SUPPLIER_ORDER_REQUEST:
      return {
        ...state,
        supplier_loading: true,
        supplier_error: null,
        supplier_success: null,
        supplier_action: action.type,
        supplier_order: null,
      };
    case SUPPLIER_ORDER_SUCCESS:
      return {
        ...state,
        supplier_loading: false,
        supplier_order: action.payload,
      };

    case SUPPLIER_ORDER_ERROR:
      return {
        ...state,
        supplier_loading: false,
        supplier_error: action.payload,
      };

    case MANAGE_SUPPLIER_DELIVERY_REQUEST:
      return {
        ...state,
        supplier_loading: true,
        supplier_error: null,
        supplier_success: null,
        supplier_action: action.type,
      };
    case MANAGE_SUPPLIER_DELIVERY_SUCCESS:
      return {
        ...state,
        supplier_loading: false,
        supplier_success: action.payload,
      };

    case MANAGE_SUPPLIER_DELIVERY_ERROR:
      return {
        ...state,
        supplier_loading: false,
        supplier_error: action.payload,
      };

    case MANAGE_SUPPLIER_QUOTATION_REQUEST:
      return {
        ...state,
        supplier_loading: true,
        supplier_error: null,
        supplier_success: null,
        supplier_action: action.type,
      };
    case MANAGE_SUPPLIER_QUOTATION_SUCCESS:
      return {
        ...state,
        supplier_loading: false,
        supplier_success: action.payload,
      };

    case MANAGE_SUPPLIER_QUOTATION_ERROR:
      return {
        ...state,
        supplier_loading: false,
        supplier_error: action.payload,
      };

    case SUPPLIER_ORDER_ITEMS_REQUEST:
      return {
        ...state,
        supplier_loading: true,
        supplier_error: null,
        supplier_success: null,
        supplier_action: action.type,
        order_items: null,
      };
    case SUPPLIER_ORDER_ITEMS_SUCCESS:
      return {
        ...state,
        supplier_loading: false,
        order_items: action.payload,
      };

    case SUPPLIER_ORDER_ITEMS_SUCCESS:
      return {
        ...state,
        supplier_loading: false,
        supplier_error: action.payload,
      };

    case MANAGE_DELIVERY_HISTORY_REQUEST:
      return {
        ...state,
        supplier_loading: true,
        supplier_error: null,
        supplier_success: null,
        supplier_action: action.type,
      };
    case MANAGE_DELIVERY_HISTORY_SUCCESS:
      return {
        ...state,
        supplier_loading: false,
        supplier_success: action.payload,
      };

    case MANAGE_DELIVERY_HISTORY_ERROR:
      return {
        ...state,
        supplier_loading: false,
        supplier_error: action.payload,
      };

    case MANAGE_ORDER_STATUS_REQUEST:
      return {
        ...state,
        supplier_loading: true,
        supplier_error: null,
        supplier_success: null,
        supplier_action: action.type,
      };
    case MANAGE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        supplier_loading: false,
        supplier_success: action.payload,
      };
    case MANAGE_ORDER_STATUS_ERORR:
      return {
        ...state,
        supplier_loading: false,
        supplier_error: action.payload,
      };

    case MANAGE_QUOTATION_STATUS_REQUEST:
      return {
        ...state,
        supplier_loading: true,
        supplier_error: null,
        supplier_success: null,
        supplier_action: action.type,
      };
    case MANAGE_QUOTATION_STATUS_SUCCESS:
      return {
        ...state,
        supplier_loading: false,
        supplier_success: action.payload,
      };
    case MANAGE_QUOTATION_STATUS_ERROR:
      return {
        ...state,
        supplier_loading: false,
        supplier_error: action.payload,
      };

    case MANAGE_INVOICE_STATUS_REQUEST:
      return {
        ...state,
        supplier_loading: true,
        supplier_error: null,
        supplier_success: null,
        supplier_action: action.type,
      };
    case MANAGE_INVOICE_STATUS_SUCCESS:
      return {
        ...state,
        supplier_loading: false,
        supplier_success: action.payload,
      };
    case MANAGE_INVOICE_STATUS_ERROR:
      return {
        ...state,
        supplier_loading: false,
        supplier_error: action.payload,
      };

    case MANAGE_DELIVER_STATUS_REQUEST:
      return {
        ...state,
        supplier_loading: true,
        supplier_error: null,
        supplier_success: null,
        supplier_action: action.type,
      };
    case MANAGE_DELIVER_STATUS_SUCCESS:
      return {
        ...state,
        supplier_loading: false,
        supplier_success: action.payload,
      };
    case MANAGE_DELIVER_STATUS_ERROR:
      return {
        ...state,
        supplier_loading: false,
        supplier_error: action.payload,
      };

    default:
      return { ...state };
  }
};
