import {
  CLEAR_DOCTOR_DEFAULTS,
  DOCTORSLOT_LIST_ERROR,
  DOCTORSLOT_LIST_REQUEST,
  DOCTORSLOT_LIST_SUCCESS,
  MANAGE_DOCTORSLOT_ERROR,
  MANAGE_DOCTORSLOT_REQUEST,
  MANAGE_DOCTORSLOT_SUCCESS,
} from "../actions";

const INIT = {
  doctor_loading: false,
  doctor_action: null,
  doctor_success: null,
  doctor_error: null,
  doctor_slot_list: [],
};

export default (state = INIT, action) => {
  switch (action.type) {
    case CLEAR_DOCTOR_DEFAULTS:
      return {
        ...state,
        doctor_loading: true,
        doctor_success: null,
        doctor_error: null,
        doctor_action: null,
      };
    case MANAGE_DOCTORSLOT_REQUEST:
      return {
        ...state,
        doctor_loading: true,
        doctor_success: null,
        doctor_error: null,
        doctor_action: action.type,
      };
    case MANAGE_DOCTORSLOT_SUCCESS: {
      return {
        ...state,
        doctor_loading: false,
        doctor_success: action.payload,
      };
    }
    case MANAGE_DOCTORSLOT_ERROR: {
      return {
        ...state,
        doctor_loading: false,
        doctor_error: action.payload,
      };
    }
    case DOCTORSLOT_LIST_REQUEST:
      return {
        ...state,
        doctor_loading: true,
        doctor_success: null,
        doctor_error: null,
        doctor_action: action.type,
        doctor_slot_list: [],
      };
    case DOCTORSLOT_LIST_SUCCESS: {
      return {
        ...state,
        doctor_loading: false,
        doctor_slot_list: action.payload,
      };
    }
    case DOCTORSLOT_LIST_ERROR: {
      return {
        ...state,
        doctor_loading: false,
        doctor_error: action.payload,
      };
    }
    default:
      return { ...state };
  }
};
