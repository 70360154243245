import { combineReducers } from "redux";
import setup_reducer from "./setup/reducer";
import pharmacy_reducer from "./pharmacy/reducer";
import patient_reducer from "./patient/reducer";
import staff_reducer from "./staff/reducer";
import user_reducer from "./user/reducer";
import inventory_reducer from "./inventory/reducer";
import supplier_reducer from "./supplier/reducer";
import invoice_reducer from "./invoice/reducer";
import center_reducer from "./center/reducer";
import guest_reducer from "./guest/reducer";
import online_reducer from "./onlinepharmacy/reducer";
import doctor_reducer from "./doctor/reducer";
import ticket_reducer from "./ticket/reducer";
import report_reducer from "./report/reducer";

import v2pharmacy_reducer from "./v2pharmacy/reducer";

const reducers = combineReducers({
  setup_reducer,
  pharmacy_reducer,
  patient_reducer,
  staff_reducer,
  user_reducer,
  inventory_reducer,
  supplier_reducer,
  invoice_reducer,
  center_reducer,
  guest_reducer,
  online_reducer,
  doctor_reducer,
  ticket_reducer,
  report_reducer,
  v2pharmacy_reducer,
});

export default reducers;
