import {
  V2_MANAGE_CENTER_STOCK_ERROR,
  V2_MANAGE_CENTER_STOCK_REQUEST,
  V2_MANAGE_CENTER_STOCK_SUCCESS,
  V2_MANAGE_PHARMAACY_ERROR,
  V2_MANAGE_PHARMAACY_REQUEST,
  V2_MANAGE_PHARMAACY_SUCCESS,
} from "../actions";

export const v2ManageCentralStockRequest = (request) => ({
  type: V2_MANAGE_CENTER_STOCK_REQUEST,
  payload: request,
  request_type: request.request_type,
});
export const v2ManageCentralStockSuccess = (response, request_type) => ({
  type: V2_MANAGE_CENTER_STOCK_SUCCESS,
  payload: response,
  request_type: request_type,
});
export const v2ManageCentralStockError = (message) => ({
  type: V2_MANAGE_CENTER_STOCK_ERROR,
  payload: message,
});

export const v2ManagePharmacyRequest = (request) => ({
  type: V2_MANAGE_PHARMAACY_REQUEST,
  payload: request,
  request_type: request.request_type,
});
export const v2ManagePharmacySuccess = (response, request_type) => ({
  type: V2_MANAGE_PHARMAACY_SUCCESS,
  payload: response,
  request_type: request_type,
});
export const v2ManagePharmacyError = (message) => ({
  type: V2_MANAGE_PHARMAACY_ERROR,
  payload: message,
});
