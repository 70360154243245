import {
  MANAGE_ONLINE_DELIVERSTATUS_ERROR,
  MANAGE_ONLINE_DELIVERSTATUS_REQUEST,
  MANAGE_ONLINE_DELIVERSTATUS_SUCCESS,
  MANAGE_ONLINE_ORDERSTATUS_ERROR,
  MANAGE_ONLINE_ORDERSTATUS_REQUEST,
  MANAGE_ONLINE_ORDERSTATUS_SUCCESS,
  ONLINE_ORDER_DETAILS_ERROR,
  ONLINE_ORDER_DETAILS_REQUEST,
  ONLINE_ORDER_DETAILS_SUCCESS,
  ONLINE_ORDER_LIST_ERROR,
  ONLINE_ORDER_LIST_REQUEST,
  ONLINE_ORDER_LIST_SUCCESS,
  ONLINE_PHARMACY_ERROR,
  ONLINE_PHARMACY_REQUEST,
  ONLINE_PHARMACY_SUCCESS,
} from "../actions";

const INIT = {
  online_loading: false,
  online_action: null,
  online_success: null,
  online_error: null,
  online_order_info: null,
  online_order_list: [],
  online_pharmacy: null,
};

export default (state = INIT, action) => {
  switch (action.type) {
    case ONLINE_ORDER_LIST_REQUEST:
      return {
        ...state,
        online_loading: true,
        online_success: null,
        online_error: null,
        online_action: action.type,
        online_order_list: [],
      };
    case ONLINE_ORDER_LIST_SUCCESS: {
      return {
        ...state,
        online_loading: false,
        online_order_list: action.payload,
      };
    }
    case ONLINE_ORDER_LIST_ERROR: {
      return {
        ...state,
        online_loading: false,
        online_error: action.payload,
      };
    }

    case ONLINE_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        online_loading: true,
        online_success: null,
        online_error: null,
        online_action: action.type,
        online_order_info: null,
      };
    case ONLINE_ORDER_DETAILS_SUCCESS: {
      return {
        ...state,
        online_loading: false,
        online_order_info: action.payload,
      };
    }
    case ONLINE_ORDER_DETAILS_ERROR: {
      return {
        ...state,
        online_loading: false,
        online_error: action.payload,
      };
    }
    case ONLINE_PHARMACY_REQUEST:
      return {
        ...state,
        online_loading: true,
        online_success: null,
        online_error: null,
        online_action: action.type,
        online_pharmacy: null,
      };
    case ONLINE_PHARMACY_SUCCESS: {
      return {
        ...state,
        online_loading: false,
        online_pharmacy: action.payload,
      };
    }
    case ONLINE_PHARMACY_ERROR: {
      return {
        ...state,
        online_loading: false,
        online_error: action.payload,
      };
    }

    case MANAGE_ONLINE_ORDERSTATUS_REQUEST:
      return {
        ...state,
        online_loading: true,
        online_success: null,
        online_error: null,
        online_action: action.type,
      };
    case MANAGE_ONLINE_ORDERSTATUS_SUCCESS: {
      return {
        ...state,
        online_loading: false,
        online_success: action.payload,
      };
    }
    case MANAGE_ONLINE_ORDERSTATUS_ERROR: {
      return {
        ...state,
        online_loading: false,
        online_error: action.payload,
      };
    }

    case MANAGE_ONLINE_DELIVERSTATUS_REQUEST:
      return {
        ...state,
        online_loading: true,
        online_success: null,
        online_error: null,
        online_action: action.type,
      };
    case MANAGE_ONLINE_DELIVERSTATUS_SUCCESS: {
      return {
        ...state,
        online_loading: false,
        online_success: action.payload,
      };
    }
    case MANAGE_ONLINE_DELIVERSTATUS_ERROR: {
      return {
        ...state,
        online_loading: false,
        online_error: action.payload,
      };
    }
    default:
      return { ...state };
  }
};
