import {
  AUTO_ITEMS_ERROR,
  AUTO_ITEMS_REQUEST,
  AUTO_ITEMS_SUCCESS,
  CLEAR_SETUP_DEFAULTS,
  DELETE_MEDICINE_CATEGORY_ERROR,
  DELETE_MEDICINE_CATEGORY_REQUEST,
  DELETE_MEDICINE_CATEGORY_SUCCESS,
  DELETE_MEDICINE_DOSAGE_ERROR,
  DELETE_MEDICINE_DOSAGE_REQUEST,
  DELETE_MEDICINE_DOSAGE_SUCCESS,
  DELETE_SUPPLIER_ERROR,
  DELETE_SUPPLIER_REQUEST,
  DELETE_SUPPLIER_SUCCESS,
  MANAGE_MEDICINE_CATEGORY_ERROR,
  MANAGE_MEDICINE_CATEGORY_REQUEST,
  MANAGE_MEDICINE_CATEGORY_SUCCESS,
  MANAGE_MEDICINE_DOSAGE_ERROR,
  MANAGE_MEDICINE_DOSAGE_REQUEST,
  MANAGE_MEDICINE_DOSAGE_SUCCESS,
  MANAGE_SUPPLIER_ERROR,
  MANAGE_SUPPLIER_REQUEST,
  MANAGE_SUPPLIER_SUCCESS,
  MEDICINE_CATEGORY_LIST_ERROR,
  MEDICINE_CATEGORY_LIST_REQUEST,
  MEDICINE_CATEGORY_LIST_SUCCESS,
  MEDICINE_DOSAGE_LIST_ERROR,
  MEDICINE_DOSAGE_LIST_REQUEST,
  MEDICINE_DOSAGE_LIST_SUCCESS,
  SUPPLIER_DETAILS_ERROR,
  SUPPLIER_DETAILS_REQUEST,
  SUPPLIER_DETAILS_SUCCESS,
  SUPPLIER_LIST_ERROR,
  SUPPLIER_LIST_REQUEST,
  SUPPLIER_LIST_SUCCESS,
  ADMIN_UTILS_LIST_ERROR,
  ADMIN_UTILS_LIST_REQUEST,
  ADMIN_UTILS_LIST_SUCCESS,
  CLIENT_UTILS_LIST_REQUEST,
  CLIENT_UTILS_LIST_SUCCESS,
  CLIENT_UTILS_LIST_ERROR,
  AUTO_SUGGEST_REQUEST,
  AUTO_SUGGEST_SUCCESS,
  AUTO_SUGGEST_ERROR,
  MANAGE_ITEM_CATEGORY_RQUEST,
  MANAGE_ITEM_CATEGORY_SUCECSS,
  MANAGE_ITEM_CATEGORY_ERROR,
  ITEM_CATEGORY_LIST_REQUEST,
  ITEM_CATEGORY_LIST_SUCCESS,
  ITEM_CATEGORY_LIST_ERROR,
  MANAGE_LEAVETYPE_REQUEST,
  MANAGE_LEAVETYPE_SUCCESS,
  MANAGE_LEAVETYPE_ERROR,
  MANAGE_DEPARTMENT_REQUEST,
  MANAGE_DEPARTMENT_SUCCESS,
  MANAGE_DEPARTMENT_ERROR,
  MANAGE_DESIGNATION_REQUEST,
  MANAGE_DESIGNATION_SUCCESS,
  MANAGE_DESIGNATION_ERROR,
  LEAVETYPE_LIST_REQUEST,
  LEAVETYPE_LIST_SUCCESS,
  LEAVETYPE_LIST_ERROR,
  DEPARTMENT_LIST_REQUEST,
  DEPARTMENT_LIST_SUCCESS,
  DESIGNATION_LIST_REQUEST,
  DESIGNATION_LIST_SUCCESS,
  DESIGNATION_LIST_ERROR,
  MANAGE_SPECIALIZATION_REQUEST,
  MANAGE_SPECIALIZATION_SUCCES,
  MANAGE_SPECIALIZATION_ERROR,
  SPECIALIZATION_LIST_REQUEST,
  SPECIALIZATION_LIST_SUCCESS,
  SPECIALIZATION_LIST_ERROR,
  REQUEST,
  SUCCESS,
  CLEAR,
  BATCHNO_LIST_REQUEST,
} from "../actions";

const INIT_STATE = {
  setup_loading: false,
  setup_error: null,
  setup_success: null,
  setup_action: null,
  request: {},
  supplier: null,
  medicine_categories: [],
  supplier_list: [],
  medicine_dosages: [],
  admin_utils: [],
  client_utils: [],
  auto_items: [],
  auto_suggest: [],
  item_categories: [],
  leavetypes: [],
  departments: [],
  designations: [],
  specializations: [],
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_SETUP_DEFAULTS:
      return {
        ...state,
        setup_loading: false,
        setup_error: null,
        setup_success: null,
        setup_action: null,
      };

    case REQUEST:
      return {
        ...state,
        setup_loading: false,
        setup_success: null,
        setup_error: null,
        request: { ...state.request },
      };
    case SUCCESS:
      return { ...state, request: { ...action.payload } };
    case CLEAR:
      return { ...state, request: {} };
    case MANAGE_MEDICINE_CATEGORY_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        setup_action: action.type,
      };
    case MANAGE_MEDICINE_CATEGORY_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        setup_success: action.payload,
      };

    case MANAGE_MEDICINE_CATEGORY_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };
    case DELETE_MEDICINE_CATEGORY_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        setup_action: action.type,
      };
    case DELETE_MEDICINE_CATEGORY_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        setup_success: action.payload,
      };
    case DELETE_MEDICINE_CATEGORY_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };
    case MEDICINE_CATEGORY_LIST_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        setup_action: action.type,

        medicine_categories: [],
      };
    case MEDICINE_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        medicine_categories: action.payload,
      };
    case MEDICINE_CATEGORY_LIST_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };

    case MANAGE_SUPPLIER_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        setup_action: action.type,
      };
    case MANAGE_SUPPLIER_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        setup_success: action.payload,
      };

    case MANAGE_SUPPLIER_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };
    case DELETE_SUPPLIER_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        setup_action: action.type,
      };
    case DELETE_SUPPLIER_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        setup_success: action.payload,
      };
    case DELETE_SUPPLIER_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };
    case SUPPLIER_LIST_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        supplier_list: [],
        setup_action: action.type,
      };
    case SUPPLIER_LIST_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        supplier_list: action.payload,
      };
    case SUPPLIER_LIST_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };

    case MANAGE_MEDICINE_DOSAGE_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_success: null,
        setup_error: null,
        setup_action: action.type,
      };
    case MANAGE_MEDICINE_DOSAGE_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        setup_success: action.payload,
      };
    case MANAGE_MEDICINE_DOSAGE_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };
    case DELETE_MEDICINE_DOSAGE_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        setup_action: action.type,
      };
    case DELETE_MEDICINE_DOSAGE_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        setup_success: action.payload,
      };
    case DELETE_MEDICINE_DOSAGE_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };
    case MEDICINE_DOSAGE_LIST_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        medicine_dosages: [],
        setup_action: action.type,
      };
    case MEDICINE_DOSAGE_LIST_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        medicine_dosages: action.payload,
      };
    case MEDICINE_DOSAGE_LIST_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };
    case ADMIN_UTILS_LIST_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        admin_utils: [],
        setup_action: action.type,
      };
    case ADMIN_UTILS_LIST_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        admin_utils: action.payload,
      };
    case ADMIN_UTILS_LIST_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };

    case CLIENT_UTILS_LIST_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        client_utils: [],
        setup_action: action.type,
      };
    case CLIENT_UTILS_LIST_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        client_utils: action.payload,
      };
    case CLIENT_UTILS_LIST_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };

    case AUTO_ITEMS_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        auto_items: [],
        setup_action: action.type,
      };
    case AUTO_ITEMS_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        auto_items: action.payload,
      };
    case AUTO_ITEMS_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };
    case AUTO_ITEMS_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        auto_items: [],
        setup_action: action.type,
      };
    case AUTO_ITEMS_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        auto_items: action.payload,
      };
    case AUTO_ITEMS_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };

    case SUPPLIER_DETAILS_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        supplier: null,
        setup_action: action.type,
      };
    case SUPPLIER_DETAILS_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        supplier: action.payload,
      };
    case SUPPLIER_DETAILS_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };

    case AUTO_SUGGEST_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        auto_suggest: [],
        setup_action: action.type,
      };
    case AUTO_SUGGEST_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        auto_suggest: action.payload,
      };
    case AUTO_SUGGEST_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };

    case MANAGE_ITEM_CATEGORY_RQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        setup_action: action.type,
      };
    case MANAGE_ITEM_CATEGORY_SUCECSS:
      return {
        ...state,
        setup_loading: false,
        setup_success: action.payload,
      };
    case MANAGE_ITEM_CATEGORY_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };

    case ITEM_CATEGORY_LIST_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        setup_action: action.type,
        item_categories: [],
      };
    case ITEM_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        item_categories: action.payload,
      };
    case ITEM_CATEGORY_LIST_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };

    case MANAGE_LEAVETYPE_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        setup_action: action.type,
      };
    case MANAGE_LEAVETYPE_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        setup_success: action.payload,
      };
    case MANAGE_LEAVETYPE_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };
    case MANAGE_DEPARTMENT_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        setup_action: action.type,
      };
    case MANAGE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        setup_success: action.payload,
      };
    case MANAGE_DEPARTMENT_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };
    case MANAGE_DESIGNATION_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        setup_action: action.type,
      };
    case MANAGE_DESIGNATION_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        setup_success: action.payload,
      };
    case MANAGE_DESIGNATION_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };

    case LEAVETYPE_LIST_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        setup_action: action.type,
        leavetypes: [],
      };
    case LEAVETYPE_LIST_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        leavetypes: action.payload,
      };
    case LEAVETYPE_LIST_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };

    case DEPARTMENT_LIST_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        setup_action: action.type,
        departments: [],
      };
    case DEPARTMENT_LIST_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        departments: action.payload,
      };
    case DEPARTMENT_LIST_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };
    case DESIGNATION_LIST_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        setup_action: action.type,
        designations: [],
      };
    case DESIGNATION_LIST_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        designations: action.payload,
      };
    case DESIGNATION_LIST_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };

    case MANAGE_SPECIALIZATION_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        setup_action: action.type,
      };
    case MANAGE_SPECIALIZATION_SUCCES:
      return {
        ...state,
        setup_loading: false,
        setup_success: action.payload,
      };
    case MANAGE_SPECIALIZATION_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };

    case SPECIALIZATION_LIST_REQUEST:
      return {
        ...state,
        setup_loading: true,
        setup_error: null,
        setup_success: null,
        setup_action: action.type,
        specializations: [],
      };
    case SPECIALIZATION_LIST_SUCCESS:
      return {
        ...state,
        setup_loading: false,
        specializations: action.payload,
      };
    case SPECIALIZATION_LIST_ERROR:
      return {
        ...state,
        setup_loading: false,
        setup_error: action.payload,
      };
    default:
      return { ...state };
  }
};
