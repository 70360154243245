export const CLEAR_USER_DEFAULTS = "CLEAR_USER_DEFAULTS";
export const CLEAR_SETUP_DEFAULTS = "CLEAR_SETUP_DEFAULTS";
export const CLEAR_SUPPLIER_DEFAULTS = "CLEAR_SUPPLIER_DEFAULTS";
export const CLEAR_INVENTORY_DEFAULTS = "CLEAR_INVENTORY_DEFAULTS";
export const CLEAR_STAFF_DEFAULTS = "CLEAR_STAFF_DEFAULTS";
export const CLEAR_PHARMACY_DEFAULTS = "CLEAR_PHARMACY_DEFAULTS";
export const CLEAR_PATIENT_DEFAULTS = "CLEAR_PATIENT_DEFAULTS";
export const CLEAR_DOCTOR_DEFAULTS = "CLEAR_DOCTOR_DEFAULTS";

export const CLEAR_ALL_DEFAULTS = "CLEAR_ALL_DEFAULTS";

export const MANAGE_MEDICINE_CATEGORY_REQUEST =
  "MANAGE_MEDICINE_CATEGORY_REQUEST";
export const MANAGE_MEDICINE_CATEGORY_SUCCESS =
  "MANAGE_MEDICINE_CATEGORY_SUCCESS";
export const MANAGE_MEDICINE_CATEGORY_ERROR = "MANAGE_MEDICINE_CATEGORY_ERROR";

export const DELETE_MEDICINE_CATEGORY_REQUEST =
  "DELETE_MEDICINE_CATEGORY_REQUEST";
export const DELETE_MEDICINE_CATEGORY_SUCCESS =
  "DELETE_MEDICINE_CATEGORY_SUCCESS";
export const DELETE_MEDICINE_CATEGORY_ERROR = "DELETE_MEDICINE_CATEGORY_ERROR";

export const MEDICINE_CATEGORY_LIST_REQUEST = "MEDICINE_CATEGORY_LIST_REQUEST";
export const MEDICINE_CATEGORY_LIST_SUCCESS = "MEDICINE_CATEGORY_LIST_SUCCESS";
export const MEDICINE_CATEGORY_LIST_ERROR = "MEDICINE_CATEGORY_LIST_ERROR";

export const MANAGE_SUPPLIER_REQUEST = "MANAGE_SUPPLIER_REQUEST";
export const MANAGE_SUPPLIER_SUCCESS = "MANAGE_SUPPLIER_SUCCESS";
export const MANAGE_SUPPLIER_ERROR = "MANAGE_SUPPLIER_ERROR";

export const DELETE_SUPPLIER_REQUEST = "DELETE_SUPPLIER_REQUEST";
export const DELETE_SUPPLIER_SUCCESS = "DELETE_SUPPLIER_SUCCESS";
export const DELETE_SUPPLIER_ERROR = "DELETE_SUPPLIER_ERROR";

export const SUPPLIER_LIST_REQUEST = "SUPPLIER_LIST_REQUEST";
export const SUPPLIER_LIST_SUCCESS = "SUPPLIER_LIST_SUCCESS";
export const SUPPLIER_LIST_ERROR = "SUPPLIER_LIST_ERROR";

export const MANAGE_MEDICINE_DOSAGE_REQUEST = "MANAGE_MEDICINE_DOSAGE_REQUEST";
export const MANAGE_MEDICINE_DOSAGE_SUCCESS = "MANAGE_MEDICINE_DOSAGE_SUCCESS";
export const MANAGE_MEDICINE_DOSAGE_ERROR = "MANAGE_MEDICINE_DOSAGE_ERROR";

export const DELETE_MEDICINE_DOSAGE_REQUEST = "DELETE_MEDICINE_DOSAGE_REQUEST";
export const DELETE_MEDICINE_DOSAGE_SUCCESS = "DELETE_MEDICINE_DOSAGE_SUCCESS";
export const DELETE_MEDICINE_DOSAGE_ERROR = "DELETE_MEDICINE_DOSAGE_ERROR";

export const MEDICINE_DOSAGE_LIST_REQUEST = "MEDICINE_DOSAGE_LIST_REQUEST";
export const MEDICINE_DOSAGE_LIST_SUCCESS = "MEDICINE_DOSAGE_LIST_SUCCESS";
export const MEDICINE_DOSAGE_LIST_ERROR = "MEDICINE_DOSAGE_LIST_ERROR";

export const MANAGE_MEDICINE_REQUEST = "MANAGE_MEDICINE_REQUEST";
export const MANAGE_MEDICINE_SUCCESS = "MANAGE_MEDICINE_SUCCESS";
export const MANAGE_MEDICINE_ERROR = "MANAGE_MEDICINE_ERROR";

export const DELETE_MEDICINE_REQUEST = "DELETE_MEDICINE_REQUEST";
export const DELETE_MEDICINE_SUCCESS = "DELETE_MEDICINE_SUCCESS";
export const DELETE_MEDICINE_ERROR = "DELETE_MEDICINE_ERROR";

export const MEDICINE_LIST_REQUEST = "MEDICINE_LIST_REQUEST";
export const MEDICINE_LIST_SUCCESS = "MEDICINE_LIST_SUCCESS";
export const MEDICINE_LIST_ERROR = "MEDICINE_LIST_ERROR";

export const ADMIN_UTILS_LIST_REQUEST = "ADMIN_UTILS_LIST_REQUEST";
export const ADMIN_UTILS_LIST_SUCCESS = "ADMIN_UTILS_LIST_SUCCESS";
export const ADMIN_UTILS_LIST_ERROR = "ADMIN_UTILS_LIST_ERROR";

export const CLIENT_UTILS_LIST_REQUEST = "CLIENT_UTILS_LIST_REQUEST";
export const CLIENT_UTILS_LIST_SUCCESS = "CLIENT_UTILS_LIST_SUCCESS";
export const CLIENT_UTILS_LIST_ERROR = "CLIENT_UTILS_LIST_ERROR";

export const MANAGE_PURCHASE_REQUEST = "MANAGE_PURCHASE_REQUEST";
export const MANAGE_PURCHASE_SUCCESS = "MANAGE_PURCHASE_SUCCESS";
export const MANAGE_PURCHASE_ERROR = "MANAGE_PURCHASE_ERROR";

export const MANAGE_PATIENT_REQUEST = "MANAGE_PATIENT_REQUEST";
export const MANAGE_PATIENT_SUCCESS = "MANAGE_PATIENT_SUCCESS";
export const MANAGE_PATIENT_ERROR = "MANAGE_PATIENT_ERROR";

export const DELETE_PATIENT_REQUEST = "DELETE_PATIENT_REQUEST";
export const DELETE_PATIENT_SUCCESS = "DELETE_PATIENT_SUCCESS";
export const DELETE_PATIENT_ERROR = "DELETE_PATIENT_ERROR";

export const PATIENT_LIST_REQUEST = "PATIENT_LIST_REQUEST";
export const PATIENT_LIST_SUCCESS = "PATIENT_LIST_SUCCESS";
export const PATIENT_LIST_ERROR = "PATIENT_LIST_ERROR";

export const AUTO_SUGGEST_REQUEST = "AUTO_SUGGEST_REQUEST";
export const AUTO_SUGGEST_SUCCESS = "AUTO_SUGGEST_SUCCESS";
export const AUTO_SUGGEST_ERROR = "AUTO_SUGGEST_ERROR";

export const AUTO_MEDICINE_SUGGEST_REQUEST = "AUTO_MEDICINE_SUGGEST_REQUEST";
export const AUTO_MEDICINE_SUGGEST_SUCCESS = "AUTO_MEDICINE_SUGGEST_SUCCESS";
export const AUTO_MEDICINE_SUGGEST_ERROR = "AUTO_MEDICINE_SUGGEST_ERROR";

export const BATCHNO_LIST_REQUEST = "BATCHNO_LIST_REQUEST";
export const BATCHNO_LIST_SUCCESS = "BATCHNO_LIST_SUCCESS";
export const BATCHNO_LIST_ERROR = "BATCHNO_LIST_ERROR";
export const CLEAR_BATCHNO_LIST = "CLEAR_BATCHNO_LIST";

export const EXPIRY_DATE_REQUEST = "EXPIRY_DATE_REQUEST";
export const EXPIRY_DATE_SUCCESS = "EXPIRY_DATE_SUCCESS";
export const EXPIRY_DATE_ERROR = "EXPIRY_DATE_ERROR";

export const MANAGE_BILL_REQUEST = "MANAGE_BILL_REQUEST";
export const MANAGE_BILL_SUCCESS = "MANAGE_BILL_SUCCESS";
export const MANAGE_BILL_ERROR = "MANAGE_BILL_ERROR";

export const BILL_BASIC_LIST_REQUEST = "BILL_BASIC_LIST_REQUEST";
export const BILL_BASIC_LIST_SUCCESS = "BILL_BASIC_LIST_SUCCESS";
export const BILL_BASIC_LIST_ERROR = "BILL_BASIC_LIST_ERROR";

export const BILL_DETAILS_LIST_REQUEST = "BILL_DETAILS_LIST_REQUEST";
export const BILL_DETAILS_LIST_SUCCESS = "BILL_DETAILS_LIST_SUCCESS";
export const BILL_DETAILS_LIST_ERROR = "BILL_DETAILS_LIST_ERROR";

export const BILL_REQUEST = "BILL_REQUEST";
export const BILL_SUCCESS = "BILL_SUCCESS";
export const BILL_ERROR = "BILL_ERROR";

export const PURCHASE_BASIC_LIST_REQUEST = "PURCHASE_BASIC_LIST_REQUEST";
export const PURCHASE_BASIC_LIST_SUCCESS = "PURCHASE_BASIC_LIST_SUCCESS";
export const PURCHASE_BASIC_LIST_ERROR = "PURCHASE_BASIC_LIST_ERROR";

export const PURCHASE_DETAILS_LIST_REQUEST = "PURCHASE_DETAILS_LIST_REQUEST";
export const PURCHASE_DETAILS_LIST_SUCCESS = "PURCHASE_DETAILS_LIST_SUCCESS";
export const PURCHASE_DETAILS_LIST_ERROR = "PURCHASE_DETAILS_LIST_ERROR";

export const PURCHASE_REQUEST = "PURCHASE_REQUEST";
export const PURCHASE_SUCCESS = "PURCHASE_SUCCESS";
export const PURCHASE_ERROR = "PURCHASE_ERROR";

export const MEDICINE_REQUEST = "MEDICINE_REQUEST";
export const MEDICINE_SUCCESS = "MEDICINE_SUCCESS";
export const MEDICINE_ERROR = "MEDICINE_ERROR";

export const AUTO_PATIENT_SUGGEST_REQUEST = "AUTO_PATIENT_SUGGEST_REQUEST";
export const AUTO_PATIENT_SUGGEST_SUCCESS = "AUTO_PATIENT_SUGGEST_SUCCESS";
export const AUTO_PATIENT_SUGGEST_ERROR = "AUTO_PATIENT_SUGGEST_ERROR";

export const MANAGE_STAFF_REQUEST = "MANAGE_STAFF_REQUEST";
export const MANAGE_STAFF_SUCCESS = "MANAGE_STAFF_SUCCESS";
export const MANAGE_STAFF_ERROR = "MANAGE_STAFF_ERROR";

export const VALIDATE_LOGIN_REQUEST = "VALIDATE_LOGIN_REQUEST";
export const VALIDATE_LOGIN_SUCCESS = "VALIDATE_LOGIN_SUCCESS";
export const VALIDATE_LOGIN_ERROR = "VALIDATE_LOGIN_ERROR";

export const SUPPLIER_TRACKING_REQUEST = "SUPPLIER_TRACKING_REQUEST";
export const SUPPLIER_TRACKING_SUCCESS = "SUPPLIER_TRACKING_SUCCESS";
export const SUPPLIER_TRACKING_ERROR = "SUPPLIER_TRACKING_ERROR";

export const MANAGE_BRAND_MASTER_REQUEST = "MANAGE_BRAND_MASTER_REQUEST";
export const MANAGE_BRAND_MASTER_SUCCESS = "MANAGE_BRAND_MASTER_SUCCESS";
export const MANAGE_BRAND_MASTER_ERROR = "MANAGE_BRAND_MASTER_ERROR";

export const BRAND_MASTER_REQUEST = "BRAND_MASTER_REQUEST";
export const BRAND_MASTER_SUCCESS = "BRAND_MASTER_SUCCESS";
export const BRAND_MASTER_ERROR = "BRAND_MASTER_ERROR";

export const BRAND_MASTER_LIST_REQUEST = "BRAND_MASTER_LIST_REQUEST";
export const BRAND_MASTER_LIST_SUCCESS = "BRAND_MASTER_LIST_SUCCESS";
export const BRAND_MASTER_LIST_ERROR = "BRAND_MASTER_LIST_ERROR";

export const MANAGE_ITEM_REQUEST = "MANAGE_ITEM_REQUEST";
export const MANAGE_ITEM_SUCCESS = "MANAGE_ITEM_SUCCESS";
export const MANAGE_ITEM_ERROR = "MANAGE_ITEM_ERROR";

export const ITEM_LIST_REQUEST = "ITEM_LIST_REQUEST";
export const ITEM_LIST_SUCCESS = "ITEM_LIST_SUCCESS";
export const ITEM_LIST_ERROR = "ITEM_LIST_ERROR";

export const AUTO_ITEMS_REQUEST = "AUTO_ITEMS_REQUEST";
export const AUTO_ITEMS_SUCCESS = "AUTO_ITEMS_SUCCESS";
export const AUTO_ITEMS_ERROR = "AUTO_ITEMS_ERROR";

export const MANAGE_SUPPLIER_ORDER_REQUEST = "MANAGE_SUPPLIER_ORDER_REQUEST";
export const MANAGE_SUPPLIER_ORDER_SUCCESS = "MANAGE_SUPPLIER_ORDER_SUCCESS";
export const MANAGE_SUPPLIER_ORDER_ERROR = "MANAGE_SUPPLIER_ORDER_ERROR";

export const SUPPLIER_ORDER_LIST_REQUEST = "SUPPLIER_ORDER_LIST_REQUEST";
export const SUPPLIER_ORDER_LIST_SUCCESS = "SUPPLIER_ORDER_LIST_SUCCESS";
export const SUPPLIER_ORDER_LIST_ERROR = "SUPPLIER_ORDER_LIST_ERROR";

export const SUPPLIER_ORDER_REQUEST = "SUPPLIER_ORDER_REQUEST";
export const SUPPLIER_ORDER_SUCCESS = "SUPPLIER_ORDER_SUCCESS";
export const SUPPLIER_ORDER_ERROR = "SUPPLIER_ORDER_ERROR";

export const MANAGE_SUPPLIER_DELIVERY_REQUEST =
  "MANAGE_SUPPLIER_DELIVERY_REQUEST";
export const MANAGE_SUPPLIER_DELIVERY_SUCCESS =
  "MANAGE_SUPPLIER_DELIVERY_SUCCESS";
export const MANAGE_SUPPLIER_DELIVERY_ERROR = "MANAGE_SUPPLIER_DELIVERY_ERROR";

export const MANAGE_SUPPLIER_INVOICE_REQUEST =
  "MANAGE_SUPPLIER_INVOICE_REQUEST";
export const MANAGE_SUPPLIER_INVOICE_SUCCESS =
  "MANAGE_SUPPLIER_INVOICE_SUCCESS";
export const MANAGE_SUPPLIER_INVOICE_ERROR = "MANAGE_SUPPLIER_INVOICE_ERROR";

export const MANAGE_SUPPLIER_PAYMENT_REQUEST =
  "MANAGE_SUPPLIER_PAYMENT_REQUEST";
export const MANAGE_SUPPLIER_PAYMENT_SUCCESS =
  "MANAGE_SUPPLIER_PAYMENT_SUCCESS";
export const MANAGE_SUPPLIER_PAYMENT_ERROR = "MANAGE_SUPPLIER_PAYMENT_ERROR";

export const SUPPLIER_LOGIN_REQUEST = "SUPPLIER_LOGIN_REQUEST";
export const SUPPLIER_LOGIN_SUCCESS = "SUPPLIER_LOGIN_SUCCESS";
export const SUPPLIER_LOGIN_ERROR = "SUPPLIER_LOGIN_ERROR";

export const MANAGE_SUPPLIER_QUOTATION_REQUEST =
  "MANAGE_SUPPLIER_QUOTATION_REQUEST";
export const MANAGE_SUPPLIER_QUOTATION_SUCCESS =
  "MANAGE_SUPPLIER_QUOTATION_SUCCESS";
export const MANAGE_SUPPLIER_QUOTATION_ERROR =
  "MANAGE_SUPPLIER_QUOTATION_ERROR";

export const SUPPLIER_ORDER_ITEMS_REQUEST = "SUPPLIER_ORDER_ITEMS_REQUEST";
export const SUPPLIER_ORDER_ITEMS_SUCCESS = "SUPPLIER_ORDER_ITEMS_SUCCESS";
export const SUPPLIER_ORDER_ITEMS_ERROR = "SUPPLIER_ORDER_ITEMS_ERROR";

export const MANAGE_DELIVERY_HISTORY_REQUEST =
  "MANAGE_DELIVERY_HISTORY_REQUEST";
export const MANAGE_DELIVERY_HISTORY_SUCCESS =
  "MANAGE_DELIVERY_HISTORY_SUCCESS";
export const MANAGE_DELIVERY_HISTORY_ERROR = "MANAGE_DELIVERY_HISTORY_ERROR";

export const MANAGE_ORDER_STATUS_REQUEST = "MANAGE_ORDER_STATUS_REQUEST";
export const MANAGE_ORDER_STATUS_SUCCESS = "MANAGE_ORDER_STATUS_SUCCESS";
export const MANAGE_ORDER_STATUS_ERORR = "MANAGE_ORDER_STATUS_ERORR";

export const MANAGE_QUOTATION_STATUS_REQUEST =
  "MANAGE_QUOTATION_STATUS_REQUEST";
export const MANAGE_QUOTATION_STATUS_SUCCESS =
  "MANAGE_QUOTATION_STATUS_SUCCESS";
export const MANAGE_QUOTATION_STATUS_ERROR = "MANAGE_QUOTATION_STATUS_ERROR";

export const MANAGE_INVOICE_STATUS_REQUEST = "MANAGE_INVOICE_STATUS_REQUEST";
export const MANAGE_INVOICE_STATUS_SUCCESS = "MANAGE_INVOICE_STATUS_SUCCESS";
export const MANAGE_INVOICE_STATUS_ERROR = "MANAGE_INVOICE_STATUS_ERROR";

export const USER_INFO_REQUEST = "USER_INFO_REQUEST";
export const USER_INFO_SUCCESS = "USER_INFO_SUCCESS";
export const USER_INFO_ERROR = "USER_INFO_ERROR";

export const MANAGE_DELIVER_STATUS_REQUEST = "MANAGE_DELIVER_STATUS_REQUEST";
export const MANAGE_DELIVER_STATUS_SUCCESS = "MANAGE_DELIVER_STATUS_SUCCESS";
export const MANAGE_DELIVER_STATUS_ERROR = "MANAGE_DELIVER_STATUS_ERROR";

export const MANAGE_STOCK_INVOICE_REQUEST = "MANAGE_STOCK_INVOICE_REQUEST";
export const MANAGE_STOCK_INVOICE_SUCCESS = "MANAGE_STOCK_INVOICE_SUCCESS";
export const MANAGE_STOCK_INVOICE_ERROR = "MANAGE_STOCK_INVOICE_ERROR";

export const STOCK_INVOICE_LIST_REQUEST = "STOCK_INVOICE_LIST_REQUEST";
export const STOCK_INVOICE_LIST_SUCCESS = "STOCK_INVOICE_LIST_SUCCESS";
export const STOCK_INVOICE_LIST_ERROR = "STOCK_INVOICE_LIST_ERROR";

export const STOCK_INVOICE_REQUEST = "STOCK_INVOICE_REQUEST";
export const STOCK_INVOICE_SUCCESS = "STOCK_INVOICE_SUCCESS";
export const STOCK_INVOICE_ERROR = "STOCK_INVOICE_ERROR";

export const SUPPLIER_DETAILS_REQUEST = "SUPPLIER_DETAILS_REQUEST";
export const SUPPLIER_DETAILS_SUCCESS = "SUPPLIER_DETAILS_SUCCESS";
export const SUPPLIER_DETAILS_ERROR = "SUPPLIER_DETAILS_ERROR";

export const INVOICE_SUPPLIER_LIST_REQUEST = "INVOICE_SUPPLIER_LIST_REQUEST";
export const INVOICE_SUPPLIER_LIST_SUCCESS = "INVOICE_SUPPLIER_LIST_SUCCESS";
export const INVOICE_SUPPLIER_LIST_ERROR = "INVOICE_SUPPLIER_LIST_ERROR";

export const STOCK_INVOICE_OVERVIEW_REQUEST = "STOCK_INVOICE_OVERVIEW_REQUEST";
export const STOCK_INVOICE_OVERVIEW_SUCCESS = "STOCK_INVOICE_OVERVIEW_SUCCESS";
export const STOCK_INVOICE_OVERVIEW_ERROR = "STOCK_INVOICE_OVERVIEW_ERROR";

export const MANAGE_STOCKINVOICE_RETURN_REQUEST =
  "MANAGE_STOCKINVOICE_RETURN_REQUEST";
export const MANAGE_STOCKINVOICE_RETURN_SUCCESS =
  "MANAGE_STOCKINVOICE_RETURN_SUCCESS";
export const MANAGE_STOCKINVOICE_RETURN_ERROR =
  "MANAGE_STOCKINVOICE_RETURN_ERROR";

export const MANAGE_STOCKINVOICE_PAYMENT_REQUEST =
  "MANAGE_STOCKINVOICE_PAYMENT_REQUEST";
export const MANAGE_STOCKINVOICE_PAYMENT_SUCCESS =
  "MANAGE_STOCKINVOICE_PAYMENT_SUCCESS";
export const MANAGE_STOCKINVOICE_PAYMENT_ERROR =
  "MANAGE_STOCKINVOICE_PAYMENT_ERROR";

export const MANAGE_STOCKREQUEST_REQUEST = "MANAGE_STOCKREQUEST_REQUEST";
export const MANAGE_STOCKREQUEST_SUCCESS = "MANAGE_STOCKREQUEST_SUCCESS";
export const MANAGE_STOCKREQUEST_ERROR = "MANAGE_STOCKREQUEST_ERROR";

export const STOCKREQUEST_LIST_REQUEST = "STOCKREQUEST_LIST_REQUEST";
export const STOCKREQUEST_LIST_SUCCESS = "STOCKREQUEST_LIST_SUCCESS";
export const STOCKREQUEST_LIST_ERROR = "STOCKREQUEST_LIST_ERROR";

export const STOCKSTATUS_LIST_REQUEST = "STOCKSTATUS_LIST_REQUEST";
export const STOCKSTATUS_LIST_SUCCESS = "STOCKSTATUS_LIST_SUCCESS";
export const STOCKSTATUS_LIST_ERROR = "STOCKSTATUS_LIST_ERROR";

export const STOCK_REQUEST_INFO_REQUEST = "STOCK_REQUEST_INFO_REQUEST";
export const STOCK_REQUEST_INFO_SUCCESS = "STOCK_REQUEST_INFO_SUCCESS";
export const STOCK_REQUEST_INFO_ERROR = "STOCK_REQUEST_INFO_ERROR";

export const MANAGE_STOCKREQUEST_DELIVER_REQUEST =
  "MANAGE_STOCKREQUEST_DELIVER_REQUEST";
export const MANAGE_STOCKREQUEST_DELIVER_SUCCESS =
  "MANAGE_STOCKREQUEST_DELIVER_SUCCESS";
export const MANAGE_STOCKREQUEST_DELIVER_ERROR =
  "MANAGE_STOCKREQUEST_DELIVER_ERROR";

export const STOCK_REQUEST_DELIVER_INFO_REQUEST =
  "STOCK_REQUEST_DELIVER_INFO_REQUEST";
export const STOCK_REQUEST_DELIVER_INFO_SUCCESS =
  "STOCK_REQUEST_DELIVER_INFO_SUCCESS";
export const STOCK_REQUEST_DELIVER_INFO_ERROR =
  "STOCK_REQUEST_DELIVER_INFO_ERROR";

export const MANAGE_STOCK_REQUEST = "MANAGE_STOCK_REQUEST";
export const MANAGE_STOCK_SUCCESS = "MANAGE_STOCK_SUCCESS";
export const MANAGE_STOCK_ERROR = "MANAGE_STOCK_ERROR";

export const UPDATE_STOCK_REQUEST_STATUS_REQUEST =
  "UPDATE_STOCK_REQUEST_STATUS_REQUEST";
export const UPDATE_STOCK_REQUEST_STATUS_SUCCESS =
  "UPDATE_STOCK_REQUEST_STATUS_SUCCESS";
export const UPDATE_STOCK_REQUEST_STATUS_ERROR =
  "UPDATE_STOCK_REQUEST_STATUS_ERROR";

export const UPDATE_DELIVER_REQUEST_STATUS_REQUEST =
  "UPDATE_DELIVER_REQUEST_STATUS_REQUEST";
export const UPDATE_DELIVER_REQUEST_STATUS_SUCCESS =
  "UPDATE_DELIVER_REQUEST_STATUS_SUCCESS";
export const UPDATE_DELIVER_REQUEST_STATUS_ERROR =
  "UPDATE_DELIVER_REQUEST_STATUS_ERROR";

export const CENTER_STOCK_LIST_REQUEST = "CENTER_STOCK_LIST_REQUEST";
export const CENTER_STOCK_LIST_SUCCESS = "CENTER_STOCK_LIST_SUCCESS";
export const CENTER_STOCK_LIST_ERROR = "CENTER_STOCK_LIST_ERROR";

export const ITEM_DETAILS_REQUEST = "ITEM_DETAILS_REQUEST";
export const ITEM_DETAILS_SUCCESS = "ITEM_DETAILS_SUCCESS";
export const ITEM_DETAILS_ERROR = "ITEM_DETAILS_ERROR";

export const MANAGE_ITEM_CATEGORY_RQUEST = "MANAGE_ITEM_CATEGORY_RQUEST";
export const MANAGE_ITEM_CATEGORY_SUCECSS = "MANAGE_ITEM_CATEGORY_SUCECSS";
export const MANAGE_ITEM_CATEGORY_ERROR = "MANAGE_ITEM_CATEGORY_ERROR";

export const ITEM_CATEGORY_LIST_REQUEST = "ITEM_CATEGORY_LIST_REQUEST";
export const ITEM_CATEGORY_LIST_SUCCESS = "ITEM_CATEGORY_LIST_SUCCESS";
export const ITEM_CATEGORY_LIST_ERROR = "ITEM_CATEGORY_LIST_ERROR";

export const UPDATE_CETRAL_STOCK_DETAILS_REQUEST =
  "UPDATE_CETRAL_STOCK_DETAILS_REQUEST";
export const UPDATE_CETRAL_STOCK_DETAILS_SUCCESS =
  "UPDATE_CETRAL_STOCK_DETAILS_SUCCESS";
export const UPDATE_CETRAL_STOCK_DETAILS_ERROR =
  "UPDATE_CETRAL_STOCK_DETAILS_ERROR";

export const MENU_REQUEST = "MENU_REQUEST";
export const MENU_SUCCESS = "MENU_SUCCESS";
export const MENU_ERROR = "MENU_ERROR";

export const UNIQUE_KEY_PERMISSION_REQUEST = "UNIQUE_KEY_PERMISSION_REQUEST";
export const UNIQUE_KEY_PERMISSION_SUCCESS = "UNIQUE_KEY_PERMISSION_SUCCESS";
export const UNIQUE_KEY_PERMISSION_ERROR = "UNIQUE_KEY_PERMISSION_ERROR";

export const GET_PERMISSION_LIST_REQUEST = "GET_PERMISSION_LIST_REQUEST";
export const GET_PERMISSION_LIST_SUCCESS = "GET_PERMISSION_LIST_SUCCESS";
export const GET_PERMISSION_LIST_ERROR = "GET_PERMISSION_LIST_ERROR";

export const STAFF_LIST_REQUEST = "STAFF_LIST_REQUEST";
export const STAFF_LIST_SUCCESS = "STAFF_LIST_SUCCESS";
export const STAFF_LIST_ERROR = "STAFF_LIST_ERROR";

export const UPDATE_PERMISSIONS_REQUEST = "UPDATE_PERMISSIONS_REQUEST";
export const UPDATE_PERMISSIONS_SUCCESS = "UPDATE_PERMISSIONS_SUCCESS";
export const UPDATE_PERMISSIONS_ERROR = "UPDATE_PERMISSIONS_ERROR";

export const MANAGE_PHARMACY_RETURN_REQUEST = "MANAGE_PHARMACY_RETURN_REQUEST";
export const MANAGE_PHARMACY_RETURN_SUCCESS = "MANAGE_PHARMACY_RETURN_SUCCESS";
export const MANAGE_PHARMACY_RETURN_ERROR = "MANAGE_PHARMACY_RETURN_ERROR";

export const MANAGE_LEAVETYPE_REQUEST = "MANAGE_LEAVETYPE_REQUEST";
export const MANAGE_LEAVETYPE_SUCCESS = "MANAGE_LEAVETYPE_SUCCESS";
export const MANAGE_LEAVETYPE_ERROR = "MANAGE_LEAVETYPE_ERROR";

export const LEAVETYPE_LIST_REQUEST = "LEAVETYPE_LIST_REQUEST";
export const LEAVETYPE_LIST_SUCCESS = "LEAVETYPE_LIST_SUCCESS";
export const LEAVETYPE_LIST_ERROR = "LEAVETYPE_LIST_ERROR";

export const MANAGE_DESIGNATION_REQUEST = "MANAGE_DESIGNATION_REQUEST";
export const MANAGE_DESIGNATION_SUCCESS = "MANAGE_DESIGNATION_SUCCESS";
export const MANAGE_DESIGNATION_ERROR = "MANAGE_DESIGNATION_ERROR";

export const DESIGNATION_LIST_REQUEST = "DESIGNATION_LIST_REQUEST";
export const DESIGNATION_LIST_SUCCESS = "DESIGNATION_LIST_SUCCESS";
export const DESIGNATION_LIST_ERROR = "DESIGNATION_LIST_ERROR";

export const MANAGE_DEPARTMENT_REQUEST = "MANAGE_DEPARTMENT_REQUEST";
export const MANAGE_DEPARTMENT_SUCCESS = "MANAGE_DEPARTMENT_SUCCESS";
export const MANAGE_DEPARTMENT_ERROR = "MANAGE_DEPARTMENT_ERROR";

export const DEPARTMENT_LIST_REQUEST = "DEPARTMENT_LIST_REQUEST";
export const DEPARTMENT_LIST_SUCCESS = "DEPARTMENT_LIST_SUCCESS";
export const DEPARTMENT_LIST_ERROR = "DEPARTMENT_LIST_ERROR";

export const MANAGE_SPECIALIZATION_REQUEST = "MANAGE_SPECIALIZATION_REQUEST";
export const MANAGE_SPECIALIZATION_SUCCES = "MANAGE_SPECIALIZATION_SUCCES";
export const MANAGE_SPECIALIZATION_ERROR = "MANAGE_SPECIALIZATION_ERROR";

export const SPECIALIZATION_LIST_REQUEST = "SPECIALIZATION_LIST_REQUEST";
export const SPECIALIZATION_LIST_SUCCESS = "SPECIALIZATION_LIST_SUCCESS";
export const SPECIALIZATION_LIST_ERROR = "SPECIALIZATION_LIST_ERROR";

export const STAFF_DETAILS_REQUEST = "STAFF_DETAILS_REQUEST";
export const STAFF_DETAILS_SUCCESS = "STAFF_DETAILS_SUCCESS";
export const STAFF_DETAILS_ERROR = "STAFF_DETAILS_ERROR";

export const ONLINE_ORDER_LIST_REQUEST = "ONLINE_ORDER_LIST_REQUEST";
export const ONLINE_ORDER_LIST_SUCCESS = "ONLINE_ORDER_LIST_SUCCESS";
export const ONLINE_ORDER_LIST_ERROR = "ONLINE_ORDER_LIST_ERROR";

export const ONLINE_ORDER_DETAILS_REQUEST = "ONLINE_ORDER_DETAILS_REQUEST";
export const ONLINE_ORDER_DETAILS_SUCCESS = "ONLINE_ORDER_DETAILS_SUCCESS";
export const ONLINE_ORDER_DETAILS_ERROR = "ONLINE_ORDER_DETAILS_ERROR";

export const ONLINE_PHARMACY_REQUEST = "ONLINE_PHARMACY_REQUEST";
export const ONLINE_PHARMACY_SUCCESS = "ONLINE_PHARMACY_SUCCESS";
export const ONLINE_PHARMACY_ERROR = "ONLINE_PHARMACY_ERROR";

export const MANAGE_ONLINE_ORDERSTATUS_REQUEST =
  "MANAGE_ONLINE_ORDERSTATUS_REQUEST";
export const MANAGE_ONLINE_ORDERSTATUS_SUCCESS =
  "MANAGE_ONLINE_ORDERSTATUS_SUCCESS";
export const MANAGE_ONLINE_ORDERSTATUS_ERROR =
  "MANAGE_ONLINE_ORDERSTATUS_ERROR";

export const MANAGE_ONLINE_DELIVERSTATUS_REQUEST =
  "MANAGE_ONLINE_DELIVERSTATUS_REQUEST";
export const MANAGE_ONLINE_DELIVERSTATUS_SUCCESS =
  "MANAGE_ONLINE_DELIVERSTATUS_SUCCESS";
export const MANAGE_ONLINE_DELIVERSTATUS_ERROR =
  "MANAGE_ONLINE_DELIVERSTATUS_ERROR";

export const REQUEST = "REQUEST";
export const SUCCESS = "SUCCESS";
export const CLEAR = "CLEAR";

export const MANAGE_DOCTORSLOT_REQUEST = "MANAGE_DOCTORSLOT_REQUEST";
export const MANAGE_DOCTORSLOT_SUCCESS = "MANAGE_DOCTORSLOT_SUCCESS";
export const MANAGE_DOCTORSLOT_ERROR = "MANAGE_DOCTORSLOT_ERROR";

export const DOCTORSLOT_LIST_REQUEST = "DOCTORSLOT_LIST_REQUEST";
export const DOCTORSLOT_LIST_SUCCESS = "DOCTORSLOT_LIST_SUCCESS";
export const DOCTORSLOT_LIST_ERROR = "DOCTORSLOT_LIST_ERROR";

export const MANAGE_GUEST_REQUEST = "MANAGE_GUEST_REQUEST";
export const MANAGE_GUEST_SUCCESS = "MANAGE_GUEST_SUCCESS";
export const MANAGE_GUEST_ERROR = "MANAGE_GUEST_ERROR";

export const GUEST_LIST_REQUEST = "GUEST_LIST_REQUEST";
export const GUEST_LIST_SUCCESS = "GUEST_LIST_SUCCESS";
export const GUEST_LIST_ERROR = "GUEST_LIST_ERROR";

export const GUEST_INFO_REQUEST = "GUEST_INFO_REQUEST";
export const GUEST_INFO_SUCCESS = "GUEST_INFO_SUCCESS";
export const GUEST_INFO_ERROR = "GUEST_INFO_ERROR";

export const CENTER_INFO_REQUEST = "CENTER_INFO_REQUEST";
export const CENTER_INFO_SUCCESS = "CENTER_INFO_SUCCESS";
export const CENTER_INFO_ERROR = "CENTER_INFO_ERROR";

export const MANAGE_IDC_TICKET_REQUEST = "MANAGE_IDC_TICKET_REQUEST";
export const MANAGE_IDC_TICKET_SUCCESS = "MANAGE_IDC_TICKET_SUCCESS";
export const MANAGE_IDC_TICKET_ERROR = "MANAGE_IDC_TICKET_ERROR";

export const MANAGE_CENTER_REQUEST = "MANAGE_CENTER_REQUEST";
export const MANAGE_CENTER_SUCCESS = "MANAGE_CENTER_SUCCESS";
export const MANAGE_CENTER_ERROR = "MANAGE_CENTER_ERROR";

export const DELETE_ITEM_REQUEST = "DELETE_ITEM_REQUEST";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_ERROR = "DELETE_ITEM_ERROR";

export const MANAGE_REPORT_OVERVIEW_REQUEST = "MANAGE_REPORT_OVERVIEW_REQUEST";
export const MANAGE_REPORT_OVERVIEW_SUCCESS = "MANAGE_REPORT_OVERVIEW_SUCCESS";
export const MANAGE_REPORT_OVERVIEW_ERROR = "MANAGE_REPORT_OVERVIEW_ERROR";

export const MANAGE_CASHSUMMARY_REQUEST = "MANAGE_CASHSUMMARY_REQUEST";
export const MANAGE_CASHSUMMARY_SUCCESS = "MANAGE_CASHSUMMARY_SUCCESS";
export const MANAGE_CASHSUMMARY_ERROR = "MANAGE_CASHSUMMARY_ERROR";

export const MANAGE_REPORT_REQUEST = "MANAGE_REPORT_REQUEST";
export const MANAGE_REPORT_SUCCESS = "MANAGE_REPORT_SUCCESS";
export const MANAGE_REPORT_ERROR = "MANAGE_REPORT_ERROR";

export const MANAGE_CENTRALSTOCK_REQUEST = "MANAGE_CENTRALSTOCK_REQUEST";
export const MANAGE_CENTRALSTOCK_SUCCESS = "MANAGE_CENTRALSTOCK_SUCCESS";
export const MANAGE_CENTRALSTOCK_ERROR = "MANAGE_CENTRALSTOCK_ERROR";

export const UPLOAD_CENTER_STOCK_REQUEST = "UPLOAD_CENTER_STOCK_REQUEST";
export const UPLOAD_CENTER_STOCK_SUCCESS = "UPLOAD_CENTER_STOCK_SUCCESS";
export const UPLOAD_CENTER_STOCK_ERROR = "UPLOAD_CENTER_STOCK_ERROR";

export const UPDATE_PROFILEPIC_REQUEST = "UPDATE_PROFILEPIC_REQUEST";
export const UPDATE_PROFILEPIC_SUCCESS = "UPDATE_PROFILEPIC_SUCCESS";
export const UPDATE_PROFILEPIC_ERROR = "UPDATE_PROFILEPIC_ERROR";

export const MANAGE_CENTER_STOCK_REQUEST = "MANAGE_CENTER_STOCK_REQUEST";
export const MANAGE_CENTER_STOCK_SUCCESS = "MANAGE_CENTER_STOCK_SUCCESS";
export const MANAGE_CENTER_STOCK_ERROR = "MANAGE_CENTER_STOCK_ERROR";

export const V2_MANAGE_CENTER_STOCK_REQUEST = "V2_MANAGE_CENTER_STOCK_REQUEST";
export const V2_MANAGE_CENTER_STOCK_SUCCESS = "V2_MANAGE_CENTER_STOCK_SUCCESS";
export const V2_MANAGE_CENTER_STOCK_ERROR = "V2_MANAGE_CENTER_STOCK_ERROR";

export const V2_MANAGE_PHARMAACY_REQUEST = "V2_MANAGE_PHARMAACY_REQUEST";
export const V2_MANAGE_PHARMAACY_SUCCESS = "V2_MANAGE_PHARMAACY_SUCCESS";
export const V2_MANAGE_PHARMAACY_ERROR = "V2_MANAGE_PHARMAACY_ERROR";

export const V2_MANAGE_STOCK_INVOICE_REQUEST =
  "V2_MANAGE_STOCK_INVOICE_REQUEST";
export const V2_MANAGE_STOCK_INVOICE_SUCCESS =
  "V2_MANAGE_STOCK_INVOICE_SUCCESS";
export const V2_MANAGE_STOCK_INVOICE_ERROR = "V2_MANAGE_STOCK_INVOICE_ERROR";

export * from "./setup/action";
export * from "./pharmacy/action";
export * from "./patient/action";
export * from "./staff/action";
export * from "./user/action";
export * from "./inventory/action";
export * from "./supplier/action";
export * from "./invoice/action";
export * from "./center/action";
export * from "./guest/action";
export * from "./onlinepharmacy/action";
export * from "./doctor/action";
export * from "./ticket/action";
export * from "./v2pharmacy/action";
