import {
  GUEST_INFO_ERROR,
  GUEST_INFO_REQUEST,
  GUEST_INFO_SUCCESS,
  GUEST_LIST_ERROR,
  GUEST_LIST_REQUEST,
  GUEST_LIST_SUCCESS,
  MANAGE_GUEST_ERROR,
  MANAGE_GUEST_REQUEST,
  MANAGE_GUEST_SUCCESS,
} from "../actions";

export const manageGuestRequest = (request) => ({
  type: MANAGE_GUEST_REQUEST,
  payload: request,
});
export const manageGuestSuccess = (response) => ({
  type: MANAGE_GUEST_SUCCESS,
  payload: response,
});
export const manageGuestError = (message) => ({
  type: MANAGE_GUEST_ERROR,
  payload: message,
});

export const guestListRequest = (request) => ({
  type: GUEST_LIST_REQUEST,
  payload: request,
});
export const guestListSuccess = (response) => ({
  type: GUEST_LIST_SUCCESS,
  payload: response,
});
export const guestListError = (message) => ({
  type: GUEST_LIST_ERROR,
  payload: message,
});

export const guestInfoRequest = (request) => ({
  type: GUEST_INFO_REQUEST,
  payload: request,
});
export const guestInfoSuccess = (response) => ({
  type: GUEST_INFO_SUCCESS,
  payload: response,
});
export const guestInfoError = (message) => ({
  type: GUEST_INFO_ERROR,
  payload: message,
});
