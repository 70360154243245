import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { objectToQueryString } from "../../helpers/Utils";
import {
  MANAGE_ONLINE_DELIVERSTATUS_REQUEST,
  MANAGE_ONLINE_ORDERSTATUS_REQUEST,
  ONLINE_ORDER_DETAILS_REQUEST,
  ONLINE_ORDER_LIST_REQUEST,
  ONLINE_PHARMACY_REQUEST,
  ONLINE_PHARMACY_REQUEST_,
} from "../actions";
import { GET, POST, DELETE, LIST, PATCH } from "../api";
import {
  manageOnlineDeliverStatusError,
  manageOnlineDeliverStatusSuccess,
  manageOnlineOrderStatusError,
  manageOnlineOrderStatusSuccess,
  onlineOrderDetailsError,
  onlineOrderDetailsSuccess,
  onlineOrderListError,
  onlineOrderListSuccess,
  onlinePharmacyError,
  onlinePharmacySuccess,
} from "./action";

export function* watchOnlineOrderList() {
  yield takeLatest(ONLINE_ORDER_LIST_REQUEST, onlineOrderList);
}

function* onlineOrderList({ payload }) {
  const url = `/onlinepharmacy/orders?${objectToQueryString(payload)}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(onlineOrderListError(response.message));
    } else {
      yield put(onlineOrderListSuccess(response));
    }
  } catch (error) {
    yield put(onlineOrderListError(error));
  }
}

export function* watchOnlineOrderDetails() {
  yield takeLatest(ONLINE_ORDER_DETAILS_REQUEST, onlineOrderDetails);
}

function* onlineOrderDetails({ payload }) {
  const url = `/onlinepharmacy/orders/${payload}`;
  try {
    const response = yield call(GET, url, payload);
    if (response && response.is_error) {
      yield put(onlineOrderDetailsError(response.message));
    } else {
      yield put(onlineOrderDetailsSuccess(response));
    }
  } catch (error) {
    yield put(onlineOrderDetailsError(error));
  }
}

export function* watchOnlinePharmacy() {
  yield takeLatest(ONLINE_PHARMACY_REQUEST, onlinePharmacy);
}

function* onlinePharmacy({ payload }) {
  const url = `/onlinepharmacy/pharmacyinfo/${payload}`;
  try {
    const response = yield call(GET, url, payload);
    if (response && response.is_error) {
      yield put(onlinePharmacyError(response.message));
    } else {
      yield put(onlinePharmacySuccess(response));
    }
  } catch (error) {
    yield put(onlinePharmacyError(error));
  }
}

export function* watchManageOnlineStatus() {
  yield takeLatest(MANAGE_ONLINE_ORDERSTATUS_REQUEST, manageOnlineOrderStatus);
}

function* manageOnlineOrderStatus({ payload }) {
  const url = `/onlinepharmacy/OrderStatus`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageOnlineOrderStatusError(response.message));
    } else {
      yield put(manageOnlineOrderStatusSuccess(response));
    }
  } catch (error) {
    yield put(manageOnlineOrderStatusError(error));
  }
}

export function* watchManageOnlineDeliverStatus() {
  yield takeLatest(
    MANAGE_ONLINE_DELIVERSTATUS_REQUEST,
    manageOnlineDeliverStatus
  );
}

function* manageOnlineDeliverStatus({ payload }) {
  const url = `/onlinepharmacy/DeliverStatus`;
  try {
    const response = yield call(
      payload && payload.deliver_id ? PATCH : POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageOnlineDeliverStatusError(response.message));
    } else {
      yield put(manageOnlineDeliverStatusSuccess(response));
    }
  } catch (error) {
    yield put(manageOnlineDeliverStatusError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchOnlineOrderList),
    fork(watchOnlineOrderDetails),
    fork(watchOnlinePharmacy),
    fork(watchManageOnlineStatus),
    fork(watchManageOnlineDeliverStatus),
  ]);
}
