import { GET, POST, DELETE, LIST, PATCH, FORMDATA_POST } from "../api";
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { MANAGE_REPORT_REQUEST } from "../actions";
import { manageReportError, manageReportSuccess } from "./action";

export function* watchManageReport() {
  yield takeLatest(MANAGE_REPORT_REQUEST, manageChat);
}

function* manageChat({ payload, request_type }) {
  let url, response;
  try {
    if (request_type == "CUSTOM_REPORT") {
      url = `/reports/customreport`;
      response = yield call(POST, url, payload);
    } else if (request_type == "PATIENT_WISE_REPORT") {
      url = `/reports/patientwisereport`;
      response = yield call(POST, url, payload);
    } else if (request_type == "CENTER_REVENUE") {
      url = `/reports/centerrevenue`;
      response = yield call(POST, url, payload);
    } else if (request_type == "DOCTOR_REVENUE") {
      url = `/reports/doctorrevenue`;
      response = yield call(POST, url, payload);
    } else if (request_type == "GUEST_REVENUE") {
      url = `/reports/guestrevenue`;
      response = yield call(POST, url, payload);
    } else if (request_type == "CHANNEL_REVENUE") {
      url = `/reports/channelrevenue`;
      response = yield call(POST, url, payload);
    } else if (request_type == "REVENUE_REPORT") {
      url = `/reports/revenuereport`;
      response = yield call(POST, url, payload);
    } else if (request_type == "DUE_REPORT") {
      url = `/reports/duereport`;
      response = yield call(POST, url, payload);
    } else if (request_type == "DOCTOR_INCENTIVE") {
      url = `/reports/doctorincentive`;
      response = yield call(POST, url, payload);
    } else if (request_type == "REFERAL_DOCTOR_COMISSION") {
      url = `/reports/referaldoctorcomission`;
      response = yield call(POST, url, payload);
    } else if (request_type == "CLINIC_SUMMARY") {
      url = `/reports/clinicsummary`;
      response = yield call(POST, url, payload);
    } else if (request_type == "PHARMACY_SUMMARY") {
      url = `/reports/pharmacysummary`;
      response = yield call(POST, url, payload);
    } else if (request_type == "STOCK_REPORT") {
      url = `/reports/stockreport`;
      response = yield call(POST, url, payload);
    } else if (request_type == "PHARMACY_DUE_REPORT") {
      url = `/reports/pharmacyduereport`;
      response = yield call(POST, url, payload);
    } else if (request_type == "PURCHASE_GST") {
      url = `/reports/purchasegst`;
      response = yield call(POST, url, payload);
    } else if (request_type == "SALES_GST") {
      url = `/reports/salesgst`;
      response = yield call(POST, url, payload);
    } else if (request_type == "SALES_REPORT") {
      url = `/reports/salesreport`;
      response = yield call(POST, url, payload);
    } else if (request_type == "MARGIN_REPORT") {
      url = `/reports/marginreport`;
      response = yield call(POST, url, payload);
    } else if (request_type == "PURCHASE_REPORT") {
      url = `/reports/purchasereport`;
      response = yield call(POST, url, payload);
    } else if (request_type == "BALANCE_REPORT") {
      url = `/reports/stockbalancereport`;
      response = yield call(POST, url, payload);
    } else {
      yield put(manageReportError("Invalid URL"));
    }
    if (response && response.is_error) {
      yield put(manageReportError(response.message));
    } else {
      yield put(manageReportSuccess(response, request_type));
    }
  } catch (error) {
    yield put(manageReportError(error));
  }
}

export default function* rootSaga() {
  yield all([fork(watchManageReport)]);
}
