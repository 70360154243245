import {
  MANAGE_STOCKREQUEST_DELIVER_ERROR,
  MANAGE_STOCKREQUEST_DELIVER_REQUEST,
  MANAGE_STOCKREQUEST_DELIVER_SUCCESS,
  MANAGE_STOCKREQUEST_ERROR,
  MANAGE_STOCKREQUEST_REQUEST,
  MANAGE_STOCKREQUEST_SUCCESS,
  MANAGE_STOCK_ERROR,
  MANAGE_STOCK_REQUEST,
  MANAGE_STOCK_SUCCESS,
  STOCKREQUEST_LIST_ERROR,
  STOCKREQUEST_LIST_REQUEST,
  STOCKREQUEST_LIST_SUCCESS,
  STOCKSTATUS_LIST_REQUEST,
  STOCKSTATUS_LIST_SUCCESS,
  STOCKSTATUS_LIST_ERROR,
  STOCK_REQUEST_DELIVER_INFO_ERROR,
  STOCK_REQUEST_DELIVER_INFO_REQUEST,
  STOCK_REQUEST_DELIVER_INFO_SUCCESS,
  STOCK_REQUEST_INFO_ERROR,
  STOCK_REQUEST_INFO_REQUEST,
  STOCK_REQUEST_INFO_SUCCESS,
  UPDATE_STOCK_REQUEST_STATUS_REQUEST,
  UPDATE_STOCK_REQUEST_STATUS_SUCCESS,
  UPDATE_STOCK_REQUEST_STATUS_ERROR,
  UPDATE_DELIVER_REQUEST_STATUS_REQUEST,
  UPDATE_DELIVER_REQUEST_STATUS_SUCCESS,
  UPDATE_DELIVER_REQUEST_STATUS_ERROR,
  CENTER_STOCK_LIST_REQUEST,
  CENTER_STOCK_LIST_SUCCESS,
  CENTER_STOCK_LIST_ERROR,
  MANAGE_CENTER_REQUEST,
  MANAGE_CENTER_SUCCESS,
  MANAGE_CENTER_ERROR,
  UPLOAD_CENTER_STOCK_REQUEST,
  UPLOAD_CENTER_STOCK_SUCCESS,
  UPLOAD_CENTER_STOCK_ERROR,
  MANAGE_CENTER_STOCK_REQUEST,
  MANAGE_CENTER_STOCK_SUCCESS,
  MANAGE_CENTER_STOCK_ERROR,
} from "../actions";

const INIT = {
  center_loading: false,
  center_action: null,
  center_success: null,
  center_error: null,
  stock_request_list: [],
  stock_request_info: null,
  stock_request_deliver_info: null,
  center_stock_list: [],
  center_list: [],
  center: null,
  center_request_type: null,
  invoice_item_info: null,
};

export default (state = INIT, action) => {
  switch (action.type) {
    case MANAGE_STOCKREQUEST_REQUEST:
      return {
        ...state,
        center_loading: true,
        center_success: null,
        center_error: null,
        center_action: action.type,
      };
    case MANAGE_STOCKREQUEST_SUCCESS: {
      return {
        ...state,
        center_loading: false,
        center_success: action.payload,
      };
    }
    case MANAGE_STOCKREQUEST_ERROR: {
      return {
        ...state,
        center_loading: false,
        center_error: action.payload,
      };
    }

    case STOCKREQUEST_LIST_REQUEST:
      return {
        ...state,
        center_loading: true,
        center_success: null,
        center_error: null,
        center_action: action.type,
        stock_request_list: [],
      };
    case STOCKREQUEST_LIST_SUCCESS: {
      return {
        ...state,
        center_loading: false,
        stock_request_list: action.payload,
      };
    }
    case STOCKREQUEST_LIST_ERROR: {
      return {
        ...state,
        center_loading: false,
        center_error: action.payload,
      };
    }

    case STOCKSTATUS_LIST_REQUEST:
      return {
        ...state,
        center_loading: true,
        center_success: null,
        center_error: null,
        center_action: action.type,
        stock_request_list: [],
      };
    case STOCKSTATUS_LIST_SUCCESS: {
      return {
        ...state,
        center_loading: false,
        stock_request_list: action.payload,
      };
    }
    case STOCKSTATUS_LIST_ERROR: {
      return {
        ...state,
        center_loading: false,
        center_error: action.payload,
      };
    }

    case STOCK_REQUEST_INFO_REQUEST:
      return {
        ...state,
        center_loading: true,
        center_success: null,
        center_error: null,
        center_action: action.type,
        stock_request_info: null,
      };
    case STOCK_REQUEST_INFO_SUCCESS: {
      return {
        ...state,
        center_loading: false,
        stock_request_info: action.payload,
      };
    }
    case STOCK_REQUEST_INFO_ERROR: {
      return {
        ...state,
        center_loading: false,
        center_error: action.payload,
      };
    }

    case MANAGE_STOCKREQUEST_DELIVER_REQUEST:
      return {
        ...state,
        center_loading: true,
        center_success: null,
        center_error: null,
        center_action: action.type,
      };
    case MANAGE_STOCKREQUEST_DELIVER_SUCCESS: {
      return {
        ...state,
        center_loading: false,
        center_success: action.payload,
      };
    }
    case MANAGE_STOCKREQUEST_DELIVER_ERROR: {
      return {
        ...state,
        center_loading: false,
        center_error: action.payload,
      };
    }

    case STOCK_REQUEST_DELIVER_INFO_REQUEST:
      return {
        ...state,
        center_loading: true,
        center_success: null,
        center_error: null,
        center_action: action.type,
        stock_request_deliver_info: null,
      };
    case STOCK_REQUEST_DELIVER_INFO_SUCCESS: {
      return {
        ...state,
        center_loading: false,
        stock_request_deliver_info: action.payload,
      };
    }
    case STOCK_REQUEST_DELIVER_INFO_ERROR: {
      return {
        ...state,
        center_loading: false,
        center_error: action.payload,
      };
    }

    case MANAGE_STOCK_REQUEST:
      return {
        ...state,
        center_loading: true,
        center_success: null,
        center_error: null,
        center_action: action.type,
      };
    case MANAGE_STOCK_SUCCESS: {
      return {
        ...state,
        center_loading: false,
        center_success: action.payload,
      };
    }
    case MANAGE_STOCK_ERROR: {
      return {
        ...state,
        center_loading: false,
        center_error: action.payload,
      };
    }

    case UPDATE_STOCK_REQUEST_STATUS_REQUEST:
      return {
        ...state,
        center_loading: true,
        center_error: null,
        center_success: null,
        center_action: action.type,
      };
    case UPDATE_STOCK_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
        center_loading: false,
        center_success: action.payload,
      };

    case UPDATE_STOCK_REQUEST_STATUS_ERROR:
      return {
        ...state,
        center_loading: false,
        center_error: action.payload,
      };

    case UPDATE_DELIVER_REQUEST_STATUS_REQUEST:
      return {
        ...state,
        center_loading: true,
        center_error: null,
        center_success: null,
        center_action: action.type,
      };
    case UPDATE_DELIVER_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
        center_loading: false,
        center_success: action.payload,
      };

    case UPDATE_DELIVER_REQUEST_STATUS_ERROR:
      return {
        ...state,
        center_loading: false,
        center_error: action.payload,
      };
    case CENTER_STOCK_LIST_REQUEST:
      return {
        ...state,
        center_loading: true,
        center_error: null,
        center_success: null,
        center_action: action.type,
        center_stock_list: [],
      };
    case CENTER_STOCK_LIST_SUCCESS:
      return {
        ...state,
        center_loading: false,
        center_stock_list: action.payload,
      };

    case CENTER_STOCK_LIST_ERROR:
      return {
        ...state,
        center_loading: false,
        center_error: action.payload,
      };

    case MANAGE_CENTER_REQUEST:
      return {
        ...state,
        center_loading: true,
        center_error: null,
        center_success: null,
        center_action: action.type,
        center_request_type: action.request_type,
      };
    case MANAGE_CENTER_SUCCESS:
      if (action.request_type == "ADD") {
        return {
          ...state,
          center_loading: false,
          center_success: action.payload,
        };
      } else if (action.request_type == "EDIT") {
        return {
          ...state,
          center_loading: false,
          center_success: action.payload,
        };
      } else if (action.request_type == "LIST") {
        return {
          ...state,
          center_loading: false,
          center_list: action.payload,
        };
      } else if (action.request_type == "GET") {
        return {
          ...state,
          center_loading: false,
          center: action.payload,
        };
      }

    case MANAGE_CENTER_ERROR:
      return {
        ...state,
        center_loading: false,
        center_error: action.payload,
      };

    case UPLOAD_CENTER_STOCK_REQUEST:
      return {
        ...state,
        center_loading: true,
        center_error: null,
        center_success: null,
        center_action: action.type,
        center_request_type: action.request_type,
      };
    case UPLOAD_CENTER_STOCK_SUCCESS:
      if (action.request_type == "ADD") {
        return {
          ...state,
          center_loading: false,
          center_success: action.payload,
        };
      }

    case UPLOAD_CENTER_STOCK_ERROR:
      return {
        ...state,
        center_loading: false,
        center_error: action.payload,
      };

    case MANAGE_CENTER_STOCK_REQUEST:
      return {
        ...state,
        center_loading: true,
        center_error: null,
        center_success: null,
        center_action: action.type,
        center_request_type: action.request_type,
      };
    case MANAGE_CENTER_STOCK_SUCCESS:
      if (action.request_type == "INVOICE_ITEM_INFO") {
        return {
          ...state,
          center_loading: false,
          invoice_item_info: action.payload,
        };
      } else if (action.request_type == "RETURN_ITEM") {
        return {
          ...state,
          center_loading: false,
          center_success: action.payload,
        };
      }

    case MANAGE_CENTER_STOCK_ERROR:
      return {
        ...state,
        center_loading: false,
        center_error: action.payload,
      };
    default:
      return { ...state };
  }
};
