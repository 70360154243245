import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { objectToQueryString } from "../../helpers/Utils";
import {
  MANAGE_SUPPLIER_INVOICE_REQUEST,
  MANAGE_SUPPLIER_PAYMENT_REQUEST,
  MANAGE_SUPPLIER_DELIVERY_REQUEST,
  MANAGE_SUPPLIER_ORDER_REQUEST,
  SUPPLIER_ORDER_LIST_REQUEST,
  SUPPLIER_ORDER_REQUEST,
  MANAGE_SUPPLIER_QUOTATION_REQUEST,
  SUPPLIER_ORDER_ITEMS_REQUEST,
  MANAGE_DELIVERY_HISTORY_REQUEST,
  MANAGE_ORDER_STATUS_REQUEST,
  MANAGE_QUOTATION_STATUS_REQUEST,
  MANAGE_INVOICE_STATUS_REQUEST,
  MANAGE_DELIVER_STATUS_REQUEST,
} from "../actions";
import {
  GET,
  POST,
  DELETE,
  LIST,
  PATCH,
  FORMDATA_POST,
  FORMDATA_PATCH,
} from "../api";
import {
  manageSuppierInvoiceError,
  manageSuppierInvoiceSuccess,
  manageSupplierPaymentError,
  manageSupplierPaymentSuccess,
  manageSupplierOrderError,
  manageSupplierOrderSuccess,
  supplierOrderErorr,
  supplierOrderListError,
  supplierOrderListSuccess,
  supplierOrderSuccess,
  manageSupplierQuotationError,
  manageSupplierQuotationSuccess,
  supplierOrderItemsError,
  supplierOrderItemsSuccess,
  manageDeliveryHistoryError,
  manageDeliveryHistorySuccess,
  utilsListError,
  utilsListSuccess,
  manageOrderStatusError,
  manageOrderStatusSuccess,
  manageQuotationStatusError,
  manageQuotationStatusSuccess,
  manageInvoiceStatusError,
  manageInvoiceStatusSuccess,
  manageDeliverStatusError,
  manageDeliverStatusSuccess,
} from "./action";
export function* watchManageSupplierInvoice() {
  yield takeLatest(MANAGE_SUPPLIER_INVOICE_REQUEST, manageSupplierInvoice);
}

function* manageSupplierInvoice({ payload }) {
  const url = `/supplier/invoice`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageSuppierInvoiceError(response.message));
    } else {
      yield put(manageSuppierInvoiceSuccess(response));
    }
  } catch (error) {
    yield put(manageSuppierInvoiceError(error));
  }
}

export function* watchSupplierPayment() {
  yield takeLatest(MANAGE_SUPPLIER_PAYMENT_REQUEST, manageSupplierPayment);
}

function* manageSupplierPayment({ payload }) {
  const url = `/supplier/payment`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageSupplierPaymentError(response.message));
    } else {
      yield put(manageSupplierPaymentSuccess(response));
    }
  } catch (error) {
    yield put(manageSupplierPaymentError(error));
  }
}

export function* watchManageSupplierOrder() {
  yield takeLatest(MANAGE_SUPPLIER_ORDER_REQUEST, manageSupplierRequest);
}

function* manageSupplierRequest({ payload }) {
  const url = "/supplier/order";
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageSupplierOrderError(response.message));
    } else {
      yield put(manageSupplierOrderSuccess(response));
    }
  } catch (error) {
    yield put(manageSupplierOrderError(error));
  }
}

export function* watchSupplierOrderList() {
  yield takeLatest(SUPPLIER_ORDER_LIST_REQUEST, supplierOrderList);
}

function* supplierOrderList({ payload }) {
  const url = "/supplier/order";
  try {
    const response = yield call(LIST, url, payload);

    if (response && response.is_error) {
      yield put(supplierOrderListError(response.message));
    } else {
      yield put(supplierOrderListSuccess(response));
    }
  } catch (error) {
    yield put(supplierOrderListError(error));
  }
}

export function* watchSupplierOrder() {
  yield takeLatest(SUPPLIER_ORDER_REQUEST, supplierOrder);
}

function* supplierOrder({ payload }) {
  const url = `/supplier/order/${payload}`;
  try {
    const response = yield call(GET, url, payload);
    if (response && response.is_error) {
      yield put(supplierOrderErorr(response.message));
    } else {
      yield put(supplierOrderSuccess(response));
    }
  } catch (error) {
    yield put(supplierOrderErorr(error));
  }
}

export function* watchManageSupplierDelivery() {
  yield takeLatest(MANAGE_SUPPLIER_DELIVERY_REQUEST, manageSupplierDeliver);
}

function* manageSupplierDeliver({ payload }) {
  const url = `/supplier/supplierdelivery`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageSupplierOrderError(response.message));
    } else {
      yield put(manageSupplierOrderSuccess(response));
    }
  } catch (error) {
    yield put(manageSupplierOrderError(error));
  }
}

export function* wathManageSupplierQuotation() {
  yield takeLatest(MANAGE_SUPPLIER_QUOTATION_REQUEST, manageSupplierQuotation);
}

function* manageSupplierQuotation({ payload }) {
  const url = `/supplier/quotation`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageSupplierQuotationError(response.message));
    } else {
      yield put(manageSupplierQuotationSuccess(response));
    }
  } catch (error) {
    yield put(manageSupplierQuotationError(error));
  }
}

export function* watchOrderItems() {
  yield takeLatest(SUPPLIER_ORDER_ITEMS_REQUEST, orderItems);
}

function* orderItems({ payload }) {
  const url = `/supplier/OrderInfo?${objectToQueryString(payload)}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(supplierOrderItemsError(response.message));
    } else {
      yield put(supplierOrderItemsSuccess(response));
    }
  } catch (error) {
    yield put(supplierOrderItemsError(error));
  }
}

export function* wathManageDeliveryHistory() {
  yield takeLatest(MANAGE_DELIVERY_HISTORY_REQUEST, manageDeliveryHistory);
}

function* manageDeliveryHistory({ payload }) {
  const url = `/supplier/deliverhistory`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageDeliveryHistoryError(response.message));
    } else {
      yield put(manageDeliveryHistorySuccess(response));
    }
  } catch (error) {
    yield put(manageDeliveryHistoryError(error));
  }
}

export function* watchManageOrderStatus() {
  yield takeLatest(MANAGE_ORDER_STATUS_REQUEST, manageOrderStatus);
}

function* manageOrderStatus({ payload }) {
  const url = `/supplier/updateorderstatus`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageOrderStatusError(response.message));
    } else {
      yield put(manageOrderStatusSuccess(response));
    }
  } catch (error) {
    yield put(manageOrderStatusError(error));
  }
}

export function* watchManageQuotationStatus() {
  yield takeLatest(MANAGE_QUOTATION_STATUS_REQUEST, manageQuotationStatus);
}

function* manageQuotationStatus({ payload }) {
  const url = `/supplier/updateQuotationstatus`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageQuotationStatusError(response.message));
    } else {
      yield put(manageQuotationStatusSuccess(response));
    }
  } catch (error) {
    yield put(manageQuotationStatusError(error));
  }
}

export function* watchManageInvoiceStatus() {
  yield takeLatest(MANAGE_INVOICE_STATUS_REQUEST, manageInvoiceStatus);
}

function* manageInvoiceStatus({ payload }) {
  const url = `/supplier/updateinvoicetatus`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageInvoiceStatusError(response.message));
    } else {
      yield put(manageInvoiceStatusSuccess(response));
    }
  } catch (error) {
    yield put(manageInvoiceStatusError(error));
  }
}

export function* watchManageDeliverStatus() {
  yield takeLatest(MANAGE_DELIVER_STATUS_REQUEST, manageDeliverStatus);
}

function* manageDeliverStatus({ payload }) {
  const url = `/supplier/updatedeliverstatus`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageDeliverStatusError(response.message));
    } else {
      yield put(manageDeliverStatusSuccess(response));
    }
  } catch (error) {
    yield put(manageDeliverStatusError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchManageSupplierInvoice),
    fork(watchSupplierPayment),
    fork(watchManageSupplierOrder),
    fork(watchSupplierOrderList),
    fork(watchSupplierOrder),
    fork(watchManageSupplierDelivery),
    fork(wathManageSupplierQuotation),
    fork(watchOrderItems),
    fork(wathManageDeliveryHistory),
    fork(watchManageOrderStatus),
    fork(watchManageQuotationStatus),
    fork(watchManageInvoiceStatus),
    fork(watchManageDeliverStatus),
  ]);
}
