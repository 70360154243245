import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  GET,
  POST,
  DELETE,
  LIST,
  PATCH,
  FORMDATA_POST,
  FORMDATA_PATCH,
} from "../api";

import {
  AUTO_MEDICINE_SUGGEST_REQUEST,
  BATCHNO_LIST_REQUEST,
  BILL_BASIC_LIST_REQUEST,
  BILL_REQUEST,
  DELETE_MEDICINE_REQUEST,
  EXPIRY_DATE_REQUEST,
  MANAGE_BILL_REQUEST,
  MANAGE_CASHSUMMARY_REQUEST,
  MANAGE_MEDICINE_REQUEST,
  MANAGE_ORDER_STATUS_REQUEST,
  MANAGE_PHARMACY_RETURN_REQUEST,
  MANAGE_PURCHASE_REQUEST,
  MANAGE_REPORT_OVERVIEW_REQUEST,
  MEDICINE_LIST_REQUEST,
  MEDICINE_REQUEST,
  PURCHASE_BASIC_LIST_REQUEST,
  PURCHASE_REQUEST,
  SUPPLIER_TRACKING_REQUEST,
} from "../actions";
import {
  autoMedicineSuggestError,
  autoMedicineSuggestSuccess,
  batchNoListError,
  batchNoListSuccess,
  billBasicListError,
  billBasicListSuccess,
  billError,
  billSuccess,
  deleteMedicineError,
  deleteMedicineSuccess,
  expiryDateError,
  expiryDateSuccess,
  manageBillError,
  manageBillSuccess,
  manageCashSummaryError,
  manageCashSummarySuccess,
  manageMedicineError,
  manageMedicineSuccess,
  manageOrderStatusError,
  manageOrderStatusSuccess,
  managePharmacyReturnError,
  managePharmacyReturnSuccess,
  managePurchaseError,
  managePurchaseSuccess,
  manageReportOverViewError,
  manageReportOverViewSuccess,
  medicineError,
  medicineListError,
  medicineListSuccess,
  medicineSuccess,
  purchaseBasicListError,
  purchaseBasicListSuccess,
  purchaseError,
  purchaseSuccess,
  supplierTrackingError,
  supplierTrackingSuccess,
} from "./action";
import { objectToQueryString } from "../../helpers/Utils";

export function* watchManageMedicine() {
  yield takeLatest(MANAGE_MEDICINE_REQUEST, manageMedicine);
}

function* manageMedicine({ payload }) {
  // var object = {};
  // payload.forEach((value, key) => (object[key] = value));
  // var json = JSON.stringify(object);

  const url = "/pharmacy/medicine";
  try {
    const response = yield call(FORMDATA_POST, url, payload);
    if (response && response.is_error) {
      yield put(manageMedicineError(response.message));
    } else {
      yield put(manageMedicineSuccess(response));
    }
  } catch (error) {
    yield put(manageMedicineError(error));
  }
}

export function* watchDeleteMedicine() {
  yield takeLatest(DELETE_MEDICINE_REQUEST, deleteMedicine);
}

function* deleteMedicine({ payload }) {
  const url = `/pharmacy/medicine/${payload.id}`;
  try {
    const response = yield call(DELETE, url);
    if (response && response.is_error) {
      yield put(deleteMedicineError(response.message));
    } else {
      yield put(deleteMedicineSuccess(response));
    }
  } catch (error) {
    yield put(deleteMedicineError(error));
  }
}

export function* watchMedicineList() {
  yield takeLatest(MEDICINE_LIST_REQUEST, medicineList);
}

function* medicineList({ payload }) {
  try {
    const url = "/pharmacy/medicine";
    const response = yield call(LIST, url);
    if (response && response.is_error) {
      yield put(medicineListError(response.message));
    } else {
      yield put(medicineListSuccess(response));
    }
  } catch (error) {
    yield put(medicineListError(error));
  }
}

export function* watchManagePurchase() {
  yield takeLatest(MANAGE_PURCHASE_REQUEST, managePurchase);
}

function* managePurchase({ payload }) {
  var object = {};
  payload.forEach((value, key) => (object[key] = value));
  var json = JSON.stringify(object);
  try {
    const url = "/pharmacy/purchase";
    const response = yield call(FORMDATA_POST, url, payload);
    console.log(response);
    if (response && response.is_error) {
      yield put(managePurchaseError(response.message));
    } else {
      yield put(managePurchaseSuccess(response));
    }
  } catch (error) {
    yield put(managePurchaseError(error));
  }
}

export function* watchAutoMedicines() {
  yield takeLatest(AUTO_MEDICINE_SUGGEST_REQUEST, autoMedicines);
}

function* autoMedicines({ payload }) {
  if (payload) {
    const url = `/utils/autosuggest?search=${payload}&type=items`;
    try {
      const response = yield call(GET, url);
      if (response && response.is_error) {
        yield put(autoMedicineSuggestError(response.message));
      } else {
        response.map(async (x, i) => {
          // x["label"] = x.medicine_name;
          // x["value"] = x.id;
          x["label"] = x.item_name;
          x["value"] = x.item_id;
          await x;
        });
        yield put(autoMedicineSuggestSuccess(response));
      }
    } catch (error) {
      yield put(autoMedicineSuggestError(error));
    }
  }
}

export function* watchBatchNoList() {
  yield takeLatest(BATCHNO_LIST_REQUEST, batchNoList);
}

function* batchNoList({ payload }) {
  const url = `/pharmacy/BatchList?item_id=${payload}`;
  try {
    const response = yield call(GET, url);
    if (response && response.is_error) {
      yield put(batchNoListError(response.message));
    } else {
      yield put(batchNoListSuccess(response));
    }
  } catch (error) {
    yield put(batchNoListError(error));
  }
}

export function* watchExpiryDate() {
  yield takeLatest(EXPIRY_DATE_REQUEST, getExpiryDate);
}

function* getExpiryDate({ payload }) {
  const url = `/pharmacy/GetExpiryDate?batch_id=${payload}`;
  try {
    const response = yield call(GET, url);
    if (response && response.is_error) {
      yield put(expiryDateError(response.message));
    } else {
      yield put(expiryDateSuccess(response));
    }
  } catch (error) {
    yield put(expiryDateError(error));
  }
}

export function* watchManageBill() {
  yield takeLatest(MANAGE_BILL_REQUEST, manageBill);
}

function* manageBill({ payload }) {
  const url = `/pharmacy/bill`;
  try {
    const response = yield call(FORMDATA_POST, url, payload);
    if (response && response.is_error) {
      yield put(manageBillError(response.message));
    } else {
      yield put(manageBillSuccess(response));
    }
  } catch (error) {
    yield put(manageBillError(error));
  }
}

export function* watchBillList() {
  yield takeLatest(BILL_BASIC_LIST_REQUEST, billList);
}

function* billList({ payload }) {
  console.log(payload);
  try {
    let response;
    if (payload.request_type === "bills") {
      const url = `/pharmacy/bill`;
      response = yield call(LIST, url, payload);
    }
    if (payload.request_type === "next_bills") {
      const url = `/pharmacy/next_bill`;
      response = yield call(LIST, url, payload);
    }
    if (response && response.is_error) {
      yield put(billBasicListError(response.message));
    } else {
      yield put(billBasicListSuccess(response));
    }
  } catch (error) {
    yield put(billBasicListError(error));
  }
}

export function* watchBill() {
  yield takeLatest(BILL_REQUEST, bill);
}

function* bill({ payload }) {
  const url = `/pharmacy/billinfo?${objectToQueryString(payload)}`;
  try {
    const response = yield call(GET, url);
    if (response && response.is_error) {
      yield put(billError(response.message));
    } else {
      yield put(billSuccess(response));
    }
  } catch (error) {
    yield put(billError(error));
  }
}

export function* watchPurchaseList() {
  yield takeLatest(PURCHASE_BASIC_LIST_REQUEST, purchaseList);
}

function* purchaseList({ payload }) {
  const url = `/pharmacy/purchase`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(purchaseBasicListError(response.message));
    } else {
      yield put(purchaseBasicListSuccess(response));
    }
  } catch (error) {
    yield put(purchaseBasicListError(error));
  }
}

export function* watchPurchase() {
  yield takeLatest(PURCHASE_REQUEST, purchase);
}

function* purchase({ payload }) {
  const url = `/pharmacy/purchase/${payload}`;
  try {
    const response = yield call(GET, url);
    if (response && response.is_error) {
      yield put(purchaseError(response.message));
    } else {
      yield put(purchaseSuccess(response));
    }
  } catch (error) {
    yield put(purchaseError(error));
  }
}

export function* watchMedicine() {
  yield takeLatest(MEDICINE_REQUEST, medicine);
}

function* medicine({ payload }) {
  const url = `/pharmacy/medicine/${payload}`;
  try {
    const response = yield call(GET, url);
    if (response && response.is_error) {
      yield put(medicineError(response.message));
    } else {
      yield put(medicineSuccess(response));
    }
  } catch (error) {
    yield put(medicineError(error));
  }
}

export function* watchSupplerTracking() {
  yield takeLatest(SUPPLIER_TRACKING_REQUEST, supplierTracking);
}

function* supplierTracking({ payload }) {
  const url = `/pharmacy/SupplierTracking/${payload}`;
  try {
    const response = yield call(GET, url);
    if (response && response.is_error) {
      yield put(supplierTrackingError(response.message));
    } else {
      yield put(supplierTrackingSuccess(response));
    }
  } catch (error) {
    yield put(supplierTrackingError(error));
  }
}

export function* watchManagePharmacyReturn() {
  yield takeLatest(MANAGE_PHARMACY_RETURN_REQUEST, managePharmacyReturn);
}

function* managePharmacyReturn({ payload }) {
  const url = `/pharmacy/ReturnBill`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(managePharmacyReturnError(response.message));
    } else {
      yield put(managePharmacyReturnSuccess(response));
    }
  } catch (error) {
    yield put(managePharmacyReturnError(error));
  }
}

export function* watcgManageReportOverView() {
  yield takeLatest(MANAGE_REPORT_OVERVIEW_REQUEST, manageReportOverview);
}
function* manageReportOverview({ payload, request_type }) {
  let url, response;
  try {
    if (request_type == "OVERVIEW") {
      url = `/frontdesk/overview?${objectToQueryString(payload)}`;
      response = yield call(GET, url, payload);
    } else if (request_type == "REPORT_LIST") {
      url = `/frontdesk/guestreport?${objectToQueryString(payload)}`;
      response = yield call(GET, url, payload);
    } else {
      manageReportOverViewError("Invalid URL Request");
    }
    if (response && response.is_error) {
      yield put(manageReportOverViewError(response.message));
    } else {
      yield put(manageReportOverViewSuccess(response, request_type));
    }
  } catch (error) {
    yield put(manageReportOverViewError(error));
  }
}

export function* watchManageCashSummary() {
  yield takeLatest(MANAGE_CASHSUMMARY_REQUEST, manageCashSummary);
}
function* manageCashSummary({ payload, request_type }) {
  let url, response;

  try {
    if (request_type == "ADD") {
      url = `/frontdesk/cashsummary`;
      response = yield call(POST, url, payload);
    } else if (request_type == "EDIT") {
      url = `/frontdesk/cashsummary`;
      response = yield call(PATCH, url, payload);
    } else if (request_type == "GET") {
      url = `/frontdesk/cashsummary/${payload.summary_id}?${objectToQueryString(
        payload
      )}`;
      response = yield call(GET, url, payload);
    } else if (request_type == "LIST") {
      url = `/frontdesk/cashsummary?${objectToQueryString(payload)}`;
      response = yield call(GET, url, payload);
    } else {
      yield put(manageCashSummaryError("Invalid URL Request"));
    }
    if (response && response.is_error) {
      yield put(manageCashSummaryError(response.message));
    } else {
      yield put(manageCashSummarySuccess(response, request_type));
    }
  } catch (error) {
    yield put(manageCashSummaryError(error));
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchManageMedicine),
    fork(watchDeleteMedicine),
    fork(watchMedicineList),
    fork(watchManagePurchase),
    fork(watchAutoMedicines),
    fork(watchBatchNoList),
    fork(watchExpiryDate),
    fork(watchManageBill),
    fork(watchBillList),
    fork(watchBill),
    fork(watchPurchaseList),
    fork(watchPurchase),
    fork(watchMedicine),
    fork(watchSupplerTracking),
    fork(watchManagePharmacyReturn),
    fork(watcgManageReportOverView),
    fork(watchManageCashSummary),
  ]);
}
