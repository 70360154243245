import {
  VALIDATE_LOGIN_REQUEST,
  VALIDATE_LOGIN_SUCCESS,
  VALIDATE_LOGIN_ERROR,
  SUPPLIER_LOGIN_REQUEST,
  SUPPLIER_LOGIN_SUCCESS,
  SUPPLIER_LOGIN_ERROR,
  USER_INFO_REQUEST,
  USER_INFO_SUCCESS,
  USER_INFO_ERROR,
  CLEAR_USER_DEFAULTS,
  CLEAR_ALL_DEFAULTS,
  MENU_REQUEST,
  MENU_SUCCESS,
  MENU_ERROR,
  UNIQUE_KEY_PERMISSION_REQUEST,
  UNIQUE_KEY_PERMISSION_SUCCESS,
  UNIQUE_KEY_PERMISSION_ERROR,
  GET_PERMISSION_LIST_REQUEST,
  GET_PERMISSION_LIST_SUCCESS,
  GET_PERMISSION_LIST_ERROR,
  UPDATE_PERMISSIONS_REQUEST,
  UPDATE_PERMISSIONS_SUCCESS,
  UPDATE_PERMISSIONS_ERROR,
  CENTER_INFO_REQUEST,
  CENTER_INFO_SUCCESS,
  CENTER_INFO_ERROR,
} from "../actions";
export const clearStatusDefault = (message) => ({
  type: CLEAR_ALL_DEFAULTS,
  payload: message,
});
export const clearUserDefaults = (message) => ({
  type: CLEAR_USER_DEFAULTS,
  payload: message,
});
export const validateLoginRequest = (request, history) => ({
  type: VALIDATE_LOGIN_REQUEST,
  payload: { request, history },
});
export const validateLoginSuccess = (response) => ({
  type: VALIDATE_LOGIN_SUCCESS,
  payload: response,
});
export const validateLoginError = (message) => ({
  type: VALIDATE_LOGIN_ERROR,
  payload: message,
});

export const supplierLoginRequest = (request, history) => ({
  type: SUPPLIER_LOGIN_REQUEST,
  payload: { request, history },
});
export const supplierLoginSuccess = (response) => ({
  type: SUPPLIER_LOGIN_SUCCESS,
  payload: response,
});
export const supplierLoginError = (message) => ({
  type: SUPPLIER_LOGIN_ERROR,
  payload: message,
});

export const userInfoRequest = (request) => ({
  type: USER_INFO_REQUEST,
  payload: request,
});
export const userInfoSuccess = (response) => ({
  type: USER_INFO_SUCCESS,
  payload: response,
});
export const userInfoError = (message) => ({
  type: USER_INFO_ERROR,
  payload: message,
});

export const menuRequest = (request) => ({
  type: MENU_REQUEST,
  payload: request,
});
export const menuSuccess = (response) => ({
  type: MENU_SUCCESS,
  payload: response,
});
export const menuError = (message) => ({
  type: MENU_ERROR,
  payload: message,
});

export const uniqueKeyPermissionRequest = (request) => ({
  type: UNIQUE_KEY_PERMISSION_REQUEST,
  payload: request,
});
export const uniqueKeyPermissionSuccess = (response) => ({
  type: UNIQUE_KEY_PERMISSION_SUCCESS,
  payload: response,
});
export const uniqueKeyPermissionError = (message) => ({
  type: UNIQUE_KEY_PERMISSION_ERROR,
  payload: message,
});

export const permissionListRequest = (request) => ({
  type: GET_PERMISSION_LIST_REQUEST,
  payload: request,
});
export const permissionListSuccess = (response) => ({
  type: GET_PERMISSION_LIST_SUCCESS,
  payload: response,
});
export const permissionListError = (message) => ({
  type: GET_PERMISSION_LIST_ERROR,
  payload: message,
});

export const updatePermissionsRequest = (request) => ({
  type: UPDATE_PERMISSIONS_REQUEST,
  payload: request,
});
export const updatePermissionsSuccess = (response) => ({
  type: UPDATE_PERMISSIONS_SUCCESS,
  payload: response,
});
export const updatePermissionsError = (message) => ({
  type: UPDATE_PERMISSIONS_ERROR,
  payload: message,
});

export const centerInfoRequest = (request) => ({
  type: CENTER_INFO_REQUEST,
  payload: request,
});
export const centerInfoSuccess = (response) => ({
  type: CENTER_INFO_SUCCESS,
  payload: response,
});
export const centerInfoError = (message) => ({
  type: CENTER_INFO_ERROR,
  payload: message,
});
