import {
  CLEAR_DOCTOR_DEFAULTS,
  DOCTORSLOT_LIST_ERROR,
  DOCTORSLOT_LIST_REQUEST,
  DOCTORSLOT_LIST_SUCCESS,
  MANAGE_DOCTORSLOT_ERROR,
  MANAGE_DOCTORSLOT_REQUEST,
  MANAGE_DOCTORSLOT_SUCCESS,
} from "../actions";

export const clearDoctorDefaults = (message) => ({
  type: CLEAR_DOCTOR_DEFAULTS,
  payload: message,
});
export const manageDoctorSlotRequest = (request) => ({
  type: MANAGE_DOCTORSLOT_REQUEST,
  payload: request,
});
export const manageDoctorSlotSuccess = (response) => ({
  type: MANAGE_DOCTORSLOT_SUCCESS,
  payload: response,
});
export const manageDoctorSlotError = (message) => ({
  type: MANAGE_DOCTORSLOT_ERROR,
  payload: message,
});

export const doctorSlotListRequest = (request) => ({
  type: DOCTORSLOT_LIST_REQUEST,
  payload: request,
});
export const doctorSlotListSuccess = (response) => ({
  type: DOCTORSLOT_LIST_SUCCESS,
  payload: response,
});
export const doctorSlotListError = (message) => ({
  type: DOCTORSLOT_LIST_ERROR,
  payload: message,
});
