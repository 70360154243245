import {
  AUTO_PATIENT_SUGGEST_ERROR,
  AUTO_PATIENT_SUGGEST_REQUEST,
  AUTO_PATIENT_SUGGEST_SUCCESS,
  CLEAR_PATIENT_DEFAULTS,
  MANAGE_PATIENT_ERROR,
  MANAGE_PATIENT_REQUEST,
  MANAGE_PATIENT_SUCCESS,
} from "../actions";

const INIT_STATE = {
  patient_loading: false,
  patient_success: null,
  patient_error: null,
  patients: [],
  patientObj: null,
  auto_patients: [],
  patient_action: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_PATIENT_DEFAULTS:
      return {
        ...state,
        patient_loading: false,
        patient_error: null,
        patient_success: null,
        patient_action: null,
      };
    case MANAGE_PATIENT_REQUEST:
      return {
        ...state,
        patient_loading: true,
        patient_error: null,
        patient_success: null,
        patientObj: null,
        patient_action: action.type,
      };
    case MANAGE_PATIENT_SUCCESS:
      return {
        ...state,
        patient_loading: false,
        patient_success: action.payload.message,
        patientObj: action.payload,
      };

    case MANAGE_PATIENT_ERROR:
      return {
        ...state,
        patient_loading: false,
        patient_error: action.payload,
      };

    case AUTO_PATIENT_SUGGEST_REQUEST:
      return {
        ...state,
        patient_action: action.type,
        patient_loading: true,
        patient_error: null,
        patient_success: null,
        auto_patients: [],
      };
    case AUTO_PATIENT_SUGGEST_SUCCESS:
      return {
        ...state,
        patient_loading: false,
        auto_patients: action.payload,
      };

    case AUTO_PATIENT_SUGGEST_ERROR:
      return {
        ...state,
        patient_loading: false,
        patient_error: action.payload,
      };

    default:
      return { ...state };
  }
};
