import {
  AUTO_MEDICINE_SUGGEST_ERROR,
  AUTO_MEDICINE_SUGGEST_REQUEST,
  AUTO_MEDICINE_SUGGEST_SUCCESS,
  AUTO_SUGGEST_ERROR,
  AUTO_SUGGEST_REQUEST,
  AUTO_SUGGEST_SUCCESS,
  BATCHNO_LIST_ERROR,
  BATCHNO_LIST_REQUEST,
  BATCHNO_LIST_SUCCESS,
  CLEAR_BATCHNO_LIST,
  BILL_BASIC_LIST_ERROR,
  BILL_BASIC_LIST_REQUEST,
  BILL_BASIC_LIST_SUCCESS,
  BILL_DETAILS_LIST_ERROR,
  BILL_DETAILS_LIST_REQUEST,
  BILL_DETAILS_LIST_SUCCESS,
  BILL_ERROR,
  BILL_REQUEST,
  BILL_SUCCESS,
  CLEAR_PHARMACY_DEFAULTS,
  DELETE_MEDICINE_ERROR,
  DELETE_MEDICINE_REQUEST,
  DELETE_MEDICINE_SUCCESS,
  EXPIRY_DATE_ERROR,
  EXPIRY_DATE_REQUEST,
  EXPIRY_DATE_SUCCESS,
  MANAGE_BILL_ERROR,
  MANAGE_BILL_REQUEST,
  MANAGE_BILL_SUCCESS,
  MANAGE_CASHSUMMARY_ERROR,
  MANAGE_CASHSUMMARY_REQUEST,
  MANAGE_CASHSUMMARY_SUCCESS,
  MANAGE_MEDICINE_ERROR,
  MANAGE_MEDICINE_REQUEST,
  MANAGE_MEDICINE_SUCCESS,
  MANAGE_ORDER_STATUS_ERORR,
  MANAGE_ORDER_STATUS_REQUEST,
  MANAGE_ORDER_STATUS_SUCCESS,
  MANAGE_PHARMACY_RETURN_ERROR,
  MANAGE_PHARMACY_RETURN_REQUEST,
  MANAGE_PHARMACY_RETURN_SUCCESS,
  MANAGE_PURCHASE_ERROR,
  MANAGE_PURCHASE_REQUEST,
  MANAGE_PURCHASE_SUCCESS,
  MANAGE_REPORT_OVERVIEW_ERROR,
  MANAGE_REPORT_OVERVIEW_REQUEST,
  MANAGE_REPORT_OVERVIEW_SUCCESS,
  MEDICINE_ERROR,
  MEDICINE_LIST_ERROR,
  MEDICINE_LIST_REQUEST,
  MEDICINE_LIST_SUCCESS,
  MEDICINE_REQUEST,
  MEDICINE_SUCCESS,
  PURCHASE_BASIC_LIST_ERROR,
  PURCHASE_BASIC_LIST_REQUEST,
  PURCHASE_BASIC_LIST_SUCCESS,
  PURCHASE_DETAILS_LIST_ERROR,
  PURCHASE_DETAILS_LIST_REQUEST,
  PURCHASE_DETAILS_LIST_SUCCESS,
  PURCHASE_ERROR,
  PURCHASE_REQUEST,
  PURCHASE_SUCCESS,
  SUPPLIER_TRACKING_ERROR,
  SUPPLIER_TRACKING_REQUEST,
  SUPPLIER_TRACKING_SUCCESS,
} from "../actions";
export const clearPharmacyDefaults = (message) => ({
  type: CLEAR_PHARMACY_DEFAULTS,
  payload: message,
});
export const manageMedicineRequest = (request) => ({
  type: MANAGE_MEDICINE_REQUEST,
  payload: request,
});
export const manageMedicineSuccess = (response) => ({
  type: MANAGE_MEDICINE_SUCCESS,
  payload: response,
});
export const manageMedicineError = (message) => ({
  type: MANAGE_MEDICINE_ERROR,
  payload: message,
});

export const deleteMedicineRequest = (request) => ({
  type: DELETE_MEDICINE_REQUEST,
  payload: request,
});
export const deleteMedicineSuccess = (response) => ({
  type: DELETE_MEDICINE_SUCCESS,
  payload: response,
});
export const deleteMedicineError = (message) => ({
  type: DELETE_MEDICINE_ERROR,
  payload: message,
});

export const medicineListRequest = (request) => ({
  type: MEDICINE_LIST_REQUEST,
  payload: request,
});
export const medicineListSuccess = (response) => ({
  type: MEDICINE_LIST_SUCCESS,
  payload: response,
});
export const medicineListError = (message) => ({
  type: MEDICINE_LIST_ERROR,
  payload: message,
});

export const managePurchaseRequest = (request) => ({
  type: MANAGE_PURCHASE_REQUEST,
  payload: request,
});
export const managePurchaseSuccess = (response) => ({
  type: MANAGE_PURCHASE_SUCCESS,
  payload: response,
});
export const managePurchaseError = (message) => ({
  type: MANAGE_PURCHASE_ERROR,
  payload: message,
});

export const autoMedicineSuggestRequest = (request) => ({
  type: AUTO_MEDICINE_SUGGEST_REQUEST,
  payload: request,
});
export const autoMedicineSuggestSuccess = (response) => ({
  type: AUTO_MEDICINE_SUGGEST_SUCCESS,
  payload: response,
});
export const autoMedicineSuggestError = (message) => ({
  type: AUTO_MEDICINE_SUGGEST_ERROR,
  payload: message,
});

export const batchNoListRequest = (request) => ({
  type: BATCHNO_LIST_REQUEST,
  payload: request,
});
export const batchNoListSuccess = (response) => ({
  type: BATCHNO_LIST_SUCCESS,
  payload: response,
});
export const clearBatchNoList = (response) => ({
  type: CLEAR_BATCHNO_LIST,
  payload: response,
});
export const batchNoListError = (message) => ({
  type: BATCHNO_LIST_ERROR,
  payload: message,
});

export const expiryDateRequest = (request) => ({
  type: EXPIRY_DATE_REQUEST,
  payload: request,
});
export const expiryDateSuccess = (response) => ({
  type: EXPIRY_DATE_SUCCESS,
  payload: response,
});
export const expiryDateError = (message) => ({
  type: EXPIRY_DATE_ERROR,
  payload: message,
});

export const manageBillRequest = (request) => ({
  type: MANAGE_BILL_REQUEST,
  payload: request,
});
export const manageBillSuccess = (response) => ({
  type: MANAGE_BILL_SUCCESS,
  payload: response,
});
export const manageBillError = (message) => ({
  type: MANAGE_BILL_ERROR,
  payload: message,
});

export const billBasicListRequest = (request) => ({
  type: BILL_BASIC_LIST_REQUEST,
  payload: request,
});
export const billBasicListSuccess = (response) => ({
  type: BILL_BASIC_LIST_SUCCESS,
  payload: response,
});
export const billBasicListError = (message) => ({
  type: BILL_BASIC_LIST_ERROR,
  payload: message,
});

export const billRequest = (request) => ({
  type: BILL_REQUEST,
  payload: request,
});
export const billSuccess = (response) => ({
  type: BILL_SUCCESS,
  payload: response,
});
export const billError = (message) => ({
  type: BILL_ERROR,
  payload: message,
});

export const purchaseBasicListRequest = (request) => ({
  type: PURCHASE_BASIC_LIST_REQUEST,
  payload: request,
});
export const purchaseBasicListSuccess = (response) => ({
  type: PURCHASE_BASIC_LIST_SUCCESS,
  payload: response,
});
export const purchaseBasicListError = (message) => ({
  type: PURCHASE_BASIC_LIST_ERROR,
  payload: message,
});

export const purchaseRequest = (request) => ({
  type: PURCHASE_REQUEST,
  payload: request,
});
export const purchaseSuccess = (response) => ({
  type: PURCHASE_SUCCESS,
  payload: response,
});
export const purchaseError = (message) => ({
  type: PURCHASE_ERROR,
  payload: message,
});

export const medicineRequest = (request) => ({
  type: MEDICINE_REQUEST,
  payload: request,
});
export const medicineSuccess = (response) => ({
  type: MEDICINE_SUCCESS,
  payload: response,
});
export const medicineError = (message) => ({
  type: MEDICINE_ERROR,
  payload: message,
});

export const supplierTrackingRequest = (request) => ({
  type: SUPPLIER_TRACKING_REQUEST,
  payload: request,
});
export const supplierTrackingSuccess = (response) => ({
  type: SUPPLIER_TRACKING_SUCCESS,
  payload: response,
});
export const supplierTrackingError = (message) => ({
  type: SUPPLIER_TRACKING_ERROR,
  payload: message,
});

export const managePharmacyReturnRequest = (request) => ({
  type: MANAGE_PHARMACY_RETURN_REQUEST,
  payload: request,
});
export const managePharmacyReturnSuccess = (response) => ({
  type: MANAGE_PHARMACY_RETURN_SUCCESS,
  payload: response,
});
export const managePharmacyReturnError = (message) => ({
  type: MANAGE_PHARMACY_RETURN_ERROR,
  payload: message,
});

export const manageReportOverViewRequest = (request) => ({
  type: MANAGE_REPORT_OVERVIEW_REQUEST,
  payload: request,
  request_type: request.request_type,
});
export const manageReportOverViewSuccess = (response, request_type) => ({
  type: MANAGE_REPORT_OVERVIEW_SUCCESS,
  payload: response,
  request_type: request_type,
});
export const manageReportOverViewError = (message) => ({
  type: MANAGE_REPORT_OVERVIEW_ERROR,
  payload: message,
});

export const manageCashSummaryRequest = (request) => ({
  type: MANAGE_CASHSUMMARY_REQUEST,
  payload: request,
  request_type: request.request_type,
});
export const manageCashSummarySuccess = (response, request_type) => ({
  type: MANAGE_CASHSUMMARY_SUCCESS,
  payload: response,
  request_type: request_type,
});
export const manageCashSummaryError = (message) => ({
  type: MANAGE_CASHSUMMARY_ERROR,
  payload: message,
});
