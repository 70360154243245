import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { objectToQueryString } from "../../helpers/Utils";
import {
  STOCK_INVOICE_LIST_REQUEST,
  MANAGE_STOCK_INVOICE_REQUEST,
  STOCK_INVOICE_REQUEST,
  INVOICE_SUPPLIER_LIST_REQUEST,
  STOCK_INVOICE_OVERVIEW_REQUEST,
  MANAGE_STOCKINVOICE_RETURN_REQUEST,
  MANAGE_STOCKINVOICE_PAYMENT_REQUEST,
  V2_MANAGE_STOCK_INVOICE_REQUEST,
} from "../actions";
import { GET, LIST, PATCH, POST } from "../api";
import {
  invoiceSupplierListError,
  invoiceSupplierListSuccess,
  manageStockInvoiceError,
  manageStockInvoicePaymentError,
  manageStockInvoicePaymentSuccess,
  manageStockInvoiceSuccess,
  manageStockInvoicReturnError,
  manageStockInvoicReturnSuccess,
  stockInvoiceError,
  stockInvoiceListError,
  stockInvoiceListSuccess,
  stockInvoiceOverViewError,
  stockInvoiceOverViewSuccess,
  stockInvoiceSuccess,
  v2manageStockInvoiceError,
  v2manageStockInvoiceSuccess,
} from "./action";

export function* watchManageStockInvoice() {
  yield takeLatest(MANAGE_STOCK_INVOICE_REQUEST, manageStockInvoice);
}

function* manageStockInvoice({ payload }) {
  const url = "/invoice/StockInvoice";
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageStockInvoiceError(response.message));
    } else {
      yield put(manageStockInvoiceSuccess(response));
    }
  } catch (error) {
    yield put(manageStockInvoiceError(error));
  }
}

export function* watchStockInvoiceList() {
  yield takeLatest(STOCK_INVOICE_LIST_REQUEST, stockInvoiceList);
}

function* stockInvoiceList({ payload }) {
  const url = "/invoice/StockInvoice";
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(stockInvoiceListError(response.message));
    } else {
      yield put(stockInvoiceListSuccess(response));
    }
  } catch (error) {
    yield put(stockInvoiceListError(error));
  }
}

export function* watchStockInvoiceInfo() {
  yield takeLatest(STOCK_INVOICE_REQUEST, stockInvoiceInfo);
}

function* stockInvoiceInfo({ payload }) {
  const url = `/invoice/StockInvoiceInfo?${objectToQueryString(payload)}`;
  try {
    const response = yield call(GET, url, payload);
    if (response && response.is_error) {
      yield put(stockInvoiceError(response.message));
    } else {
      yield put(stockInvoiceSuccess(response));
    }
  } catch (error) {
    yield put(stockInvoiceError(error));
  }
}

export function* warchInvoiceSupplierList() {
  yield takeLatest(INVOICE_SUPPLIER_LIST_REQUEST, invoiceSuppllierList);
}

function* invoiceSuppllierList({ payload }) {
  const url = `/invoice/Supplier?${objectToQueryString(payload)}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(invoiceSupplierListError(response.message));
    } else {
      yield put(invoiceSupplierListSuccess(response));
    }
  } catch (error) {
    yield put(invoiceSupplierListError(error));
  }
}

export function* watchStockInvoiceOverview() {
  yield takeLatest(STOCK_INVOICE_OVERVIEW_REQUEST, stockInvoiceOverview);
}

function* stockInvoiceOverview({ payload }) {
  const url = `/invoice/stockinvoiceoverview?${objectToQueryString(payload)}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(stockInvoiceOverViewError(response.message));
    } else {
      yield put(stockInvoiceOverViewSuccess(response));
    }
  } catch (error) {
    yield put(stockInvoiceOverViewError(error));
  }
}

export function* watchmanageStockInvoicReturn() {
  yield takeLatest(MANAGE_STOCKINVOICE_RETURN_REQUEST, manageStockInvoicReturn);
}

function* manageStockInvoicReturn({ payload }) {
  const url = "/invoice/ReturnStockInvoice";
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageStockInvoicReturnError(response.message));
    } else {
      yield put(manageStockInvoicReturnSuccess(response));
    }
  } catch (error) {
    yield put(manageStockInvoicReturnError(error));
  }
}

export function* watchManageStockInvoicePayment() {
  yield takeLatest(
    MANAGE_STOCKINVOICE_PAYMENT_REQUEST,
    manageStockInvoicPayment
  );
}

function* manageStockInvoicPayment({ payload }) {
  const url = "/invoice/StockInvoicePayment";
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageStockInvoicePaymentError(response.message));
    } else {
      yield put(manageStockInvoicePaymentSuccess(response));
    }
  } catch (error) {
    yield put(manageStockInvoicePaymentError(error));
  }
}

export function* watchV2ManageStockInvoice() {
  yield takeLatest(V2_MANAGE_STOCK_INVOICE_REQUEST, manageV2StockInvoice);
}

function* manageV2StockInvoice({ payload, request_type }) {
  let url;
  let response;

  try {
    if (request_type === "CHECK_INVOICE") {
      url = `/invoice/checkinvoice?${objectToQueryString(payload)}`;
      response = yield call(GET, url, payload);
    } else {
      yield put(v2manageStockInvoiceError(response.message));
    }
    if (response && response.is_error) {
      yield put(v2manageStockInvoiceError(response.message));
    } else {
      yield put(v2manageStockInvoiceSuccess(response, request_type));
    }
  } catch (error) {
    yield put(v2manageStockInvoiceError(error));
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchManageStockInvoice),
    fork(watchStockInvoiceList),
    fork(watchStockInvoiceInfo),
    fork(warchInvoiceSupplierList),
    fork(watchStockInvoiceOverview),
    fork(watchmanageStockInvoicReturn),
    fork(watchManageStockInvoicePayment),
    fork(watchV2ManageStockInvoice),
  ]);
}
