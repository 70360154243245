import {
  STOCK_INVOICE_LIST_ERROR,
  STOCK_INVOICE_LIST_REQUEST,
  STOCK_INVOICE_LIST_SUCCESS,
  MANAGE_STOCK_INVOICE_ERROR,
  MANAGE_STOCK_INVOICE_REQUEST,
  MANAGE_STOCK_INVOICE_SUCCESS,
  STOCK_INVOICE_SUCCESS,
  STOCK_INVOICE_REQUEST,
  STOCK_INVOICE_ERROR,
  INVOICE_SUPPLIER_LIST_REQUEST,
  INVOICE_SUPPLIER_LIST_SUCCESS,
  INVOICE_SUPPLIER_LIST_ERROR,
  STOCK_INVOICE_OVERVIEW_REQUEST,
  STOCK_INVOICE_OVERVIEW_SUCCESS,
  STOCK_INVOICE_OVERVIEW_ERROR,
  MANAGE_STOCKINVOICE_RETURN_REQUEST,
  MANAGE_STOCKINVOICE_RETURN_ERROR,
  MANAGE_STOCKINVOICE_RETURN_SUCCESS,
  MANAGE_STOCKINVOICE_PAYMENT_REQUEST,
  MANAGE_STOCKINVOICE_PAYMENT_SUCCESS,
  MANAGE_STOCKINVOICE_PAYMENT_ERROR,
  V2_MANAGE_STOCK_INVOICE_REQUEST,
  V2_MANAGE_STOCK_INVOICE_SUCCESS,
  V2_MANAGE_STOCK_INVOICE_ERROR,
} from "../actions";
export const manageStockInvoiceRequest = (request) => ({
  type: MANAGE_STOCK_INVOICE_REQUEST,
  payload: request,
});
export const manageStockInvoiceSuccess = (response) => ({
  type: MANAGE_STOCK_INVOICE_SUCCESS,
  payload: response,
});
export const manageStockInvoiceError = (message) => ({
  type: MANAGE_STOCK_INVOICE_ERROR,
  payload: message,
});

export const stockInvoiceListRequest = (request) => ({
  type: STOCK_INVOICE_LIST_REQUEST,
  payload: request,
});
export const stockInvoiceListSuccess = (response) => ({
  type: STOCK_INVOICE_LIST_SUCCESS,
  payload: response,
});
export const stockInvoiceListError = (message) => ({
  type: STOCK_INVOICE_LIST_ERROR,
  payload: message,
});

export const stockInvoiceRequest = (request) => ({
  type: STOCK_INVOICE_REQUEST,
  payload: request,
});
export const stockInvoiceSuccess = (response) => ({
  type: STOCK_INVOICE_SUCCESS,
  payload: response,
});
export const stockInvoiceError = (message) => ({
  type: STOCK_INVOICE_ERROR,
  payload: message,
});

export const invoiceSupplierListRequest = (request) => ({
  type: INVOICE_SUPPLIER_LIST_REQUEST,
  payload: request,
});
export const invoiceSupplierListSuccess = (response) => ({
  type: INVOICE_SUPPLIER_LIST_SUCCESS,
  payload: response,
});
export const invoiceSupplierListError = (message) => ({
  type: INVOICE_SUPPLIER_LIST_ERROR,
  payload: message,
});

export const stockInvoiceOverViewRequest = (request) => ({
  type: STOCK_INVOICE_OVERVIEW_REQUEST,
  payload: request,
});
export const stockInvoiceOverViewSuccess = (response) => ({
  type: STOCK_INVOICE_OVERVIEW_SUCCESS,
  payload: response,
});
export const stockInvoiceOverViewError = (message) => ({
  type: STOCK_INVOICE_OVERVIEW_ERROR,
  payload: message,
});

export const manageStockInvoicReturnRequest = (request) => ({
  type: MANAGE_STOCKINVOICE_RETURN_REQUEST,
  payload: request,
});
export const manageStockInvoicReturnSuccess = (response) => ({
  type: MANAGE_STOCKINVOICE_RETURN_SUCCESS,
  payload: response,
});
export const manageStockInvoicReturnError = (message) => ({
  type: MANAGE_STOCKINVOICE_RETURN_ERROR,
  payload: message,
});

export const manageStockInvoicePaymentRequest = (request) => ({
  type: MANAGE_STOCKINVOICE_PAYMENT_REQUEST,
  payload: request,
});
export const manageStockInvoicePaymentSuccess = (response) => ({
  type: MANAGE_STOCKINVOICE_PAYMENT_SUCCESS,
  payload: response,
});
export const manageStockInvoicePaymentError = (message) => ({
  type: MANAGE_STOCKINVOICE_PAYMENT_ERROR,
  payload: message,
});

export const v2manageStockInvoiceRequest = (request) => ({
  type: V2_MANAGE_STOCK_INVOICE_REQUEST,
  payload: request,
  request_type: request.request_type,
});
export const v2manageStockInvoiceSuccess = (response, request_type) => ({
  type: V2_MANAGE_STOCK_INVOICE_SUCCESS,
  payload: response,
  request_type: request_type,
});
export const v2manageStockInvoiceError = (message) => ({
  type: V2_MANAGE_STOCK_INVOICE_ERROR,
  payload: message,
});
