import {
  GUEST_INFO_ERROR,
  GUEST_INFO_REQUEST,
  GUEST_INFO_SUCCESS,
  GUEST_LIST_ERROR,
  GUEST_LIST_REQUEST,
  GUEST_LIST_SUCCESS,
  MANAGE_GUEST_ERROR,
  MANAGE_GUEST_REQUEST,
  MANAGE_GUEST_SUCCESS,
} from "../actions";

const INIT_STATE = {
  guest_loading: false,
  guest_success: null,
  guest_error: null,
  guest_action: null,
  guestObj: null,
  guest_list: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MANAGE_GUEST_REQUEST:
      return {
        ...state,
        guest_loading: true,
        guest_error: null,
        guest_success: null,
        guest_action: action.type,
      };
    case MANAGE_GUEST_SUCCESS:
      return {
        ...state,
        guest_loading: false,
        guest_success: action.payload.message,
        guestObj: action.payload,
      };

    case MANAGE_GUEST_ERROR:
      return {
        ...state,
        guest_loading: false,
        guest_error: action.payload,
      };

    case GUEST_INFO_REQUEST:
      return {
        ...state,
        guest_loading: true,
        guest_error: null,
        guest_success: null,
        guest_action: action.type,
      };
    case GUEST_INFO_SUCCESS:
      return {
        ...state,
        guest_loading: false,
        guestObj: action.payload,
      };

    case GUEST_INFO_ERROR:
      return {
        ...state,
        guest_loading: false,
        guest_error: action.payload,
      };

    case GUEST_LIST_REQUEST:
      return {
        ...state,
        guest_loading: true,
        guest_error: null,
        guest_success: null,
        guest_action: action.type,
      };
    case GUEST_LIST_SUCCESS:
      return {
        ...state,
        guest_loading: false,
        guest_list: action.payload,
      };

    case GUEST_LIST_ERROR:
      return {
        ...state,
        guest_loading: false,
        guest_error: action.payload,
      };
    default:
      return { ...state };
  }
};
