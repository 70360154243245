import {
  MANAGE_REPORT_ERROR,
  MANAGE_REPORT_REQUEST,
  MANAGE_REPORT_SUCCESS,
} from "../actions";

const INIT_STATE = {
  report_loading: false,
  report_success: null,
  report_error: null,
  report_action: null,
  report_request_type: null,
  custom_report: [],
  patient_wise_report: [],
  center_revenue: [],
  doctor_revenue: [],
  guest_revenue: [],
  channel_revenue: [],
  due_report: [],
  referal_doctor_comission: [],
  clinic_summary: [],
  pharmacy_summary: [],
  revenue_report: [],
  doctor_incentive: [],
  stock_report: [],
  pharmacy_due_report: [],
  purchase_gst: [],
  sales_gst: [],
  sales_report: [],
  margin_report: [],
  purchase_report: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MANAGE_REPORT_REQUEST:
      return {
        ...state,
        report_loading: true,
        report_error: null,
        report_success: null,
        report_action: action.type,
        report_request_type: action.request_type,
      };
    case MANAGE_REPORT_SUCCESS:
      if (action.request_type == "CUSTOM_REPORT") {
        return {
          ...state,
          report_loading: false,
          custom_report: action.payload,
        };
      } else if (action.request_type == "PATIENT_WISE_REPORT") {
        return {
          ...state,
          report_loading: false,
          patient_wise_report: action.payload,
        };
      } else if (action.request_type == "CENTER_REVENUE") {
        return {
          ...state,
          report_loading: false,
          center_revenue: action.payload,
        };
      } else if (action.request_type == "DOCTOR_REVENUE") {
        return {
          ...state,
          report_loading: false,
          doctor_revenue: action.payload,
        };
      } else if (action.request_type == "GUEST_REVENUE") {
        return {
          ...state,
          report_loading: false,
          guest_revenue: action.payload,
        };
      } else if (action.request_type == "CHANNEL_REVENUE") {
        return {
          ...state,
          report_loading: false,
          channel_revenue: action.payload,
        };
      } else if (action.request_type == "REVENUE_REPORT") {
        return {
          ...state,
          report_loading: false,
          revenue_report: action.payload,
        };
      } else if (action.request_type == "DUE_REPORT") {
        return {
          ...state,
          report_loading: false,
          due_report: action.payload,
        };
      } else if (action.request_type == "REFERAL_DOCTOR_COMISSION") {
        return {
          ...state,
          report_loading: false,
          referal_doctor_comission: action.payload,
        };
      } else if (action.request_type == "DOCTOR_INCENTIVE") {
        return {
          ...state,
          report_loading: false,
          doctor_incentive: action.payload,
        };
      } else if (action.request_type == "CLINIC_SUMMARY") {
        return {
          ...state,
          report_loading: false,
          clinic_summary: action.payload,
        };
      } else if (action.request_type == "PHARMACY_SUMMARY") {
        return {
          ...state,
          report_loading: false,
          pharmacy_summary: action.payload,
        };
      } else if (action.request_type == "PHARMACY_DUE_REPORT") {
        return {
          ...state,
          report_loading: false,
          pharmacy_due_report: action.payload,
        };
      } else if (action.request_type == "STOCK_REPORT") {
        return {
          ...state,
          report_loading: false,
          stock_report: action.payload,
        };
      } else if (action.request_type == "PURCHASE_GST") {
        return {
          ...state,
          report_loading: false,
          purchase_gst: action.payload,
        };
      } else if (action.request_type == "SALES_GST") {
        return {
          ...state,
          report_loading: false,
          sales_gst: action.payload,
        };
      } else if (action.request_type == "SALES_REPORT") {
        return {
          ...state,
          report_loading: false,
          sales_report: action.payload,
        };
      } else if (action.request_type == "MARGIN_REPORT") {
        return {
          ...state,
          report_loading: false,
          margin_report: action.payload,
        };
      } else if (action.request_type == "PURCHASE_REPORT") {
        return {
          ...state,
          report_loading: false,
          purchase_report: action.payload,
        };
      } else if (action.request_type == "BALANCE_REPORT") {
        return {
          ...state,
          report_loading: false,
          balance_report: action.payload,
        };
      }
    case MANAGE_REPORT_ERROR:
      return {
        ...state,
        report_loading: false,
        report_success: action.payload,
      };

    default:
      return { ...state };
  }
};
