import {
  MANAGE_SUPPLIER_INVOICE_ERROR,
  MANAGE_SUPPLIER_INVOICE_REQUEST,
  MANAGE_SUPPLIER_INVOICE_SUCCESS,
  MANAGE_SUPPLIER_PAYMENT_ERROR,
  MANAGE_SUPPLIER_PAYMENT_REQUEST,
  MANAGE_SUPPLIER_PAYMENT_SUCCESS,
  MANAGE_SUPPLIER_DELIVERY_ERROR,
  MANAGE_SUPPLIER_DELIVERY_REQUEST,
  MANAGE_SUPPLIER_DELIVERY_SUCCESS,
  MANAGE_SUPPLIER_ORDER_ERROR,
  MANAGE_SUPPLIER_ORDER_REQUEST,
  MANAGE_SUPPLIER_ORDER_SUCCESS,
  SUPPLIER_ORDER_ERROR,
  SUPPLIER_ORDER_LIST_ERROR,
  SUPPLIER_ORDER_LIST_REQUEST,
  SUPPLIER_ORDER_LIST_SUCCESS,
  SUPPLIER_ORDER_REQUEST,
  SUPPLIER_ORDER_SUCCESS,
  MANAGE_SUPPLIER_QUOTATION_REQUEST,
  MANAGE_SUPPLIER_QUOTATION_SUCCESS,
  MANAGE_SUPPLIER_QUOTATION_ERROR,
  SUPPLIER_ORDER_ITEMS_REQUEST,
  SUPPLIER_ORDER_ITEMS_SUCCESS,
  SUPPLIER_ORDER_ITEMS_ERROR,
  MANAGE_DELIVERY_HISTORY_REQUEST,
  MANAGE_DELIVERY_HISTORY_SUCCESS,
  MANAGE_DELIVERY_HISTORY_ERROR,
  MANAGE_ORDER_STATUS_REQUEST,
  MANAGE_ORDER_STATUS_SUCCESS,
  MANAGE_ORDER_STATUS_ERORR,
  MANAGE_QUOTATION_STATUS_REQUEST,
  MANAGE_QUOTATION_STATUS_ERROR,
  MANAGE_QUOTATION_STATUS_SUCCESS,
  MANAGE_INVOICE_STATUS_REQUEST,
  MANAGE_INVOICE_STATUS_SUCCESS,
  MANAGE_INVOICE_STATUS_ERROR,
  MANAGE_DELIVER_STATUS_REQUEST,
  MANAGE_DELIVER_STATUS_SUCCESS,
  MANAGE_DELIVER_STATUS_ERROR,
  CLEAR_SUPPLIER_DEFAULTS,
} from "../actions";
export const clearSupplierDefaults = (message) => ({
  type: CLEAR_SUPPLIER_DEFAULTS,
  payload: message,
});

export const manageSupplierPaymentRequest = (request) => ({
  type: MANAGE_SUPPLIER_PAYMENT_REQUEST,
  payload: request,
});
export const manageSupplierPaymentSuccess = (response) => ({
  type: MANAGE_SUPPLIER_PAYMENT_SUCCESS,
  payload: response,
});
export const manageSupplierPaymentError = (message) => ({
  type: MANAGE_SUPPLIER_PAYMENT_ERROR,
  payload: message,
});

export const manageSupplierOrderRequest = (request) => ({
  type: MANAGE_SUPPLIER_ORDER_REQUEST,
  payload: request,
});
export const manageSupplierOrderSuccess = (response) => ({
  type: MANAGE_SUPPLIER_ORDER_SUCCESS,
  payload: response,
});
export const manageSupplierOrderError = (message) => ({
  type: MANAGE_SUPPLIER_ORDER_ERROR,
  payload: message,
});

export const supplierOrderListRequest = (request) => ({
  type: SUPPLIER_ORDER_LIST_REQUEST,
  payload: request,
});
export const supplierOrderListSuccess = (response) => ({
  type: SUPPLIER_ORDER_LIST_SUCCESS,
  payload: response,
});
export const supplierOrderListError = (message) => ({
  type: SUPPLIER_ORDER_LIST_ERROR,
  payload: message,
});

export const supplierOrderRequest = (request) => ({
  type: SUPPLIER_ORDER_REQUEST,
  payload: request,
});
export const supplierOrderSuccess = (response) => ({
  type: SUPPLIER_ORDER_SUCCESS,
  payload: response,
});
export const supplierOrderErorr = (message) => ({
  type: SUPPLIER_ORDER_ERROR,
  payload: message,
});

export const manageSupplierDeliveryRequest = (request) => ({
  type: MANAGE_SUPPLIER_DELIVERY_REQUEST,
  payload: request,
});
export const manageSupplierDeliverySuccess = (response) => ({
  type: MANAGE_SUPPLIER_DELIVERY_SUCCESS,
  payload: response,
});
export const manageSupplierDeliveryError = (message) => ({
  type: MANAGE_SUPPLIER_DELIVERY_ERROR,
  payload: message,
});

export const manageSuppierInvoiceRequest = (request) => ({
  type: MANAGE_SUPPLIER_INVOICE_REQUEST,
  payload: request,
});
export const manageSuppierInvoiceSuccess = (response) => ({
  type: MANAGE_SUPPLIER_INVOICE_SUCCESS,
  payload: response,
});
export const manageSuppierInvoiceError = (message) => ({
  type: MANAGE_SUPPLIER_INVOICE_ERROR,
  payload: message,
});

export const manageSupplierQuotationRequest = (request) => ({
  type: MANAGE_SUPPLIER_QUOTATION_REQUEST,
  payload: request,
});
export const manageSupplierQuotationSuccess = (response) => ({
  type: MANAGE_SUPPLIER_QUOTATION_SUCCESS,
  payload: response,
});
export const manageSupplierQuotationError = (message) => ({
  type: MANAGE_SUPPLIER_QUOTATION_ERROR,
  payload: message,
});

export const supplierOrderItemsRequest = (request) => ({
  type: SUPPLIER_ORDER_ITEMS_REQUEST,
  payload: request,
});
export const supplierOrderItemsSuccess = (response) => ({
  type: SUPPLIER_ORDER_ITEMS_SUCCESS,
  payload: response,
});
export const supplierOrderItemsError = (message) => ({
  type: SUPPLIER_ORDER_ITEMS_ERROR,
  payload: message,
});

export const manageDeliveryHistoryRequest = (request) => ({
  type: MANAGE_DELIVERY_HISTORY_REQUEST,
  payload: request,
});
export const manageDeliveryHistorySuccess = (response) => ({
  type: MANAGE_DELIVERY_HISTORY_SUCCESS,
  payload: response,
});
export const manageDeliveryHistoryError = (message) => ({
  type: MANAGE_DELIVERY_HISTORY_ERROR,
  payload: message,
});

export const manageOrderStatusRequest = (request) => ({
  type: MANAGE_ORDER_STATUS_REQUEST,
  payload: request,
});
export const manageOrderStatusSuccess = (response) => ({
  type: MANAGE_ORDER_STATUS_SUCCESS,
  payload: response,
});
export const manageOrderStatusError = (message) => ({
  type: MANAGE_ORDER_STATUS_ERORR,
  payload: message,
});

export const manageQuotationStatusRequest = (request) => ({
  type: MANAGE_QUOTATION_STATUS_REQUEST,
  payload: request,
});
export const manageQuotationStatusSuccess = (response) => ({
  type: MANAGE_QUOTATION_STATUS_SUCCESS,
  payload: response,
});
export const manageQuotationStatusError = (message) => ({
  type: MANAGE_QUOTATION_STATUS_ERROR,
  payload: message,
});

export const manageInvoiceStatusRequest = (request) => ({
  type: MANAGE_INVOICE_STATUS_REQUEST,
  payload: request,
});
export const manageInvoiceStatusSuccess = (response) => ({
  type: MANAGE_INVOICE_STATUS_SUCCESS,
  payload: response,
});
export const manageInvoiceStatusError = (message) => ({
  type: MANAGE_INVOICE_STATUS_ERROR,
  payload: message,
});

export const manageDeliverStatusRequest = (request) => ({
  type: MANAGE_DELIVER_STATUS_REQUEST,
  payload: request,
});
export const manageDeliverStatusSuccess = (response) => ({
  type: MANAGE_DELIVER_STATUS_SUCCESS,
  payload: response,
});
export const manageDeliverStatusError = (message) => ({
  type: MANAGE_DELIVER_STATUS_ERROR,
  payload: message,
});
