import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { objectToQueryString, setCurrentUser } from "../../helpers/Utils";
import {
  CENTER_INFO_REQUEST,
  GET_PERMISSION_LIST_REQUEST,
  MENU_REQUEST,
  SUPPLIER_LOGIN_REQUEST,
  UNIQUE_KEY_PERMISSION_REQUEST,
  UPDATE_PERMISSIONS_REQUEST,
  USER_INFO_REQUEST,
  VALIDATE_LOGIN_REQUEST,
} from "../actions";
import {
  GET,
  POST,
  DELETE,
  LIST,
  PATCH,
  FORMDATA_POST,
  FORMDATA_PATCH,
} from "../api";
import {
  centerInfoError,
  centerInfoSuccess,
  menuError,
  menuSuccess,
  permissionListError,
  permissionListSuccess,
  uniqueKeyPermissionError,
  uniqueKeyPermissionSuccess,
  updatePermissionsError,
  updatePermissionsSuccess,
  userInfoError,
  userInfoSuccess,
  validateLoginError,
  validateLoginSuccess,
} from "./action";

export function* watchValidateLogin() {
  yield takeLatest(VALIDATE_LOGIN_REQUEST, validateLogin);
}

function* validateLogin({ payload }) {
  const { request, history } = payload;
  const url = `/user/login`;
  try {
    const response = yield call(POST, url, request);
    if (response && response.is_error) {
      yield put(validateLoginError(response.message));
    } else {
      // setCurrentUser(response);
      //window.location.href = "/pharmacy/bill";
      yield put(validateLoginSuccess(response));
    }
  } catch (error) {
    yield put(validateLoginError(error));
  }
}

export function* watchSupplierLogin() {
  yield takeLatest(SUPPLIER_LOGIN_REQUEST, validateSupplierLogin);
}

function* validateSupplierLogin({ payload }) {
  const { request, history } = payload;
  const url = `/supplier/login`;
  try {
    const response = yield call(POST, url, request);
    if (response && response.is_error) {
      yield put(validateLoginError(response.message));
    } else {
      setCurrentUser(response);
      history.push("/pharmacy/bill");
      yield put(validateLoginSuccess(response));
    }
  } catch (error) {
    yield put(validateLoginError(error));
  }
}

export function* watchUser() {
  yield takeLatest(USER_INFO_REQUEST, userInfo);
}

function* userInfo({ payload }) {
  const url = `/user/userinfo`;
  try {
    const response = yield call(GET, url, payload);
    if (response && response.is_error) {
      yield put(userInfoError(response.message));
    } else {
      yield put(userInfoSuccess(response));
    }
  } catch (error) {
    yield put(userInfoError(error));
  }
}

export function* watchMenu() {
  yield takeLatest(MENU_REQUEST, menu);
}

function* menu({ payload }) {
  const url = `/user/userMenu`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(menuError(response.message));
    } else {
      yield put(menuSuccess(response));
    }
  } catch (error) {
    yield put(menuError(error));
  }
}

export function* watchUniqueKeyPermission() {
  yield takeLatest(UNIQUE_KEY_PERMISSION_REQUEST, uniqueKeyPermission);
}

function* uniqueKeyPermission({ payload }) {
  const url = `/user/getPermission?${objectToQueryString(payload)}`;
  try {
    const response = yield call(GET, url);

    if (response && response.is_error) {
      yield put(uniqueKeyPermissionError(response.message));
    } else {
      yield put(uniqueKeyPermissionSuccess(response));
    }
  } catch (error) {
    yield put(uniqueKeyPermissionError(error));
  }
}

export function* watchPermissionList() {
  yield takeLatest(GET_PERMISSION_LIST_REQUEST, permissionList);
}

function* permissionList({ payload }) {
  const url = `/user/Permissions?${objectToQueryString(payload)}`;
  try {
    const response = yield call(LIST, url);
    if (response && response.is_error) {
      yield put(permissionListError(response.message));
    } else {
      yield put(permissionListSuccess(response));
    }
  } catch (error) {
    yield put(permissionListError(error));
  }
}

export function* watchUpdatePermissions() {
  yield takeLatest(UPDATE_PERMISSIONS_REQUEST, updatePermissions);
}

function* updatePermissions({ payload }) {
  const url = `/user/updatePermissions`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(updatePermissionsError(response.message));
    } else {
      yield put(updatePermissionsSuccess(response));
    }
  } catch (error) {
    yield put(updatePermissionsError(error));
  }
}

export function* watchCenterInfo() {
  yield takeLatest(CENTER_INFO_REQUEST, centerInfo);
}

function* centerInfo({ payload }) {
  const url = `/user/centerInfo`;
  try {
    const response = yield call(GET, url, payload);
    if (response && response.is_error) {
      yield put(centerInfoError(response.message));
    } else {
      yield put(centerInfoSuccess(response));
    }
  } catch (error) {
    yield put(centerInfoError(error));
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchValidateLogin),
    fork(watchSupplierLogin),
    fork(watchUser),
    fork(watchMenu),
    fork(watchUniqueKeyPermission),
    fork(watchPermissionList),
    fork(watchUpdatePermissions),
    fork(watchCenterInfo),
  ]);
}
