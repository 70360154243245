import {
  AUTO_ITEMS_ERROR,
  AUTO_ITEMS_REQUEST,
  AUTO_ITEMS_SUCCESS,
  CLEAR_SETUP_DEFAULTS,
  DELETE_MEDICINE_CATEGORY_ERROR,
  DELETE_MEDICINE_CATEGORY_REQUEST,
  DELETE_MEDICINE_CATEGORY_SUCCESS,
  DELETE_MEDICINE_DOSAGE_ERROR,
  DELETE_MEDICINE_DOSAGE_REQUEST,
  DELETE_MEDICINE_DOSAGE_SUCCESS,
  DELETE_SUPPLIER_ERROR,
  DELETE_SUPPLIER_REQUEST,
  DELETE_SUPPLIER_SUCCESS,
  MANAGE_MEDICINE_CATEGORY_ERROR,
  MANAGE_MEDICINE_CATEGORY_REQUEST,
  MANAGE_MEDICINE_CATEGORY_SUCCESS,
  MANAGE_MEDICINE_DOSAGE_ERROR,
  MANAGE_MEDICINE_DOSAGE_REQUEST,
  MANAGE_MEDICINE_DOSAGE_SUCCESS,
  MANAGE_SUPPLIER_ERROR,
  MANAGE_SUPPLIER_REQUEST,
  MANAGE_SUPPLIER_SUCCESS,
  MEDICINE_CATEGORY_LIST_ERROR,
  MEDICINE_CATEGORY_LIST_REQUEST,
  MEDICINE_CATEGORY_LIST_SUCCESS,
  MEDICINE_DOSAGE_LIST_ERROR,
  MEDICINE_DOSAGE_LIST_REQUEST,
  MEDICINE_DOSAGE_LIST_SUCCESS,
  SUPPLIER_DETAILS_ERROR,
  SUPPLIER_DETAILS_REQUEST,
  SUPPLIER_DETAILS_SUCCESS,
  SUPPLIER_LIST_ERROR,
  SUPPLIER_LIST_REQUEST,
  SUPPLIER_LIST_SUCCESS,
  ADMIN_UTILS_LIST_ERROR,
  ADMIN_UTILS_LIST_REQUEST,
  ADMIN_UTILS_LIST_SUCCESS,
  CLIENT_UTILS_LIST_REQUEST,
  CLIENT_UTILS_LIST_SUCCESS,
  CLIENT_UTILS_LIST_ERROR,
  AUTO_SUGGEST_REQUEST,
  AUTO_SUGGEST_SUCCESS,
  AUTO_SUGGEST_ERROR,
  MANAGE_ITEM_CATEGORY_RQUEST,
  MANAGE_ITEM_CATEGORY_SUCECSS,
  MANAGE_ITEM_CATEGORY_ERROR,
  ITEM_CATEGORY_LIST_REQUEST,
  ITEM_CATEGORY_LIST_SUCCESS,
  ITEM_CATEGORY_LIST_ERROR,
  MANAGE_LEAVETYPE_REQUEST,
  MANAGE_LEAVETYPE_SUCCESS,
  MANAGE_LEAVETYPE_ERROR,
  MANAGE_DEPARTMENT_REQUEST,
  MANAGE_DEPARTMENT_SUCCESS,
  MANAGE_DEPARTMENT_ERROR,
  MANAGE_DESIGNATION_REQUEST,
  MANAGE_DESIGNATION_SUCCESS,
  MANAGE_DESIGNATION_ERROR,
  LEAVETYPE_LIST_REQUEST,
  LEAVETYPE_LIST_SUCCESS,
  LEAVETYPE_LIST_ERROR,
  DEPARTMENT_LIST_REQUEST,
  DEPARTMENT_LIST_SUCCESS,
  DEPARTMENT_LIST_ERROR,
  DESIGNATION_LIST_REQUEST,
  DESIGNATION_LIST_SUCCESS,
  DESIGNATION_LIST_ERROR,
  MANAGE_SPECIALIZATION_REQUEST,
  MANAGE_SPECIALIZATION_SUCCES,
  MANAGE_SPECIALIZATION_ERROR,
  SPECIALIZATION_LIST_REQUEST,
  SPECIALIZATION_LIST_SUCCESS,
  SPECIALIZATION_LIST_ERROR,
  REQUEST,
  SUCCESS,
  CLEAR,
} from "../actions";

export const clearSetupDefaults = (message) => ({
  type: CLEAR_SETUP_DEFAULTS,
  payload: message,
});
export const manageMedicineCategoryRequest = (request) => ({
  type: MANAGE_MEDICINE_CATEGORY_REQUEST,
  payload: request,
});
export const manageMedicineCategorySuccess = (response) => ({
  type: MANAGE_MEDICINE_CATEGORY_SUCCESS,
  payload: response,
});
export const manageMedicineCategoryError = (message) => ({
  type: MANAGE_MEDICINE_CATEGORY_ERROR,
  payload: message,
});

export const deleteMedicineCategoryRequest = (request) => ({
  type: DELETE_MEDICINE_CATEGORY_REQUEST,
  payload: request,
});
export const deleteMedicineCategorySuccess = (response) => ({
  type: DELETE_MEDICINE_CATEGORY_SUCCESS,
  payload: response,
});
export const deleteMedicineCategoryError = (message) => ({
  type: DELETE_MEDICINE_CATEGORY_ERROR,
  payload: message,
});

export const medicineCategoryListRequest = (request) => ({
  type: MEDICINE_CATEGORY_LIST_REQUEST,
  payload: request,
});
export const medicineCategoryListSuccess = (response) => ({
  type: MEDICINE_CATEGORY_LIST_SUCCESS,
  payload: response,
});
export const medicineCategoryListError = (message) => ({
  type: MEDICINE_CATEGORY_LIST_ERROR,
  payload: message,
});

export const manageSupplierRequest = (request) => ({
  type: MANAGE_SUPPLIER_REQUEST,
  payload: request,
});
export const manageSupplierSuccess = (response) => ({
  type: MANAGE_SUPPLIER_SUCCESS,
  payload: response,
});
export const manageSupplierError = (message) => ({
  type: MANAGE_SUPPLIER_ERROR,
  payload: message,
});

export const deleteSupplierRequest = (request) => ({
  type: DELETE_SUPPLIER_REQUEST,
  payload: request,
});
export const deleteSupplierSuccess = (response) => ({
  type: DELETE_SUPPLIER_SUCCESS,
  payload: response,
});
export const deleteSupplierError = (message) => ({
  type: DELETE_SUPPLIER_ERROR,
  payload: message,
});

export const supplierListRequest = (request) => ({
  type: SUPPLIER_LIST_REQUEST,
  payload: request,
});
export const supplierListSuccess = (response) => ({
  type: SUPPLIER_LIST_SUCCESS,
  payload: response,
});
export const supplierListError = (message) => ({
  type: SUPPLIER_LIST_ERROR,
  payload: message,
});

export const manageMedicineDosageRequest = (request) => ({
  type: MANAGE_MEDICINE_DOSAGE_REQUEST,
  payload: request,
});
export const manageMedicineDosageSuccess = (response) => ({
  type: MANAGE_MEDICINE_DOSAGE_SUCCESS,
  payload: response,
});
export const manageMedicineDosageError = (message) => ({
  type: MANAGE_MEDICINE_DOSAGE_ERROR,
  payload: message,
});

export const deleteMedicineDosageRequest = (request) => ({
  type: DELETE_MEDICINE_DOSAGE_REQUEST,
  payload: request,
});
export const deleteMedicineDosageSuccess = (response) => ({
  type: DELETE_MEDICINE_DOSAGE_SUCCESS,
  payload: response,
});
export const deleteMedicineDosageError = (message) => ({
  type: DELETE_MEDICINE_DOSAGE_ERROR,
  payload: message,
});

export const medicineDosageListRequest = (request) => ({
  type: MEDICINE_DOSAGE_LIST_REQUEST,
  payload: request,
});
export const medicineDosageListSuccess = (response) => ({
  type: MEDICINE_DOSAGE_LIST_SUCCESS,
  payload: response,
});
export const medicineDosageListError = (message) => ({
  type: MEDICINE_DOSAGE_LIST_ERROR,
  payload: message,
});

export const adminUtilsRequest = (request) => ({
  type: ADMIN_UTILS_LIST_REQUEST,
  payload: request,
});
export const adminUtilsSuccess = (response) => ({
  type: ADMIN_UTILS_LIST_SUCCESS,
  payload: response,
});
export const adminUtilsError = (message) => ({
  type: ADMIN_UTILS_LIST_ERROR,
  payload: message,
});

export const clientUtilsRequest = (request) => ({
  type: CLIENT_UTILS_LIST_REQUEST,
  payload: request,
});
export const clientUtilsSuccess = (response) => ({
  type: CLIENT_UTILS_LIST_SUCCESS,
  payload: response,
});
export const clientUtilsError = (message) => ({
  type: CLIENT_UTILS_LIST_ERROR,
  payload: message,
});

export const autoItemsRequest = (request) => ({
  type: AUTO_ITEMS_REQUEST,
  payload: request,
});
export const autoItemsSuccess = (response) => ({
  type: AUTO_ITEMS_SUCCESS,
  payload: response,
});
export const autoItemsError = (message) => ({
  type: AUTO_ITEMS_ERROR,
  payload: message,
});

export const supplierDetailsRequest = (request) => ({
  type: SUPPLIER_DETAILS_REQUEST,
  payload: request,
});
export const supplierDetailsSuccess = (response) => ({
  type: SUPPLIER_DETAILS_SUCCESS,
  payload: response,
});
export const supplierDetailsError = (message) => ({
  type: SUPPLIER_DETAILS_ERROR,
  payload: message,
});

export const autoSuggestRequest = (request) => ({
  type: AUTO_SUGGEST_REQUEST,
  payload: request,
});
export const autoSuggestSuccess = (response) => ({
  type: AUTO_SUGGEST_SUCCESS,
  payload: response,
});
export const autoSuggestError = (message) => ({
  type: AUTO_SUGGEST_ERROR,
  payload: message,
});

export const manageItemCategoryRequest = (request) => ({
  type: MANAGE_ITEM_CATEGORY_RQUEST,
  payload: request,
});
export const manageItemCategorySuccess = (response) => ({
  type: MANAGE_ITEM_CATEGORY_SUCECSS,
  payload: response,
});
export const manageItemCategoryError = (message) => ({
  type: MANAGE_ITEM_CATEGORY_ERROR,
  payload: message,
});

export const itemCategoryListRequest = (request) => ({
  type: ITEM_CATEGORY_LIST_REQUEST,
  payload: request,
});
export const itemCategoryListSuccess = (response) => ({
  type: ITEM_CATEGORY_LIST_SUCCESS,
  payload: response,
});
export const itemCategoryListError = (message) => ({
  type: ITEM_CATEGORY_LIST_ERROR,
  payload: message,
});

export const manageLeaveTypeRequest = (request) => ({
  type: MANAGE_LEAVETYPE_REQUEST,
  payload: request,
});
export const manageLeaveTypeSuccess = (response) => ({
  type: MANAGE_LEAVETYPE_SUCCESS,
  payload: response,
});
export const manageLeaveTypeError = (message) => ({
  type: MANAGE_LEAVETYPE_ERROR,
  payload: message,
});

export const leaveTypeListRequest = (request) => ({
  type: LEAVETYPE_LIST_REQUEST,
  payload: request,
});
export const leaveTypeListSuccess = (response) => ({
  type: LEAVETYPE_LIST_SUCCESS,
  payload: response,
});
export const leaveTypeListError = (message) => ({
  type: LEAVETYPE_LIST_ERROR,
  payload: message,
});

export const manageDepartmentRequest = (request) => ({
  type: MANAGE_DEPARTMENT_REQUEST,
  payload: request,
});
export const manageDepartmentSuccess = (response) => ({
  type: MANAGE_DEPARTMENT_SUCCESS,
  payload: response,
});
export const manageDepartmentError = (message) => ({
  type: MANAGE_DEPARTMENT_ERROR,
  payload: message,
});

export const departmentListRequest = (request) => ({
  type: DEPARTMENT_LIST_REQUEST,
  payload: request,
});
export const departmentListSuccess = (response) => ({
  type: DEPARTMENT_LIST_SUCCESS,
  payload: response,
});
export const departmentListError = (message) => ({
  type: DEPARTMENT_LIST_ERROR,
  payload: message,
});

export const manageDesignationRequest = (request) => ({
  type: MANAGE_DESIGNATION_REQUEST,
  payload: request,
});
export const manageDesignationSuccess = (response) => ({
  type: MANAGE_DESIGNATION_SUCCESS,
  payload: response,
});
export const manageDesignationError = (message) => ({
  type: MANAGE_DESIGNATION_ERROR,
  payload: message,
});

export const designationListRequest = (request) => ({
  type: DESIGNATION_LIST_REQUEST,
  payload: request,
});
export const designationListSuccess = (response) => ({
  type: DESIGNATION_LIST_SUCCESS,
  payload: response,
});
export const designationListError = (message) => ({
  type: DESIGNATION_LIST_ERROR,
  payload: message,
});

export const manageSpecializationRequest = (request) => ({
  type: MANAGE_SPECIALIZATION_REQUEST,
  payload: request,
});
export const manageSpecializationSuccess = (response) => ({
  type: MANAGE_SPECIALIZATION_SUCCES,
  payload: response,
});
export const manageSpecializationError = (message) => ({
  type: MANAGE_SPECIALIZATION_ERROR,
  payload: message,
});

export const specializationListRequest = (request) => ({
  type: SPECIALIZATION_LIST_REQUEST,
  payload: request,
});
export const specializationListSuccess = (response) => ({
  type: SPECIALIZATION_LIST_SUCCESS,
  payload: response,
});
export const specializationListError = (message) => ({
  type: SPECIALIZATION_LIST_ERROR,
  payload: message,
});

export const setRequest = (request) => ({
  payload: request,
  type: REQUEST,
});
export const setRequestSuccess = (response) => ({
  payload: response,
  type: SUCCESS,
});

export const setRequestClear = (message) => ({
  payload: message,
  type: CLEAR,
});
