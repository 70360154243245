/* eslint-disable no-unused-vars */
import { all } from "redux-saga/effects";
import setupSaga from "./setup/saga";
import pharmacySaga from "./pharmacy/saga";
import patientSaga from "./patient/saga";
import staffSaga from "./staff/saga";
import userSaga from "./user/saga";
import inventorySaga from "./inventory/saga";
import supplierSaga from "./supplier/saga";
import invoiceSaga from "./invoice/saga";
import centerSaga from "./center/saga";
import guestSaga from "./guest/saga";
import onlineSaga from "./onlinepharmacy/saga";
import doctorSaga from "./doctor/saga";
import ticketSaga from "./ticket/saga";
import reportSaga from "./report/saga";
import v2pharmacySaga from "./v2pharmacy/saga";

export default function* rootSaga(getState) {
  yield all([
    setupSaga(),
    pharmacySaga(),
    patientSaga(),
    staffSaga(),
    userSaga(),
    inventorySaga(),
    supplierSaga(),
    invoiceSaga(),
    centerSaga(),
    guestSaga(),
    onlineSaga(),
    doctorSaga(),
    ticketSaga(),
    reportSaga(),
    v2pharmacySaga(),
  ]);
}
