import axios from "axios";
import { Multipartheader, header } from "../helpers/Utils";

export const POST = async (url, request) => {
  console.log(url, request, "post");
  return await axios
    .post(url, request, header())
    .then((x) => x.data)
    .catch((error) => {
      console.log(JSON.stringify(error));
      if (error) {
        error["is_error"] = true;
        if (error.response) {
          if (error.response.data && error.response.data.Message) {
            error.message = error.response.data.Message;
          } else if (error.response.data) {
            error.message = error.response.data;
          }
        }
      }
      return error;
    });
};
export const FORMDATA_POST = async (url, request) => {
  return await axios
    .post(url, request, Multipartheader())
    .then((x) => x.data)
    .catch((error) => {
      if (error) {
        error["is_error"] = true;
        if (error.response) {
          if (error.response.data && error.response.data.Message) {
            error.message = error.response.data.Message;
          } else if (error.response.data) {
            error.message = error.response.data;
          }
        }
      }
      return error;
    });
};

export const FORMDATA_PATCH = async (url, request) => {
  return await axios
    .patch(url, request, Multipartheader())
    .then((x) => x.data)
    .catch((error) => {
      if (error) {
        error["is_error"] = true;
        if (error.response) {
          if (error.response.data && error.response.data.Message) {
            error.message = error.response.data.Message;
          } else if (error.response.data) {
            error.message = error.response.data;
          }
        }
      }
      return error;
    });
};

export const GET = async (url) => {
  return await axios
    .get(url, header())
    .then((x) => x.data)
    .catch((error) => {
      if (error) {
        error["is_error"] = true;
        if (error.response) {
          if (error.response.data && error.response.data.Message) {
            error.message = error.response.data.Message;
          } else if (error.response.data) {
            error.message = error.response.data;
          }
        }
      }
      return error;
    });
};

export const LIST = async (url, request) => {
  return await axios
    .get(url, header())
    .then((x) => x.data)
    .catch((error) => {
      if (error) {
        error["is_error"] = true;
        if (error.response) {
          if (error.response.data && error.response.data.Message) {
            error.message = error.response.data.Message;
          } else if (error.response.data) {
            error.message = error.response.data;
          }
        }
      }
      return error;
    });
};

export const PATCH = async (url, request) => {
  return await axios
    .patch(url, request, header())
    .then((x) => x.data)
    .catch((error) => {
      if (error) {
        error["is_error"] = true;
        if (error.response) {
          if (error.response.data && error.response.data.Message) {
            error.message = error.response.data.Message;
          } else if (error.response.data) {
            error.message = error.response.data;
          }
        }
      }
      return error;
    });
};

export const DELETE = async (url) => {
  return await axios
    .delete(url, header())
    .then((x) => x.data)
    .catch((error) => {
      if (error) {
        error["is_error"] = true;
        if (error.response) {
          if (error.response.data && error.response.data.Message) {
            error.message = error.response.data.Message;
          } else if (error.response.data) {
            error.message = error.response.data;
          }
        }
      }
      return error;
    });
};
