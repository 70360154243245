import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { objectToQueryString } from "../../helpers/Utils";
import {
  GUEST_INFO_REQUEST,
  GUEST_LIST_REQUEST,
  MANAGE_GUEST_REQUEST,
} from "../actions";
import { GET, LIST, PATCH, POST } from "../api";
import {
  guestInfoError,
  guestInfoSuccess,
  guestListError,
  guestListSuccess,
  manageGuestError,
  manageGuestSuccess,
} from "./action";

export function* watchManageGuest() {
  yield takeLatest(MANAGE_GUEST_REQUEST, manageGuest);
}

function* manageGuest({ payload }) {
  const url = "/guest";
  try {
    const response = yield call(
      payload && payload.guest_id ? PATCH : POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageGuestError(response.message));
    } else {
      yield put(manageGuestSuccess(response));
    }
  } catch (error) {
    yield put(manageGuestError(error));
  }
}

export function* watchGuestInfo() {
  yield takeLatest(GUEST_INFO_REQUEST, guestInfo);
}

function* guestInfo({ payload }) {
  const url = `/guest?${objectToQueryString(payload)}`;
  try {
    const response = yield call(GET, url, payload);
    if (response && response.is_error) {
      yield put(guestInfoError(response.message));
    } else {
      yield put(guestInfoSuccess(response));
    }
  } catch (error) {
    yield put(guestInfoError(error));
  }
}

export function* watchGuestList() {
  yield takeLatest(GUEST_LIST_REQUEST, guestList);
}

function* guestList({ payload }) {
  const url = `/guest?${objectToQueryString(payload)}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(guestListError(response.message));
    } else {
      yield put(guestListSuccess(response));
    }
  } catch (error) {
    yield put(guestListError(error));
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchManageGuest),
    fork(watchGuestInfo),
    fork(watchGuestList),
  ]);
}
