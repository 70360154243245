import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { objectToQueryString } from "../../helpers/Utils";
import {
  BRAND_MASTER_LIST_REQUEST,
  BRAND_MASTER_REQUEST,
  deleteItemError,
  deleteItemSuccess,
  DELETE_ITEM_REQUEST,
  ITEM_DETAILS_REQUEST,
  ITEM_LIST_REQUEST,
  MANAGE_BRAND_MASTER_REQUEST,
  MANAGE_CENTRALSTOCK_REQUEST,
  MANAGE_ITEM_REQUEST,
  UPDATE_CETRAL_STOCK_DETAILS_REQUEST,
} from "../actions";
import { DELETE, GET, LIST, PATCH, POST } from "../api";
import {
  brandMasterError,
  brandMasterListError,
  brandMasterListSuccess,
  brandMasterSuccess,
  itemDetailsError,
  itemDetailsSuccess,
  itemListError,
  itemListSuccess,
  manageBrandMasterError,
  manageBrandMasterSuccess,
  manageCentralStockError,
  manageCentralStockSuccess,
  manageItemError,
  manageItemSuccess,
  updateCentralStockDetailsError,
  updateCentralStockDetailsSuccess,
} from "./action";

export function* watchManagebrandMaster() {
  yield takeLatest(MANAGE_BRAND_MASTER_REQUEST, manageBrandMaster);
}

function* manageBrandMaster({ payload }) {
  const url = "/inventory/brandmaster";
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageBrandMasterError(response.message));
    } else {
      yield put(manageBrandMasterSuccess(response));
    }
  } catch (error) {
    yield put(manageBrandMasterError(error));
  }
}

export function* watchBrandMaster() {
  yield takeLatest(BRAND_MASTER_REQUEST, brandMaster);
}

function* brandMaster({ payload }) {
  const url = `/inventory/brandmaster/${payload.brand_id}`;
  try {
    const response = yield call(GET, url);
    if (response && response.is_error) {
      yield put(brandMasterError(response.message));
    } else {
      yield put(brandMasterSuccess(response));
    }
  } catch (error) {
    yield put(brandMasterError(error));
  }
}

export function* watchBrandMasterList() {
  yield takeLatest(BRAND_MASTER_LIST_REQUEST, brandMasterList);
}

function* brandMasterList({ payload }) {
  const url = `/inventory/brandmaster`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(brandMasterListError(response.message));
    } else {
      yield put(brandMasterListSuccess(response));
    }
  } catch (error) {
    yield put(brandMasterListError(error));
  }
}

export function* watchManageItem() {
  yield takeLatest(MANAGE_ITEM_REQUEST, manageItem);
}

function* manageItem({ payload }) {
  const url = "/inventory/item";
  try {
    const response = yield call(payload.id > 0 ? PATCH : POST, url, payload);
    if (response && response.is_error) {
      yield put(manageItemError(response.message));
    } else {
      yield put(manageItemSuccess(response));
    }
  } catch (error) {
    yield put(manageItemError(error));
  }
}

export function* watchDeleteItem() {
  yield takeLatest(DELETE_ITEM_REQUEST, deletetItem);
}

function* deletetItem({ payload }) {
  const url = `/inventory/item/${payload.item_id}`;
  try {
    const response = yield call(PATCH, url, payload);
    if (response && response.is_error) {
      yield put(deleteItemError(response.message));
    } else {
      yield put(deleteItemSuccess(response));
    }
  } catch (error) {
    yield put(deleteItemError(error));
  }
}

export function* watchItemList() {
  yield takeLatest(ITEM_LIST_REQUEST, itemList);
}

function* itemList({ payload }) {
  const url = "/inventory/item";
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(itemListError(response.message));
    } else {
      yield put(itemListSuccess(response));
    }
  } catch (error) {
    yield put(itemListError(error));
  }
}

export function* watchItemDetails() {
  yield takeLatest(ITEM_DETAILS_REQUEST, itemDetails);
}

function* itemDetails({ payload }) {
  const url = `/inventory/item/${payload}`;
  try {
    const response = yield call(GET, url, payload);
    if (response && response.is_error) {
      yield put(itemDetailsError(response.message));
    } else {
      yield put(itemDetailsSuccess(response));
    }
  } catch (error) {
    yield put(itemDetailsError(error));
  }
}

export function* watchupdateCentralStockDetails() {
  yield takeLatest(
    UPDATE_CETRAL_STOCK_DETAILS_REQUEST,
    updateCentralStockDetails
  );
}

function* updateCentralStockDetails({ payload }) {
  const url = `/inventory/updateCentralStockDetails`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(updateCentralStockDetailsError(response.message));
    } else {
      yield put(updateCentralStockDetailsSuccess(response));
    }
  } catch (error) {
    yield put(updateCentralStockDetailsError(error));
  }
}

export function* watchManageCentralStock() {
  yield takeLatest(MANAGE_CENTRALSTOCK_REQUEST, manageCentralStock);
}
function* manageCentralStock({ payload, request_type }) {
  let url, response;
  try {
    if (request_type == "GET") {
      url = `/invoice/stockinvoiceInfo?${objectToQueryString(payload)}`;
      response = yield call(GET, url, payload);
    } else {
      yield put(manageCentralStockError("Invalid URL"));
    }
    if (response && response.is_error) {
      yield put(manageCentralStockError(response.message));
    } else {
      yield put(manageCentralStockSuccess(response, request_type));
    }
  } catch (error) {
    yield put(manageCentralStockError(error));
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchManagebrandMaster),
    fork(watchBrandMaster),
    fork(watchBrandMasterList),
    fork(watchManageItem),
    fork(watchItemList),
    fork(watchItemDetails),
    fork(watchupdateCentralStockDetails),
    fork(watchDeleteItem),
    fork(watchManageCentralStock),
  ]);
}
