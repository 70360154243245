import React, { useEffect, Suspense, useState, useMemo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { NotificationContainer } from "react-notifications";
import { CheckAuth } from "./helpers/Utils";
import { connect } from "react-redux";
import {
  centerInfoRequest,
  menuRequest,
  setRequest,
  userInfoRequest,
} from "./redux/actions";
import Ticket from "./views/ticket";
const AdminView = React.lazy(() => import("./views/admin"));
const UserView = React.lazy(() => import("./views/user"));
const DashboardView = React.lazy(() => import("./views/dashboard"));
const SetttingsView = React.lazy(() => import("./views/setup"));
const HumanResourceView = React.lazy(() => import("./views/humanresource"));
const AppointmentView = React.lazy(() => import("./views/appointment"));
const PharmacyView = React.lazy(() => import("./views/pharmacy"));
const PathologyView = React.lazy(() => import("./views/pathology"));
const RadiologyView = React.lazy(() => import("./views/radiology"));
const PatientView = React.lazy(() => import("./views/patient"));
const InventoryView = React.lazy(() => import("./views/inventory"));
const SupplierView = React.lazy(() => import("./views/supplier"));
const InvoiceView = React.lazy(() => import("./views/invoice"));
const CenterView = React.lazy(() => import("./views/center"));
const GuestView = React.lazy(() => import("./views/guest"));
const OnlineView = React.lazy(() => import("./views/online"));
const DoctorView = React.lazy(() => import("./views/doctor"));

const ReportsView = React.lazy(() => import("./views/reports"));

const PharmacyV2View = React.lazy(() => import("./views/v2pharmacy"));

const App = (props) => {
  const { request } = props;
  useEffect(() => {
    if (CheckAuth()) {
      props.menuRequestAsync();
      props.UserInfoAsync();
      props.centerInfoAsync();
    }
  }, []);

  return (
    <>
      <NotificationContainer />
      <Ticket />

      <Router>
        <Suspense fallback={<div className="loading" />}>
          <Switch>
            <Route path="/user" render={(props) => <UserView {...props} />} />
            <AuthRoute path="/admin" component={AdminView} />
            <AuthRoute path="/dashboard" component={DashboardView} />
            <AuthRoute path="/setup" component={SetttingsView} />
            <AuthRoute path="/humanresource" component={HumanResourceView} />
            <AuthRoute path="/appointment" component={AppointmentView} />
            <AuthRoute path="/pharmacy" component={PharmacyView} />
            <AuthRoute path="/pathology" component={PathologyView} />
            <AuthRoute path="/radiology" component={RadiologyView} />
            <AuthRoute path="/patient" component={PatientView} />
            <AuthRoute path="/inventory" component={InventoryView} />
            <AuthRoute path="/supplier" component={SupplierView} />
            <AuthRoute path="/invoice" component={InvoiceView} />
            <AuthRoute path="/center" component={CenterView} />
            <AuthRoute path="/guest" component={GuestView} />
            <AuthRoute path="/online" component={OnlineView} />
            <AuthRoute path="/doctor" component={DoctorView} />
            <AuthRoute path="/reports" component={ReportsView} />
            <AuthRoute path="/v2/pharamacy" component={PharmacyV2View} />

            <Route path="/" exact render={(props) => <UserView {...props} />} />
          </Switch>
        </Suspense>
      </Router>
      {/* {CheckAuth() && (
        <span
          style={{
            position: "fixed",
            right: "2%",
            bottom: "2%",
            borderRadius: "50%",
            background: "orange",
            color: "#fff",
            height: "50px",
            width: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            props.setRequestAsync({ ...request, support_modal: true });
          }}
        >
          <i className="fa fa-support fa-2x" />
        </span>
      )} */}
    </>
  );
};

const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        CheckAuth() == true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/user/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
const MapstateToProps = ({ user_reducer, setup_reducer }) => {
  const { user, menu } = user_reducer;
  const { request } = setup_reducer;

  return { user, menu, request };
};
const MapDispatchToProps = {
  UserInfoAsync: userInfoRequest,
  menuRequestAsync: menuRequest,
  setRequestAsync: setRequest,
  centerInfoAsync: centerInfoRequest,
};
export default connect(MapstateToProps, MapDispatchToProps)(App);
