import {
  AUTO_PATIENT_SUGGEST_ERROR,
  AUTO_PATIENT_SUGGEST_REQUEST,
  AUTO_PATIENT_SUGGEST_SUCCESS,
  CLEAR_PATIENT_DEFAULTS,
  MANAGE_PATIENT_ERROR,
  MANAGE_PATIENT_REQUEST,
  MANAGE_PATIENT_SUCCESS,
} from "../actions";
export const clearPatientDefaults = (message) => ({
  type: CLEAR_PATIENT_DEFAULTS,
  payload: message,
});
export const managePatientRequest = (request) => ({
  type: MANAGE_PATIENT_REQUEST,
  payload: request,
});
export const managePatientSuccess = (response) => ({
  type: MANAGE_PATIENT_SUCCESS,
  payload: response,
});
export const managePatientError = (message) => ({
  type: MANAGE_PATIENT_ERROR,
  payload: message,
});

export const autoPatientRequest = (request) => ({
  type: AUTO_PATIENT_SUGGEST_REQUEST,
  payload: request,
});
export const autoPatientSuccess = (response) => ({
  type: AUTO_PATIENT_SUGGEST_SUCCESS,
  payload: response,
});
export const autoPatientError = (message) => ({
  type: AUTO_PATIENT_SUGGEST_ERROR,
  payload: message,
});
