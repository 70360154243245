import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { objectToQueryString } from "../../helpers/Utils";
import {
  V2_MANAGE_CENTER_STOCK_REQUEST,
  V2_MANAGE_PHARMAACY_REQUEST,
} from "../actions";
import {
  GET,
  POST,
  DELETE,
  LIST,
  PATCH,
  FORMDATA_POST,
  FORMDATA_PATCH,
} from "../api";
import {
  v2ManageCentralStockError,
  v2ManageCentralStockSuccess,
  v2ManagePharmacyError,
  v2ManagePharmacySuccess,
} from "./action";

export function* watchManageV2CentralStock() {
  yield takeLatest(V2_MANAGE_CENTER_STOCK_REQUEST, manageV2CentralStock);
}
function* manageV2CentralStock({ payload, request_type }) {
  let url, response;

  try {
    if (request_type === "ADD_CENTRAL_STOCK") {
      url = `/v2pharmacy/centralstock`;
      response = yield call(POST, url, payload);
    } else if (request_type === "EDIT_CENTRAL_STOCK") {
      url = `/v2pharmacy/centralstock`;
      response = yield call(PATCH, url, payload);
    } else {
      yield put(v2ManageCentralStockError("Invalid URL Request"));
    }
    if (response && response.is_error) {
      yield put(v2ManageCentralStockError(response.message));
    } else {
      yield put(v2ManageCentralStockSuccess(response, request_type));
    }
  } catch (error) {
    yield put(v2ManageCentralStockError(error));
  }
}

export function* watchv2ManagePharmacy() {
  yield takeLatest(V2_MANAGE_PHARMAACY_REQUEST, managev2PharmacyAsync);
}
function* managev2PharmacyAsync({ payload, request_type }) {
  let url, response;

  try {
    if (request_type === "SHARE_BILL") {
      url = `/v2pharmacy/sharebill`;
      response = yield call(POST, url, payload);
    } else if (request_type === "BILL_BATCH_LIST") {
      url = `/v2pharmacy/billbatchlist`;
      response = yield call(POST, url, payload);
    } else if (request_type === "PRINT_BILL") {
      url = `/v2pharmacy/printbill?${objectToQueryString(payload)}`;
      response = yield call(GET, url, payload);
    } else {
      yield put(v2ManagePharmacyError("Invalid URL Request"));
    }
    if (response && response.is_error) {
      yield put(v2ManagePharmacyError(response.message));
    } else {
      yield put(v2ManagePharmacySuccess(response, request_type));
    }
  } catch (error) {
    yield put(v2ManagePharmacyError(error));
  }
}

export default function* rootSaga() {
  yield all([fork(watchv2ManagePharmacy), fork(watchManageV2CentralStock)]);
}
