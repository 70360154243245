import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { objectToQueryString } from "../../helpers/Utils";
import { MANAGE_IDC_TICKET_REQUEST } from "../actions";
import {
  GET,
  POST,
  DELETE,
  LIST,
  PATCH,
  FORMDATA_POST,
  FORMDATA_PATCH,
} from "../api";
import { manageIDCTicketError, manageIDCTicketSuccess } from "./action";

export function* watchManageIDCTicket() {
  yield takeLatest(MANAGE_IDC_TICKET_REQUEST, manageIDCTicket);
}

function* manageIDCTicket({ payload, request_type }) {
  let url, response;
  try {
    if (request_type == "ADD") {
      let fd = new FormData();
      Object.keys(payload).map((key, index) => {
        fd.append(key, payload[key]);
      });
      if (!payload.file) fd.delete("file", null);
      url = `/ticket`;
      response = yield call(FORMDATA_POST, url, fd);
    } else if (request_type == "CONVERSATION_LIST") {
      url = `/ticket/conversation?${objectToQueryString(payload)}`;
      response = yield call(GET, url, payload);
    } else {
      yield put(manageIDCTicketError("Invalid URL"));
    }
    if (response && response.is_error) {
      yield put(manageIDCTicketError(response.message));
    } else {
      yield put(manageIDCTicketSuccess(response, request_type));
    }
  } catch (error) {
    yield put(manageIDCTicketError(error));
  }
}

export default function* rootSaga() {
  yield all([fork(watchManageIDCTicket)]);
}
