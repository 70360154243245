import {
  V2_MANAGE_CENTER_STOCK_ERROR,
  V2_MANAGE_CENTER_STOCK_REQUEST,
  V2_MANAGE_CENTER_STOCK_SUCCESS,
  V2_MANAGE_PHARMAACY_ERROR,
  V2_MANAGE_PHARMAACY_REQUEST,
  V2_MANAGE_PHARMAACY_SUCCESS,
} from "../actions";

const INIT_STATE = {
  v2pharmacy_loading: false,
  v2pharmacy_success: null,
  v2pharmacy_error: null,
  v2pharmacy_action: null,
  v2pharmacy_request_type: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case V2_MANAGE_CENTER_STOCK_REQUEST:
      return {
        ...state,
        v2pharmacy_loading: true,
        v2pharmacy_error: null,
        v2pharmacy_success: null,
        v2pharmacy_action: action.type,
        v2pharmacy_request_type: action.request_type,
      };
    case V2_MANAGE_CENTER_STOCK_SUCCESS:
      if (
        action.request_type === "ADD_CENTRAL_STOCK" ||
        action.request_type === "EDIT_CENTRAL_STOCK"
      ) {
        return {
          ...state,
          v2pharmacy_loading: false,
          v2pharmacy_success: action.payload,
        };
      }
    case V2_MANAGE_CENTER_STOCK_ERROR:
      return {
        ...state,
        v2pharmacy_loading: false,
        v2pharmacy_error: action.payload,
      };

    case V2_MANAGE_PHARMAACY_REQUEST:
      return {
        ...state,
        v2pharmacy_loading: true,
        v2pharmacy_error: null,
        v2pharmacy_success: null,
        v2pharmacy_action: action.type,
        v2pharmacy_request_type: action.request_type,
      };
    case V2_MANAGE_PHARMAACY_SUCCESS:
      if (action.request_type === "SHARE_BILL") {
        return {
          ...state,
          v2pharmacy_loading: false,
          v2pharmacy_success: action.payload,
        };
      } else if (action.request_type === "BILL_BATCH_LIST") {
        return {
          ...state,
          v2pharmacy_loading: false,
          bill_batch_list: action.payload,
        };
      } else if (action.request_type === "PRINT_BILL") {
        return {
          ...state,
          v2pharmacy_loading: false,
          print_bill: action.payload,
        };
      }
    case V2_MANAGE_PHARMAACY_ERROR:
      return {
        ...state,
        v2pharmacy_loading: false,
        v2pharmacy_error: action.payload,
      };

    default:
      return { ...state };
  }
};
