import {
  MANAGE_REPORT_ERROR,
  MANAGE_REPORT_REQUEST,
  MANAGE_REPORT_SUCCESS,
} from "../actions";

export const manageReportRequest = (request) => ({
  type: MANAGE_REPORT_REQUEST,
  payload: request,
  request_type: request.request_type,
});
export const manageReportSuccess = (response, request_type) => ({
  type: MANAGE_REPORT_SUCCESS,
  payload: response,
  request_type: request_type,
});
export const manageReportError = (message) => ({
  type: MANAGE_REPORT_ERROR,
  payload: message,
});
