import {
  CENTER_INFO_ERROR,
  CENTER_INFO_REQUEST,
  CENTER_INFO_SUCCESS,
  CLEAR_USER_DEFAULTS,
  GET_PERMISSION_LIST_ERROR,
  GET_PERMISSION_LIST_REQUEST,
  GET_PERMISSION_LIST_SUCCESS,
  MENU_ERROR,
  MENU_REQUEST,
  MENU_SUCCESS,
  SUPPLIER_LOGIN_ERROR,
  SUPPLIER_LOGIN_REQUEST,
  SUPPLIER_LOGIN_SUCCESS,
  UNIQUE_KEY_PERMISSION_ERROR,
  UNIQUE_KEY_PERMISSION_REQUEST,
  UNIQUE_KEY_PERMISSION_SUCCESS,
  UPDATE_PERMISSIONS_ERROR,
  UPDATE_PERMISSIONS_REQUEST,
  UPDATE_PERMISSIONS_SUCCESS,
  USER_INFO_ERROR,
  USER_INFO_REQUEST,
  USER_INFO_SUCCESS,
  VALIDATE_LOGIN_ERROR,
  VALIDATE_LOGIN_REQUEST,
  VALIDATE_LOGIN_SUCCESS,
} from "../actions";

const INIT_STATE = {
  user_loading: false,
  user_error: null,
  user_success: null,
  user_action: null,
  user: null,
  menu: [],
  permission: null,
  permission_list: [],
  center_info: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_USER_DEFAULTS:
      return {
        ...state,
        user_loading: false,
        user_error: null,
        user_success: null,
        user_action: null,
      };
    case VALIDATE_LOGIN_REQUEST:
      return {
        ...state,
        user_loading: true,
        user_error: null,
        user_success: null,
        user_action: action.type,
      };
    case VALIDATE_LOGIN_SUCCESS:
      return {
        ...state,
        user_loading: false,
        user_success: action.payload,
      };
    case VALIDATE_LOGIN_ERROR:
      return {
        ...state,
        user_loading: false,
        user_error: action.payload,
      };
    case SUPPLIER_LOGIN_REQUEST:
      return {
        ...state,
        user_loading: true,
        user_error: null,
        user_success: null,
        user_action: action.type,
      };
    case SUPPLIER_LOGIN_SUCCESS:
      return {
        ...state,
        user_loading: false,
        user_success: action.payload,
      };
    case SUPPLIER_LOGIN_ERROR:
      return {
        ...state,
        user_loading: false,
        user_error: action.payload,
      };

    case USER_INFO_REQUEST:
      return {
        ...state,
        user_loading: true,
        user_error: null,
        user_success: null,
        user_action: action.type,
        user: null,
      };
    case USER_INFO_SUCCESS:
      return {
        ...state,
        user_loading: false,
        user: action.payload,
      };
    case USER_INFO_ERROR:
      return {
        ...state,
        user_loading: false,
        user_error: action.payload,
      };

    case MENU_REQUEST:
      return {
        ...state,
        user_loading: true,
        user_error: null,
        user_success: null,
        user_action: action.type,
        menu: [],
      };
    case MENU_SUCCESS:
      return {
        ...state,
        user_loading: false,
        menu: action.payload,
      };
    case MENU_ERROR:
      return {
        ...state,
        user_loading: false,
        user_error: action.payload,
      };

    case UNIQUE_KEY_PERMISSION_REQUEST:
      return {
        ...state,
        user_loading: true,
        user_error: null,
        user_success: null,
        user_action: action.type,
        permission: null,
      };
    case UNIQUE_KEY_PERMISSION_SUCCESS:
      return {
        ...state,
        user_loading: false,
        permission: action.payload,
      };
    case UNIQUE_KEY_PERMISSION_ERROR:
      return {
        ...state,
        user_loading: false,
        user_error: action.payload,
      };

    case GET_PERMISSION_LIST_REQUEST:
      return {
        ...state,
        user_loading: true,
        user_error: null,
        user_success: null,
        user_action: action.type,
        permission_list: [],
      };
    case GET_PERMISSION_LIST_SUCCESS:
      return {
        ...state,
        user_loading: false,
        permission_list: action.payload,
      };
    case GET_PERMISSION_LIST_ERROR:
      return {
        ...state,
        user_loading: false,
        user_error: action.payload,
      };

    case UPDATE_PERMISSIONS_REQUEST:
      return {
        ...state,
        user_loading: true,
        user_error: null,
        user_success: null,
        user_action: action.type,
      };
    case UPDATE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        user_loading: false,
        user_success: action.payload,
      };
    case UPDATE_PERMISSIONS_ERROR:
      return {
        ...state,
        user_loading: false,
        user_error: action.payload,
      };

    case CENTER_INFO_REQUEST:
      return {
        ...state,
        user_loading: true,
        user_error: null,
        user_success: null,
        user_action: action.type,
        center_info: null,
      };
    case CENTER_INFO_SUCCESS:
      return {
        ...state,
        user_loading: false,
        center_info: action.payload,
      };
    case CENTER_INFO_ERROR:
      return {
        ...state,
        user_loading: false,
        user_error: action.payload,
      };
    default:
      return { ...state };
  }
};
