import {
  BRAND_MASTER_ERROR,
  BRAND_MASTER_LIST_ERROR,
  BRAND_MASTER_LIST_REQUEST,
  BRAND_MASTER_LIST_SUCCESS,
  BRAND_MASTER_REQUEST,
  BRAND_MASTER_SUCCESS,
  STOCK_INVOICE_LIST_ERROR,
  STOCK_INVOICE_LIST_REQUEST,
  STOCK_INVOICE_LIST_SUCCESS,
  CLEAR_INVENTORY_DEFAULTS,
  ITEM_LIST_ERROR,
  ITEM_LIST_REQUEST,
  ITEM_LIST_SUCCESS,
  MANAGE_BRAND_MASTER_ERROR,
  MANAGE_BRAND_MASTER_REQUEST,
  MANAGE_BRAND_MASTER_SUCCESS,
  MANAGE_STOCK_INVOICE_ERROR,
  MANAGE_STOCK_INVOICE_REQUEST,
  MANAGE_STOCK_INVOICE_SUCCESS,
  MANAGE_ITEM_ERROR,
  MANAGE_ITEM_REQUEST,
  MANAGE_ITEM_SUCCESS,
  STOCK_INVOICE_REQUEST,
  STOCK_INVOICE_ERROR,
  STOCK_INVOICE_SUCCESS,
  ITEM_DETAILS_REQUEST,
  ITEM_DETAILS_SUCCESS,
  ITEM_DETAILS_ERROR,
  UPDATE_CETRAL_STOCK_DETAILS_REQUEST,
  UPDATE_CETRAL_STOCK_DETAILS_SUCCESS,
  UPDATE_CETRAL_STOCK_DETAILS_ERROR,
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_ERROR,
  MANAGE_CENTRALSTOCK_REQUEST,
  MANAGE_CENTRALSTOCK_SUCCESS,
  MANAGE_CENTRALSTOCK_ERROR,
} from "../actions";
const INIT_STATE = {
  inventory_loading: false,
  inventory_success: null,
  inventory_error: null,
  inventory_action: null,
  brand_master: null,
  brand_master_list: [],
  supplier_order_list: [],
  supplier_order: null,
  stock_invoice_list: [],
  stock_invoice: null,
  item: null,
  items: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_INVENTORY_DEFAULTS:
      return {
        ...state,
        inventory_loading: false,
        inventory_error: null,
        inventory_success: null,
        inventory_action: null,
      };
    case MANAGE_BRAND_MASTER_REQUEST:
      return {
        ...state,
        inventory_loading: true,
        inventory_error: null,
        inventory_success: null,
        inventory_action: action.type,
      };
    case MANAGE_BRAND_MASTER_SUCCESS:
      return {
        ...state,
        inventory_loading: false,
        inventory_success: action.payload,
      };

    case MANAGE_BRAND_MASTER_ERROR:
      return {
        ...state,
        inventory_loading: false,
        inventory_error: action.payload,
      };

    case BRAND_MASTER_LIST_REQUEST:
      return {
        ...state,
        inventory_loading: true,
        inventory_error: null,
        inventory_success: null,
        inventory_action: action.type,
        brand_master_list: [],
      };
    case BRAND_MASTER_LIST_SUCCESS:
      return {
        ...state,
        inventory_loading: false,
        brand_master_list: action.payload,
      };

    case BRAND_MASTER_LIST_ERROR:
      return {
        ...state,
        inventory_loading: false,
        inventory_error: action.payload,
      };

    case BRAND_MASTER_REQUEST:
      return {
        ...state,
        inventory_loading: true,
        inventory_error: null,
        inventory_success: null,
        inventory_action: action.type,
        brand_master: null,
      };
    case BRAND_MASTER_SUCCESS:
      return {
        ...state,
        inventory_loading: false,
        brand_master: action.payload,
      };

    case BRAND_MASTER_ERROR:
      return {
        ...state,
        inventory_loading: false,
        inventory_error: action.payload,
      };

    case MANAGE_ITEM_REQUEST:
      return {
        ...state,
        inventory_loading: true,
        inventory_error: null,
        inventory_success: null,
        inventory_action: action.type,
      };
    case MANAGE_ITEM_SUCCESS:
      return {
        ...state,
        inventory_loading: false,
        inventory_success: action.payload,
      };

    case MANAGE_ITEM_ERROR:
      return {
        ...state,
        inventory_loading: false,
        inventory_error: action.payload,
      };
    case DELETE_ITEM_REQUEST:
      return {
        ...state,
        inventory_loading: true,
        inventory_error: null,
        inventory_success: null,
        inventory_action: action.type,
      };
    case DELETE_ITEM_SUCCESS:
      return {
        ...state,
        inventory_loading: false,
        inventory_success: action.payload,
      };

    case DELETE_ITEM_ERROR:
      return {
        ...state,
        inventory_loading: false,
        inventory_error: action.payload,
      };
    case ITEM_LIST_REQUEST:
      return {
        ...state,
        inventory_loading: true,
        inventory_error: null,
        inventory_success: null,
        inventory_action: action.type,
        items: [],
      };
    case ITEM_LIST_SUCCESS:
      return {
        ...state,
        inventory_loading: false,
        items: action.payload,
      };

    case ITEM_LIST_ERROR:
      return {
        ...state,
        inventory_loading: false,
        inventory_error: action.payload,
      };

    case ITEM_DETAILS_REQUEST:
      return {
        ...state,
        inventory_loading: true,
        inventory_error: null,
        inventory_success: null,
        inventory_action: action.type,
        item: null,
      };
    case ITEM_DETAILS_SUCCESS:
      return {
        ...state,
        inventory_loading: false,
        item: action.payload,
      };

    case ITEM_DETAILS_ERROR:
      return {
        ...state,
        inventory_loading: false,
        inventory_error: action.payload,
      };

    case MANAGE_STOCK_INVOICE_REQUEST:
      return {
        ...state,
        inventory_loading: true,
        inventory_error: null,
        inventory_success: null,
        inventory_action: action.type,
      };
    case MANAGE_STOCK_INVOICE_SUCCESS:
      return {
        ...state,
        inventory_loading: false,
        inventory_success: action.payload,
      };

    case MANAGE_STOCK_INVOICE_ERROR:
      return {
        ...state,
        inventory_loading: false,
        inventory_error: action.payload,
      };
    case STOCK_INVOICE_LIST_REQUEST:
      return {
        ...state,
        inventory_loading: true,
        inventory_error: null,
        inventory_success: null,
        inventory_action: action.type,
        stock_invoice_list: [],
      };
    case STOCK_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        inventory_loading: false,
        stock_invoice_list: action.payload,
      };

    case STOCK_INVOICE_LIST_ERROR:
      return {
        ...state,
        inventory_loading: false,
        inventory_error: action.payload,
      };

    case STOCK_INVOICE_REQUEST:
      return {
        ...state,
        inventory_loading: true,
        inventory_error: null,
        inventory_success: null,
        inventory_action: action.type,
        stock_invoice: null,
      };
    case STOCK_INVOICE_SUCCESS:
      return {
        ...state,
        inventory_loading: false,
        stock_invoice: action.payload,
      };

    case STOCK_INVOICE_ERROR:
      return {
        ...state,
        inventory_loading: false,
        inventory_error: action.payload,
      };

    case UPDATE_CETRAL_STOCK_DETAILS_REQUEST:
      return {
        ...state,
        inventory_loading: true,
        inventory_error: null,
        inventory_success: null,
        inventory_action: action.type,
      };
    case UPDATE_CETRAL_STOCK_DETAILS_SUCCESS:
      return {
        ...state,
        inventory_loading: false,
        inventory_success: action.payload,
      };

    case UPDATE_CETRAL_STOCK_DETAILS_ERROR:
      return {
        ...state,
        inventory_loading: false,
        inventory_error: action.payload,
      };

    case MANAGE_CENTRALSTOCK_REQUEST:
      return {
        ...state,
        inventory_loading: true,
        inventory_error: null,
        inventory_success: null,
        inventory_action: action.type,
        request_type: action.request,
      };
    case MANAGE_CENTRALSTOCK_SUCCESS:
      if (action.request_type == "GET") {
        return {
          ...state,
          inventory_loading: false,
          stock_invoice: action.payload,
        };
      }

    case MANAGE_CENTRALSTOCK_ERROR:
      return {
        ...state,
        inventory_loading: false,
        inventory_error: action.payload,
      };
    default:
      return { ...state };
  }
};
