import {
  MANAGE_IDC_TICKET_ERROR,
  MANAGE_IDC_TICKET_REQUEST,
  MANAGE_IDC_TICKET_SUCCESS,
} from "../actions";

export const manageIDCTicketRequest = (request) => ({
  type: MANAGE_IDC_TICKET_REQUEST,
  payload: request,
  request_type: request.request_type,
});
export const manageIDCTicketSuccess = (response, request_type) => ({
  type: MANAGE_IDC_TICKET_SUCCESS,
  payload: response,
  request_type: request_type,
});
export const manageIDCTicketError = (message) => ({
  type: MANAGE_IDC_TICKET_ERROR,
  payload: message,
});
