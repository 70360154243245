import {
  STOCK_INVOICE_LIST_ERROR,
  STOCK_INVOICE_LIST_REQUEST,
  STOCK_INVOICE_LIST_SUCCESS,
  STOCK_INVOICE_REQUEST,
  STOCK_INVOICE_ERROR,
  STOCK_INVOICE_SUCCESS,
  MANAGE_STOCK_INVOICE_REQUEST,
  MANAGE_STOCK_INVOICE_SUCCESS,
  MANAGE_STOCK_INVOICE_ERROR,
  INVOICE_SUPPLIER_LIST_REQUEST,
  INVOICE_SUPPLIER_LIST_SUCCESS,
  INVOICE_SUPPLIER_LIST_ERROR,
  STOCK_INVOICE_OVERVIEW_REQUEST,
  STOCK_INVOICE_OVERVIEW_SUCCESS,
  STOCK_INVOICE_OVERVIEW_ERROR,
  MANAGE_STOCKINVOICE_RETURN_REQUEST,
  MANAGE_STOCKINVOICE_RETURN_SUCCESS,
  MANAGE_STOCKINVOICE_RETURN_ERROR,
  MANAGE_STOCKINVOICE_PAYMENT_REQUEST,
  MANAGE_STOCKINVOICE_PAYMENT_SUCCESS,
  MANAGE_STOCKINVOICE_PAYMENT_ERROR,
  V2_MANAGE_STOCK_INVOICE_REQUEST,
  V2_MANAGE_STOCK_INVOICE_SUCCESS,
  V2_MANAGE_STOCK_INVOICE_ERROR,
} from "../actions";
const INIT_STATE = {
  invoice_loading: false,
  invoice_success: null,
  invoice_error: null,
  invoice_action: null,
  stock_invoice_list: [],
  stock_invoice_info: null,
  supplier_list: [],
  stock_invoice_overview: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MANAGE_STOCK_INVOICE_REQUEST:
      return {
        ...state,
        invoice_loading: true,
        invoice_error: null,
        invoice_success: null,
        invoice_action: action.type,
      };
    case MANAGE_STOCK_INVOICE_SUCCESS:
      return {
        ...state,
        invoice_loading: false,
        invoice_success: action.payload,
      };

    case MANAGE_STOCK_INVOICE_ERROR:
      return {
        ...state,
        invoice_loading: false,
        invoice_error: action.payload,
      };
    case STOCK_INVOICE_LIST_REQUEST:
      return {
        ...state,
        invoice_loading: true,
        invoice_error: null,
        invoice_success: null,
        invoice_action: action.type,
        stock_invoice_list: [],
      };
    case STOCK_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        invoice_loading: false,
        stock_invoice_list: action.payload,
      };

    case STOCK_INVOICE_LIST_ERROR:
      return {
        ...state,
        invoice_loading: false,
        invoice_error: action.payload,
      };

    case STOCK_INVOICE_REQUEST:
      return {
        ...state,
        invoice_loading: true,
        invoice_error: null,
        invoice_success: null,
        invoice_action: action.type,
        stock_invoice_info: null,
      };
    case STOCK_INVOICE_SUCCESS:
      return {
        ...state,
        invoice_loading: false,
        stock_invoice_info: action.payload,
      };

    case STOCK_INVOICE_ERROR:
      return {
        ...state,
        invoice_loading: false,
        invoice_error: action.payload,
      };

    case INVOICE_SUPPLIER_LIST_REQUEST:
      return {
        ...state,
        invoice_loading: true,
        invoice_error: null,
        invoice_success: null,
        invoice_action: action.type,
        supplier_list: null,
      };
    case INVOICE_SUPPLIER_LIST_SUCCESS:
      return {
        ...state,
        invoice_loading: false,
        supplier_list: action.payload,
      };

    case INVOICE_SUPPLIER_LIST_ERROR:
      return {
        ...state,
        invoice_loading: false,
        invoice_error: action.payload,
      };

    case STOCK_INVOICE_OVERVIEW_REQUEST:
      return {
        ...state,
        invoice_loading: true,
        invoice_error: null,
        invoice_success: null,
        invoice_action: action.type,
        stock_invoice_overview: [],
      };
    case STOCK_INVOICE_OVERVIEW_SUCCESS:
      return {
        ...state,
        invoice_loading: false,
        stock_invoice_overview: action.payload,
      };

    case STOCK_INVOICE_OVERVIEW_ERROR:
      return {
        ...state,
        invoice_loading: false,
        invoice_error: action.payload,
      };
    case MANAGE_STOCKINVOICE_RETURN_REQUEST:
      return {
        ...state,
        invoice_loading: true,
        invoice_error: null,
        invoice_success: null,
        invoice_action: action.type,
      };
    case MANAGE_STOCKINVOICE_RETURN_SUCCESS:
      return {
        ...state,
        invoice_loading: false,
        invoice_success: action.payload,
      };

    case MANAGE_STOCKINVOICE_RETURN_ERROR:
      return {
        ...state,
        invoice_loading: false,
        invoice_error: action.payload,
      };
    case MANAGE_STOCKINVOICE_PAYMENT_REQUEST:
      return {
        ...state,
        invoice_loading: true,
        invoice_error: null,
        invoice_success: null,
        invoice_action: action.type,
      };
    case MANAGE_STOCKINVOICE_PAYMENT_SUCCESS:
      return {
        ...state,
        invoice_loading: false,
        invoice_success: action.payload,
      };

    case MANAGE_STOCKINVOICE_PAYMENT_ERROR:
      return {
        ...state,
        invoice_loading: false,
        invoice_error: action.payload,
      };

    case V2_MANAGE_STOCK_INVOICE_REQUEST:
      return {
        ...state,
        invoice_loading: true,
        invoice_error: null,
        invoice_success: null,
        invoice_action: action.type,
        invoice_request_type: action.request_type,
      };
    case V2_MANAGE_STOCK_INVOICE_SUCCESS:
      if (action.request_type === "CHECK_INVOICE") {
        return {
          ...state,
          invoice_loading: false,
          check_invoice: action.payload,
        };
      }
    case V2_MANAGE_STOCK_INVOICE_ERROR:
      return {
        ...state,
        invoice_loading: false,
        invoice_error: action.payload,
      };

    default:
      return { ...state };
  }
};
