import {
  MANAGE_ONLINE_DELIVERSTATUS_ERROR,
  MANAGE_ONLINE_DELIVERSTATUS_REQUEST,
  MANAGE_ONLINE_DELIVERSTATUS_SUCCESS,
  MANAGE_ONLINE_ORDERSTATUS_ERROR,
  MANAGE_ONLINE_ORDERSTATUS_REQUEST,
  MANAGE_ONLINE_ORDERSTATUS_SUCCESS,
  ONLINE_ORDER_DETAILS_ERROR,
  ONLINE_ORDER_DETAILS_REQUEST,
  ONLINE_ORDER_DETAILS_SUCCESS,
  ONLINE_ORDER_LIST_ERROR,
  ONLINE_ORDER_LIST_REQUEST,
  ONLINE_ORDER_LIST_SUCCESS,
  ONLINE_PHARMACY_ERROR,
  ONLINE_PHARMACY_REQUEST,
  ONLINE_PHARMACY_SUCCESS,
} from "../actions";

export const onlineOrderListRequest = (request) => ({
  type: ONLINE_ORDER_LIST_REQUEST,
  payload: request,
});
export const onlineOrderListSuccess = (response) => ({
  type: ONLINE_ORDER_LIST_SUCCESS,
  payload: response,
});
export const onlineOrderListError = (message) => ({
  type: ONLINE_ORDER_LIST_ERROR,
  payload: message,
});

export const onlineOrderDetailsRequest = (request) => ({
  type: ONLINE_ORDER_DETAILS_REQUEST,
  payload: request,
});
export const onlineOrderDetailsSuccess = (response) => ({
  type: ONLINE_ORDER_DETAILS_SUCCESS,
  payload: response,
});
export const onlineOrderDetailsError = (message) => ({
  type: ONLINE_ORDER_DETAILS_ERROR,
  payload: message,
});

export const onlinePharmacyRequest = (request) => ({
  type: ONLINE_PHARMACY_REQUEST,
  payload: request,
});
export const onlinePharmacySuccess = (response) => ({
  type: ONLINE_PHARMACY_SUCCESS,
  payload: response,
});
export const onlinePharmacyError = (message) => ({
  type: ONLINE_PHARMACY_ERROR,
  payload: message,
});

export const manageOnlineOrderStatusRequest = (request) => ({
  type: MANAGE_ONLINE_ORDERSTATUS_REQUEST,
  payload: request,
});
export const manageOnlineOrderStatusSuccess = (response) => ({
  type: MANAGE_ONLINE_ORDERSTATUS_SUCCESS,
  payload: response,
});
export const manageOnlineOrderStatusError = (message) => ({
  type: MANAGE_ONLINE_ORDERSTATUS_ERROR,
  payload: message,
});

export const manageOnlineDeliverStatusRequest = (request) => ({
  type: MANAGE_ONLINE_DELIVERSTATUS_REQUEST,
  payload: request,
});
export const manageOnlineDeliverStatusSuccess = (response) => ({
  type: MANAGE_ONLINE_DELIVERSTATUS_SUCCESS,
  payload: response,
});
export const manageOnlineDeliverStatusError = (message) => ({
  type: MANAGE_ONLINE_DELIVERSTATUS_ERROR,

  payload: message,
});
