import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { objectToQueryString } from "../../helpers/Utils";
import {
  MANAGE_STAFF_REQUEST,
  STAFF_DETAILS_REQUEST,
  STAFF_LIST_REQUEST,
  UPDATE_PROFILEPIC_REQUEST,
} from "../actions";
import {
  GET,
  POST,
  DELETE,
  LIST,
  PATCH,
  FORMDATA_POST,
  FORMDATA_PATCH,
} from "../api";
import {
  manageStaffError,
  manageStaffSuccess,
  staffDetailsError,
  staffDetailsSuccess,
  staffListError,
  staffListSuccess,
  updateProfilePicError,
  updateProfilePicSuccess,
} from "./action";

export function* watchManageStaff() {
  yield takeLatest(MANAGE_STAFF_REQUEST, manageStaff);
}

function* manageStaff({ payload }) {
  const url = `/staff`;
  try {
    const response = yield call(
      payload && payload.staff_id ? PATCH : POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageStaffError(response.message));
    } else {
      yield put(manageStaffSuccess(response));
    }
  } catch (error) {
    yield put(manageStaffError(error));
  }
}

export function* watchStaffList() {
  yield takeLatest(STAFF_LIST_REQUEST, staffList);
}

function* staffList({ payload }) {
  const url = `/staff?${objectToQueryString(payload)}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(staffListError(response.message));
    } else {
      yield put(staffListSuccess(response));
    }
  } catch (error) {
    yield put(staffListError(error));
  }
}

export function* watchStaffDetails() {
  yield takeLatest(STAFF_DETAILS_REQUEST, staffDetails);
}

function* staffDetails({ payload }) {
  const url = `/staff/info/${payload}`;
  try {
    const response = yield call(GET, url, payload);
    if (response && response.is_error) {
      yield put(staffDetailsError(response.message));
    } else {
      yield put(staffDetailsSuccess(response));
    }
  } catch (error) {
    yield put(staffDetailsError(error));
  }
}

export function* watchUpdateProfilePic() {
  yield takeLatest(UPDATE_PROFILEPIC_REQUEST, updateProfilePic);
}

function* updateProfilePic({ payload }) {
  const url = `/user/profilepic`;
  try {
    const response = yield call(FORMDATA_PATCH, url, payload);
    if (response && response.is_error) {
      yield put(updateProfilePicError(response.message));
    } else {
      yield put(updateProfilePicSuccess(response));
    }
  } catch (error) {
    yield put(updateProfilePicError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchManageStaff),
    fork(watchStaffList),
    fork(watchStaffDetails),
    fork(watchUpdateProfilePic),
  ]);
}
