import decode from "jwt-decode";
import Cookies from "universal-cookie";

export const setCurrentUser = (user) => {
  const cookies = new Cookies();

  try {
    if (user) {
      let d = new Date();
      d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
      cookies.set("access_key", `${user}`, {
        path: "/",
        expires: d,
      });
    } else {
      cookies.remove("access_key", { path: "/" });
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentUser -> error", error);
  }
};

export const CheckAuth = () => {
  const cookies = new Cookies();
  const current_user = cookies.get("access_key");
  if (!current_user) {
    cookies.remove("access_key", { path: "/" });
    return false;
  }

  try {
    // let token = current_user.split(':')[1];
    let token = current_user;
    if (token) {
      const { exp } = decode(token);
      if (exp < new Date().getTime() / 1000) {
        cookies.remove("access_key", { path: "/" });
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  } catch (e) {}
};

export const token = () => {
  const cookies = new Cookies();
  const emp_session = cookies.get("access_key");
  if (!emp_session) {
    cookies.remove("access_key", { path: "/" });
    return;
  }

  return emp_session;
};

export const header = () => {
  return {
    headers: {
      Authorization: "Bearer " + token(),
    },
  };
};

export const Multipartheader = () => {
  return {
    headers: {
      Authorization: "Bearer " + token(),
      "content-type": "multipart/form-data",
      Accept: "application/json",
      processData: false,
    },
  };
};

export const user_data = () => {
  const cookies = new Cookies();
  const current_user = cookies.get("access_key");
  if (!current_user) {
    cookies.remove("access_key", { path: "/" });
    return {};
  }

  try {
    // let token = current_user.split(':')[1];
    let token = current_user;
    if (token) {
      return decode(token);
    } else {
      return {};
    }
  } catch (e) {}
};

export const objectToQueryString = (obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};
export const modalStyles = {
  content: {
    background: "#fff",
    left: "50%",
    width: "90%",
    height: "auto",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    top: window.screen.width <= 767 ? "85%" : "50%",
  },
};

export const TablecustomStyles = {
  rows: {
    style: {
      minHeight: "30px", // override the row height
      fontWeight: "bold",
    },
  },
  headCells: {
    style: {
      paddingLeft: "4px", // override the cell padding for head cells
      paddingRight: "4px",
      background: "#eee",
      fontWeight: "bold",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "4px",
    },
  },
};

export const ModalDefaultsMd = {
  overlayStyle: {
    zIndex: 9999,
    overflowY: "scroll",
  },
  contentStyle: {
    padding: "20px",
    borderRadius: "4px",
    background: "#fff",
    overflowY: "scroll",
    // height: "70%",
    width: "70%",
  },
  closeOnEscape: false,
  closeOnDocumentClick: false,
  lockScroll: false,
};

export const ModalDefaults = {
  overlayStyle: {
    zIndex: 9999,
    overflowY: "scroll",
  },
  contentStyle: {
    padding: "20px",
    borderRadius: "4px",
    background: "#fff",
    overflowY: "scroll",
  },
  closeOnEscape: false,
  closeOnDocumentClick: false,
  lockScroll: false,
};

export const ModalDefaultsXL = {
  overlayStyle: {
    zIndex: 9999,
    overflowY: "scroll",
  },
  contentStyle: {
    padding: "20px",
    borderRadius: "4px",
    background: "#fff",
    width: "95%",
    height: "95%",
    overflowY: "scroll",
  },
  closeOnEscape: false,
  closeOnDocumentClick: false,
  lockScroll: false,
};

export const AutoCompleteOff = () => {
  if (document.getElementsByTagName) {
    var inputElements = document.getElementsByTagName("input");
    for (var i = 0; inputElements[i]; i++) {
      if (
        inputElements[i].className &&
        inputElements[i].className.indexOf("disableAutoComplete") != -1
      ) {
        inputElements[i].setAttribute("autocomplete", "off");
      }
    }
  }
};

export const removeObjectNulls = (data) => {
  let obj = {};
  Object.keys(data).map((key) => {
    if (data[key]) {
      obj[key] = data[key];
    }
  });

  return obj;
};
