import {
  CLEAR_STAFF_DEFAULTS,
  MANAGE_STAFF_ERROR,
  MANAGE_STAFF_REQUEST,
  MANAGE_STAFF_SUCCESS,
  STAFF_DETAILS_ERROR,
  STAFF_DETAILS_REQUEST,
  STAFF_DETAILS_SUCCESS,
  STAFF_LIST_ERROR,
  STAFF_LIST_REQUEST,
  STAFF_LIST_SUCCESS,
  UPDATE_PROFILEPIC_ERROR,
  UPDATE_PROFILEPIC_REQUEST,
  UPDATE_PROFILEPIC_SUCCESS,
} from "../actions";
export const clearStaffDefaults = (message) => ({
  type: CLEAR_STAFF_DEFAULTS,
  payload: message,
});
export const manageStaffRequest = (request) => ({
  type: MANAGE_STAFF_REQUEST,
  payload: request,
});
export const manageStaffSuccess = (response) => ({
  type: MANAGE_STAFF_SUCCESS,
  payload: response,
});
export const manageStaffError = (message) => ({
  type: MANAGE_STAFF_ERROR,
  payload: message,
});

export const staffListRequest = (request) => ({
  type: STAFF_LIST_REQUEST,
  payload: request,
});
export const staffListSuccess = (response) => ({
  type: STAFF_LIST_SUCCESS,
  payload: response,
});
export const staffListError = (message) => ({
  type: STAFF_LIST_ERROR,
  payload: message,
});

export const staffDetailsRequest = (request) => ({
  type: STAFF_DETAILS_REQUEST,
  payload: request,
});
export const staffDetailsSuccess = (response) => ({
  type: STAFF_DETAILS_SUCCESS,
  payload: response,
});
export const staffDetailsError = (message) => ({
  type: STAFF_DETAILS_ERROR,
  payload: message,
});

export const updateProfilePicRequest = (request) => ({
  type: UPDATE_PROFILEPIC_REQUEST,
  payload: request,
});
export const updateProfilePicSuccess = (response) => ({
  type: UPDATE_PROFILEPIC_SUCCESS,
  payload: response,
});
export const updateProfilePicError = (message) => ({
  type: UPDATE_PROFILEPIC_ERROR,
  payload: message,
});
