import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  AUTO_PATIENT_SUGGEST_REQUEST,
  MANAGE_PATIENT_REQUEST,
} from "../actions";
import {
  GET,
  POST,
  DELETE,
  LIST,
  PATCH,
  FORMDATA_PATCH,
  FORMDATA_POST,
} from "../api";
import {
  autoPatientRequest,
  autoPatientSuccess,
  managePatientError,
  managePatientSuccess,
} from "./action";

export function* watchManagePatient() {
  yield takeLatest(MANAGE_PATIENT_REQUEST, managePatient);
}

function* managePatient({ payload }) {
  const url = "/patient";
  try {
    const response = yield call(
      payload.id > 0 ? FORMDATA_PATCH : FORMDATA_POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(managePatientError(response.message));
    } else {
      yield put(managePatientSuccess(response));
    }
  } catch (error) {
    yield put(managePatientError(error));
  }
}

export function* watchAutoPatient() {
  yield takeLatest(AUTO_PATIENT_SUGGEST_REQUEST, autoPatient);
}

function* autoPatient({ payload }) {
  const url = `/utils/autosuggest?search=${payload}&type=patients`;
  try {
    const response = yield call(GET, url);
    if (response && response.is_error) {
      yield put(autoPatientRequest(response.message));
    } else {
      response.map(async (x, i) => {
        x["value"] = x.id;
        x["label"] = x.patient_name;
        return await x;
      });
      yield put(autoPatientSuccess(response));
    }
  } catch (error) {
    yield put(autoPatientRequest(error));
  }
}

export default function* rootSaga() {
  yield all([fork(watchManagePatient), fork(watchAutoPatient)]);
}
