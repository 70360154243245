import {
  MANAGE_IDC_TICKET_ERROR,
  MANAGE_IDC_TICKET_REQUEST,
  MANAGE_IDC_TICKET_SUCCESS,
} from "../actions";

const INIT_STATE = {
  ticket_loading: false,
  ticket_error: null,
  ticket_success: null,
  ticket_action: null,
  ticket_request_type: null,
  ticket_list: [],
  ticket: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MANAGE_IDC_TICKET_REQUEST:
      return {
        ...state,
        ticket_loading: false,
        ticket_error: null,
        ticket_success: null,
        ticket_action: action.type,
        ticket_request_type: action.request_type,
      };
    case MANAGE_IDC_TICKET_SUCCESS:
      if (action.request_type == "ADD") {
        return {
          ...state,
          ticket_loading: false,
          ticket_success: action.payload,
        };
      } else if (action.request_type == "USERS_TICKET") {
        return {
          ...state,
          ticket_loading: false,
          ticket_list: action.payload,
        };
      }
    case MANAGE_IDC_TICKET_ERROR:
      return {
        ...state,
        ticket_loading: false,
        ticket_error: action.payload,
      };

    default:
      return { ...state };
  }
};
