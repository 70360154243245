import {
  BRAND_MASTER_ERROR,
  BRAND_MASTER_LIST_ERROR,
  BRAND_MASTER_LIST_REQUEST,
  BRAND_MASTER_LIST_SUCCESS,
  BRAND_MASTER_REQUEST,
  BRAND_MASTER_SUCCESS,
  CLEAR_INVENTORY_DEFAULTS,
  DELETE_ITEM_ERROR,
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  ITEM_DETAILS_ERROR,
  ITEM_DETAILS_REQUEST,
  ITEM_DETAILS_SUCCESS,
  ITEM_LIST_ERROR,
  ITEM_LIST_REQUEST,
  ITEM_LIST_SUCCESS,
  MANAGE_BRAND_MASTER_ERROR,
  MANAGE_BRAND_MASTER_REQUEST,
  MANAGE_BRAND_MASTER_SUCCESS,
  MANAGE_CENTRALSTOCK_ERROR,
  MANAGE_CENTRALSTOCK_REQUEST,
  MANAGE_CENTRALSTOCK_SUCCESS,
  MANAGE_ITEM_ERROR,
  MANAGE_ITEM_REQUEST,
  MANAGE_ITEM_SUCCESS,
  UPDATE_CETRAL_STOCK_DETAILS_ERROR,
  UPDATE_CETRAL_STOCK_DETAILS_REQUEST,
  UPDATE_CETRAL_STOCK_DETAILS_SUCCESS,
} from "../actions";
export const clearInventoryDefaults = (message) => ({
  type: CLEAR_INVENTORY_DEFAULTS,
  payload: message,
});
export const manageBrandMasterRequest = (request) => ({
  type: MANAGE_BRAND_MASTER_REQUEST,
  payload: request,
});
export const manageBrandMasterSuccess = (response) => ({
  type: MANAGE_BRAND_MASTER_SUCCESS,
  payload: response,
});
export const manageBrandMasterError = (message) => ({
  type: MANAGE_BRAND_MASTER_ERROR,
  payload: message,
});

export const brandMasterRequest = (request) => ({
  type: BRAND_MASTER_REQUEST,
  payload: request,
});
export const brandMasterSuccess = (response) => ({
  type: BRAND_MASTER_SUCCESS,
  payload: response,
});
export const brandMasterError = (message) => ({
  type: BRAND_MASTER_ERROR,
  payload: message,
});

export const brandMasterListRequest = (request) => ({
  type: BRAND_MASTER_LIST_REQUEST,
  payload: request,
});
export const brandMasterListSuccess = (response) => ({
  type: BRAND_MASTER_LIST_SUCCESS,
  payload: response,
});
export const brandMasterListError = (message) => ({
  type: BRAND_MASTER_LIST_ERROR,
  payload: message,
});

export const manageItemRequest = (request) => ({
  type: MANAGE_ITEM_REQUEST,
  payload: request,
});
export const manageItemSuccess = (response) => ({
  type: MANAGE_ITEM_SUCCESS,
  payload: response,
});
export const manageItemError = (message) => ({
  type: MANAGE_ITEM_ERROR,
  payload: message,
});
export const deleteItemRequest = (request) => ({
  type: DELETE_ITEM_REQUEST,
  payload: request,
});
export const deleteItemSuccess = (response) => ({
  type: DELETE_ITEM_SUCCESS,
  payload: response,
});
export const deleteItemError = (message) => ({
  type: DELETE_ITEM_ERROR,
  payload: message,
});
export const itemListRequest = (request) => ({
  type: ITEM_LIST_REQUEST,
  payload: request,
});
export const itemListSuccess = (response) => ({
  type: ITEM_LIST_SUCCESS,
  payload: response,
});
export const itemListError = (message) => ({
  type: ITEM_LIST_ERROR,
  payload: message,
});

export const itemDetailsRequest = (request) => ({
  type: ITEM_DETAILS_REQUEST,
  payload: request,
});
export const itemDetailsSuccess = (response) => ({
  type: ITEM_DETAILS_SUCCESS,
  payload: response,
});
export const itemDetailsError = (message) => ({
  type: ITEM_DETAILS_ERROR,
  payload: message,
});

export const updateCentralStockDetailsRequest = (request) => ({
  type: UPDATE_CETRAL_STOCK_DETAILS_REQUEST,
  payload: request,
});
export const updateCentralStockDetailsSuccess = (response) => ({
  type: UPDATE_CETRAL_STOCK_DETAILS_SUCCESS,
  payload: response,
});
export const updateCentralStockDetailsError = (message) => ({
  type: UPDATE_CETRAL_STOCK_DETAILS_ERROR,
  payload: message,
});

export const manageCentralStockRequest = (request) => ({
  type: MANAGE_CENTRALSTOCK_REQUEST,
  payload: request,
  request_type: request.request_type,
});
export const manageCentralStockSuccess = (response, request_type) => ({
  type: MANAGE_CENTRALSTOCK_SUCCESS,
  payload: response,
  request_type: request_type,
});
export const manageCentralStockError = (message) => ({
  type: MANAGE_CENTRALSTOCK_ERROR,
  payload: message,
});
