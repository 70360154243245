import {
  CENTER_STOCK_LIST_REQUEST,
  CENTER_STOCK_LIST_SUCCESS,
  MANAGE_CENTER_ERROR,
  MANAGE_CENTER_REQUEST,
  MANAGE_CENTER_STOCK_ERROR,
  MANAGE_CENTER_STOCK_REQUEST,
  MANAGE_CENTER_STOCK_SUCCESS,
  MANAGE_CENTER_SUCCESS,
  MANAGE_CENTRALSTOCK_ERROR,
  MANAGE_CENTRALSTOCK_REQUEST,
  MANAGE_CENTRALSTOCK_SUCCESS,
  MANAGE_STOCKREQUEST_DELIVER_ERROR,
  MANAGE_STOCKREQUEST_DELIVER_REQUEST,
  MANAGE_STOCKREQUEST_DELIVER_SUCCESS,
  MANAGE_STOCKREQUEST_ERROR,
  MANAGE_STOCKREQUEST_REQUEST,
  MANAGE_STOCKREQUEST_SUCCESS,
  MANAGE_STOCK_ERROR,
  MANAGE_STOCK_REQUEST,
  MANAGE_STOCK_SUCCESS,
  STOCKREQUEST_LIST_ERROR,
  STOCKREQUEST_LIST_REQUEST,
  STOCKREQUEST_LIST_SUCCESS,
  STOCKSTATUS_LIST_REQUEST,
  STOCKSTATUS_LIST_SUCCESS,
  STOCKSTATUS_LIST_ERROR,
  STOCK_REQUEST_DELIVER_INFO_ERROR,
  STOCK_REQUEST_DELIVER_INFO_REQUEST,
  STOCK_REQUEST_DELIVER_INFO_SUCCESS,
  STOCK_REQUEST_INFO_ERROR,
  STOCK_REQUEST_INFO_REQUEST,
  STOCK_REQUEST_INFO_SUCCESS,
  UPDATE_DELIVER_REQUEST_STATUS_ERROR,
  UPDATE_DELIVER_REQUEST_STATUS_REQUEST,
  UPDATE_DELIVER_REQUEST_STATUS_SUCCESS,
  UPDATE_STOCK_REQUEST_STATUS_ERROR,
  UPDATE_STOCK_REQUEST_STATUS_REQUEST,
  UPDATE_STOCK_REQUEST_STATUS_SUCCESS,
  UPLOAD_CENTER_STOCK_ERROR,
  UPLOAD_CENTER_STOCK_REQUEST,
  UPLOAD_CENTER_STOCK_SUCCESS,
} from "../actions";

export const manageStockRequestRequest = (request) => ({
  type: MANAGE_STOCKREQUEST_REQUEST,
  payload: request,
});
export const manageStockRequestSuccess = (response) => ({
  type: MANAGE_STOCKREQUEST_SUCCESS,
  payload: response,
});
export const manageStockRequestError = (message) => ({
  type: MANAGE_STOCKREQUEST_ERROR,
  payload: message,
});

export const stockRequestListRequest = (request) => ({
  type: STOCKREQUEST_LIST_REQUEST,
  payload: request,
});
export const stockRequestListSuccess = (response) => ({
  type: STOCKREQUEST_LIST_SUCCESS,
  payload: response,
});
export const stockRequestListError = (message) => ({
  type: STOCKREQUEST_LIST_ERROR,
  payload: message,
});

export const stockStatusRequestListRequest = (request) => ({
  type: STOCKSTATUS_LIST_REQUEST,
  payload: request,
  request_type: request.request_type,
});
export const stockStatusRequestListSuccess = (response) => ({
  type: STOCKSTATUS_LIST_SUCCESS,
  payload: response,
});
export const stockStatusRequestListError = (message) => ({
  type: STOCKSTATUS_LIST_ERROR,
  payload: message,
});

export const stockRequestInfoRequest = (request) => ({
  type: STOCK_REQUEST_INFO_REQUEST,
  payload: request,
});
export const stockRequestInfoSuccess = (response) => ({
  type: STOCK_REQUEST_INFO_SUCCESS,
  payload: response,
});
export const stockRequestInfoError = (message) => ({
  type: STOCK_REQUEST_INFO_ERROR,
  payload: message,
});

export const manageStockRequestDeliverRequest = (request) => ({
  type: MANAGE_STOCKREQUEST_DELIVER_REQUEST,
  payload: request,
});
export const manageStockRequestDeliverSuccess = (response) => ({
  type: MANAGE_STOCKREQUEST_DELIVER_SUCCESS,
  payload: response,
});
export const manageStockRequestDeliverError = (message) => ({
  type: MANAGE_STOCKREQUEST_DELIVER_ERROR,
  payload: message,
});

export const stockRequestDeliverInfoRequest = (request) => ({
  type: STOCK_REQUEST_DELIVER_INFO_REQUEST,
  payload: request,
});
export const stockRequestDeliverInfoSuccess = (response) => ({
  type: STOCK_REQUEST_DELIVER_INFO_SUCCESS,
  payload: response,
});
export const stockRequestDeliverInfoError = (message) => ({
  type: STOCK_REQUEST_DELIVER_INFO_ERROR,
  payload: message,
});

export const manageStockRequest = (request) => ({
  type: MANAGE_STOCK_REQUEST,
  payload: request,
});
export const manageStockSuccess = (response) => ({
  type: MANAGE_STOCK_SUCCESS,
  payload: response,
});
export const manageStockError = (message) => ({
  type: MANAGE_STOCK_ERROR,
  payload: message,
});

export const updateStockRequestStatusRequest = (request) => ({
  type: UPDATE_STOCK_REQUEST_STATUS_REQUEST,
  payload: request,
});
export const updateStockRequestStatusSuccess = (response) => ({
  type: UPDATE_STOCK_REQUEST_STATUS_SUCCESS,
  payload: response,
});
export const updateStockRequestStatusError = (message) => ({
  type: UPDATE_STOCK_REQUEST_STATUS_ERROR,
  payload: message,
});

export const updateDeliverRequestStatusRequest = (request) => ({
  type: UPDATE_DELIVER_REQUEST_STATUS_REQUEST,
  payload: request,
});
export const updateDeliverRequestStatusSuccess = (response) => ({
  type: UPDATE_DELIVER_REQUEST_STATUS_SUCCESS,
  payload: response,
});
export const updateDeliverRequestStatusError = (message) => ({
  type: UPDATE_DELIVER_REQUEST_STATUS_ERROR,
  payload: message,
});

export const centerStockListRequest = (request) => ({
  type: CENTER_STOCK_LIST_REQUEST,
  payload: request,
});
export const centerStockListSuccess = (response) => ({
  type: CENTER_STOCK_LIST_SUCCESS,
  payload: response,
});
export const centerStockListError = (message) => ({
  type: CENTER_STOCK_LIST_SUCCESS,
  payload: message,
});

export const manageCenterRequest = (request) => ({
  type: MANAGE_CENTER_REQUEST,
  payload: request,
  request_type: request.request_type,
});
export const manageCenterSuccess = (response, request_type) => ({
  type: MANAGE_CENTER_SUCCESS,
  payload: response,
  request_type: request_type,
});
export const manageCenterError = (message) => ({
  type: MANAGE_CENTER_ERROR,
  payload: message,
});

export const uploadCenterStockRequest = (request) => ({
  type: UPLOAD_CENTER_STOCK_REQUEST,
  payload: request,
  request_type: request.request_type,
});
export const uploadCenterStockSuccess = (response, request_type) => ({
  type: UPLOAD_CENTER_STOCK_SUCCESS,
  payload: response,
  request_type: request_type,
});
export const uploadCenterStockError = (message) => ({
  type: UPLOAD_CENTER_STOCK_ERROR,
  payload: message,
});

export const manageCenterStockRequest = (request) => ({
  type: MANAGE_CENTER_STOCK_REQUEST,
  payload: request,
  request_type: request.request_type,
});
export const manageCenterStockSuccess = (response, request_type) => ({
  type: MANAGE_CENTER_STOCK_SUCCESS,
  payload: response,
  request_type: request_type,
});
export const manageCenterStockError = (message) => ({
  type: MANAGE_CENTER_STOCK_ERROR,
  payload: message,
});
