import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import { ModalDefaults, ModalDefaultsMd } from "../helpers/Utils";
import {
  manageIDCTicketRequest,
  MANAGE_IDC_TICKET_REQUEST,
  setRequest,
} from "../redux/actions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NotificationManager } from "react-notifications";
export const Ticket = (props) => {
  const {
    request,
    ticket_loading,
    ticket_success,
    ticket_error,
    ticket_action,
    ticket_request_type,
  } = props;
  const [state, setState] = useState({
    file: null,
    file_preview: null,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (ticket_action == MANAGE_IDC_TICKET_REQUEST) {
      if (ticket_request_type == "ADD") {
        if (ticket_success) {
          window.location.reload(true);
          NotificationManager.success(
            JSON.stringify(ticket_success.message),
            "Success",
            1000
          );
        } else if (ticket_error) {
          NotificationManager.error(
            JSON.stringify(ticket_success),
            "Error",
            1000
          );
        }
      }
    }
  }, [ticket_loading]);

  const saveTicket = (data) => {
    props.manageIDCTicketAsync({
      request_type: "ADD",
      file: state.file,
      ...data,
    });
  };
  const fileChange = (e) => {
    let file = e.target.files[0];
    if (file) {
      let preview = URL.createObjectURL(file);
      setState({ ...state, file: file, file_preview: preview });
    } else {
      setState({ ...state, file: null, file_preview: null });
      e.target.value = null;
    }
  };

  return (
    <Popup {...ModalDefaultsMd} open={request.support_modal}>
      <form onSubmit={handleSubmit(saveTicket)}>
        <div className="row">
          <div className="col-md-12 col-lg-12 mb-3">
            <label>Title:</label>
            <input
              className="form-control"
              placeholder="Title"
              name="title"
              {...register("title")}
              autoComplete="off"
            />
          </div>
          <div className="col-md-12 col-lg-12 mb-3">
            <label>Description:</label>
            <textarea
              className="form-control"
              placeholder="Decription"
              name="full_desc"
              {...register("full_desc")}
              autoComplete="off"
            />
          </div>
          <div className="col-md-12 col-lg-12 mb-3">
            <label>Priority:</label>
            <select
              className="form-control"
              name="priority"
              {...register("priority")}
            >
              <option value="Low">Low</option>
              <option value="Moderate">Moderate</option>
              <option value="High">High</option>
            </select>
          </div>
          <div className="col-md-12 col-lg-12 mb-3">
            <label>Upload Screen shots:</label>
            <input
              type="file"
              id="file"
              onChange={(e) => fileChange(e)}
              accept=".png,.jpg,.jpeg"
            />
          </div>
          {state.file && state.file_preview && (
            <div className="col-md-12 col-lg-12 mb-3">
              <img
                src={state.file_preview}
                height="70"
                width="70"
                style={{
                  borderRadius: "10px",
                }}
              />
              <span
                onClick={() => {
                  document.getElementById("file").value = null;
                  setState({
                    ...state,
                    file: null,
                    file_preview: null,
                  });
                }}
                style={{
                  position: "absolute",
                  left: "10px",
                  color: "#fff",
                  cursor: "pointer",
                }}
                className="fa fa-close fa-2x"
              ></span>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-warning"
            type="button"
            onClick={() => {
              props.setRequestAsync({ ...request, support_modal: false });
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            type="submit"
            disabled={
              ticket_action == MANAGE_IDC_TICKET_REQUEST && ticket_loading
            }
          >
            Save
          </button>
        </div>
      </form>
    </Popup>
  );
};

const mapStateToProps = ({ setup_reducer, ticket_reducer }) => {
  const { request } = setup_reducer;
  const {
    ticket_loading,
    ticket_success,
    ticket_error,
    ticket_action,
    ticket_request_type,
  } = ticket_reducer;
  return {
    request,
    ticket_loading,
    ticket_success,
    ticket_error,
    ticket_action,
    ticket_request_type,
  };
};

const mapDispatchToProps = {
  setRequestAsync: setRequest,
  manageIDCTicketAsync: manageIDCTicketRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Ticket);
