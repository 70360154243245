import {
  CLEAR_STAFF_DEFAULTS,
  MANAGE_STAFF_ERROR,
  MANAGE_STAFF_REQUEST,
  MANAGE_STAFF_SUCCESS,
  STAFF_DETAILS_ERROR,
  STAFF_DETAILS_REQUEST,
  STAFF_DETAILS_SUCCESS,
  STAFF_LIST_ERROR,
  STAFF_LIST_REQUEST,
  STAFF_LIST_SUCCESS,
  UPDATE_PROFILEPIC_ERROR,
  UPDATE_PROFILEPIC_REQUEST,
  UPDATE_PROFILEPIC_SUCCESS,
} from "../actions";

const INIT_STATE = {
  staff_loading: false,
  staff_error: null,
  staff_success: null,
  staff_action: null,
  staff_list: [],
  staff: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_STAFF_DEFAULTS:
      return {
        ...state,
        staff_loading: false,
        staff_error: null,
        staff_success: null,
        staff_action: null,
      };
    case MANAGE_STAFF_REQUEST:
      return {
        ...state,
        staff_loading: true,
        staff_error: null,
        staff_success: null,
        staff_action: action.type,
      };
    case MANAGE_STAFF_SUCCESS:
      return {
        ...state,
        staff_loading: false,
        staff_success: action.payload,
      };
    case MANAGE_STAFF_ERROR:
      return {
        ...state,
        staff_loading: false,
        staff_error: action.payload,
      };

    case STAFF_LIST_REQUEST:
      return {
        ...state,
        staff_loading: true,
        staff_error: null,
        staff_success: null,
        staff_action: action.type,
        staff_list: [],
      };
    case STAFF_LIST_SUCCESS:
      return {
        ...state,
        staff_loading: false,
        staff_list: action.payload,
      };
    case STAFF_LIST_ERROR:
      return {
        ...state,
        staff_loading: false,
        staff_error: action.payload,
      };

    case STAFF_DETAILS_REQUEST:
      return {
        ...state,
        staff_loading: true,
        staff_error: null,
        staff_success: null,
        staff_action: action.type,
        staff: null,
      };
    case STAFF_DETAILS_SUCCESS:
      return {
        ...state,
        staff_loading: false,
        staff: action.payload,
      };
    case STAFF_DETAILS_ERROR:
      return {
        ...state,
        staff_loading: false,
        staff_error: action.payload,
      };

    case UPDATE_PROFILEPIC_REQUEST:
      return {
        ...state,
        staff_loading: true,
        staff_error: null,
        staff_success: null,
        staff_action: action.type,
      };
    case UPDATE_PROFILEPIC_SUCCESS:
      return {
        ...state,
        staff_loading: false,
        staff_success: action.payload,
      };
    case UPDATE_PROFILEPIC_ERROR:
      return {
        ...state,
        staff_loading: false,
        staff_error: action.payload,
      };
    default:
      return { ...state };
  }
};
