import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { objectToQueryString } from "../../helpers/Utils";
import { DOCTORSLOT_LIST_REQUEST, MANAGE_DOCTORSLOT_REQUEST } from "../actions";
import { GET, POST, DELETE, LIST, PATCH } from "../api";
import {
  doctorSlotListError,
  doctorSlotListSuccess,
  manageDoctorSlotError,
  manageDoctorSlotSuccess,
} from "./action";

export function* watchManageDoctorSlot() {
  yield takeLatest(MANAGE_DOCTORSLOT_REQUEST, manageDoctor);
}

function* manageDoctor({ payload }) {
  const url = `/doctor/doctorslot`;
  try {
    const response = yield call(
      payload && payload.slot_id ? PATCH : POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageDoctorSlotError(response.message));
    } else {
      yield put(manageDoctorSlotSuccess(response));
    }
  } catch (error) {
    yield put(manageDoctorSlotError(error));
  }
}

export function* watchManageDoctorSlotList() {
  yield takeLatest(DOCTORSLOT_LIST_REQUEST, doctorSlotList);
}

function* doctorSlotList({ payload }) {
  const url = `/doctor/doctorslot?${objectToQueryString(payload)}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(doctorSlotListError(response.message));
    } else {
      yield put(doctorSlotListSuccess(response));
    }
  } catch (error) {
    yield put(doctorSlotListError(error));
  }
}

export default function* rootSaga() {
  yield all([fork(watchManageDoctorSlot), fork(watchManageDoctorSlotList)]);
}
