import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { objectToQueryString } from "../../helpers/Utils";
import {
  CENTER_STOCK_LIST_REQUEST,
  manageCenterError,
  manageCenterSuccess,
  MANAGE_CENTER_REQUEST,
  MANAGE_CENTER_STOCK_REQUEST,
  MANAGE_STOCKREQUEST_DELIVER_REQUEST,
  MANAGE_STOCKREQUEST_REQUEST,
  MANAGE_STOCK_REQUEST,
  STOCKREQUEST_LIST_REQUEST,
  STOCKSTATUS_LIST_REQUEST,
  STOCK_REQUEST_DELIVER_INFO_REQUEST,
  STOCK_REQUEST_INFO_REQUEST,
  UPDATE_DELIVER_REQUEST_STATUS_REQUEST,
  UPDATE_STOCK_REQUEST_STATUS_REQUEST,
  UPLOAD_CENTER_STOCK_REQUEST,
} from "../actions";
import { GET, POST, DELETE, LIST, PATCH } from "../api";
import {
  centerStockListError,
  centerStockListSuccess,
  manageCenterStockError,
  manageCenterStockSuccess,
  manageStockError,
  manageStockRequestDeliverError,
  manageStockRequestDeliverSuccess,
  manageStockRequestError,
  manageStockRequestSuccess,
  manageStockSuccess,
  stockRequestDeliverInfoError,
  stockRequestDeliverInfoSuccess,
  stockRequestInfoError,
  stockRequestInfoSuccess,
  stockRequestListError,
  stockRequestListSuccess,
  stockStatusRequestListSuccess,
  stockStatusRequestListError,
  updateDeliverRequestStatusError,
  updateDeliverRequestStatusSuccess,
  updateStockRequestStatusError,
  updateStockRequestStatusSuccess,
  uploadCenterStockError,
  uploadCenterStockSuccess,
} from "./action";
export function* watchManageStockRequest() {
  yield takeLatest(MANAGE_STOCKREQUEST_REQUEST, manageStockRequest);
}

function* manageStockRequest({ payload }) {
  const url = `/center/stockrequest`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageStockRequestError(response.message));
    } else {
      yield put(manageStockRequestSuccess(response));
    }
  } catch (error) {
    yield put(manageStockRequestError(error));
  }
}

export function* watchStockRequestList() {
  yield takeLatest(STOCKREQUEST_LIST_REQUEST, stockRequestList);
}

function* stockRequestList({ payload }) {
  const url = `/center/stockrequest?${objectToQueryString(payload)}`;
  try {
    const response = yield call(GET, url, payload);
    if (response && response.is_error) {
      yield put(stockRequestListError(response.message));
    } else {
      yield put(stockRequestListSuccess(response));
    }
  } catch (error) {
    yield put(stockRequestListError(error));
  }
}
export function* watchStockStatusRequestList() {
  yield takeLatest(STOCKSTATUS_LIST_REQUEST, stockStatusRequestList);
}

function* stockStatusRequestList({ payload }) {
  let url, response;
  try {
    if (payload.request_type === "ZERO_STOCK_LIST_REQUEST") {
      url = `/center/ZeroStockRequest`;
      response = yield call(GET, url, payload);
    } else if (payload.request_type === "LOW_STOCK_LIST_REQUEST") {
      url = `/center/LowStockRequest`;
      response = yield call(GET, url, payload);
    } else if (payload.request_type === "EXPIRING_STOCK_LIST_REQUEST") {
      url = `/center/ExpiringStockRequest`;
      response = yield call(GET, url, payload);
    }
    if (response && response.is_error) {
      yield put(stockStatusRequestListError(response.message));
    } else {
      yield put(stockStatusRequestListSuccess(response));
    }
  } catch (error) {
    yield put(stockStatusRequestListError(error));
  }
}

export function* watchStockRequestInfo() {
  yield takeLatest(STOCK_REQUEST_INFO_REQUEST, stockRequestInfo);
}

function* stockRequestInfo({ payload }) {
  const url = `/center/stockrequestinfo?${objectToQueryString(payload)}`;
  try {
    const response = yield call(GET, url, payload);
    if (response && response.is_error) {
      yield put(stockRequestInfoError(response.message));
    } else {
      yield put(stockRequestInfoSuccess(response));
    }
  } catch (error) {
    yield put(stockRequestInfoError(error));
  }
}

export function* watchManageStockRquestDeliver() {
  yield takeLatest(
    MANAGE_STOCKREQUEST_DELIVER_REQUEST,
    manageStockRequestDeliver
  );
}

function* manageStockRequestDeliver({ payload }) {
  const url = `/center/stockrequestdeliver`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageStockRequestDeliverError(response.message));
    } else {
      yield put(manageStockRequestDeliverSuccess(response));
    }
  } catch (error) {
    yield put(manageStockRequestDeliverError(error));
  }
}

export function* watchStockRequestDeliverInfo() {
  yield takeLatest(STOCK_REQUEST_DELIVER_INFO_REQUEST, stockRequestDeliverInfo);
}

function* stockRequestDeliverInfo({ payload }) {
  const url = `/center/StockRequestDeliverInfo?${objectToQueryString(payload)}`;
  try {
    const response = yield call(GET, url, payload);
    if (response && response.is_error) {
      yield put(stockRequestDeliverInfoError(response.message));
    } else {
      yield put(stockRequestDeliverInfoSuccess(response));
    }
  } catch (error) {
    yield put(stockRequestDeliverInfoError(error));
  }
}

export function* watchManageStock() {
  yield takeLatest(MANAGE_STOCK_REQUEST, manageStock);
}

function* manageStock({ payload }) {
  const url = `/center/stock`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageStockError(response.message));
    } else {
      yield put(manageStockSuccess(response));
    }
  } catch (error) {
    yield put(manageStockError(error));
  }
}

export function* watchUpdateStockRequestStatus() {
  yield takeLatest(
    UPDATE_STOCK_REQUEST_STATUS_REQUEST,
    updateStockRequestStatus
  );
}

function* updateStockRequestStatus({ payload }) {
  const url = "/inventory/updatestockrequeststatus";
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(updateStockRequestStatusError(response.message));
    } else {
      yield put(updateStockRequestStatusSuccess(response));
    }
  } catch (error) {
    yield put(updateStockRequestStatusError(error));
  }
}

export function* watchUpdateDeliverRequestStatus() {
  yield takeLatest(
    UPDATE_DELIVER_REQUEST_STATUS_REQUEST,
    udpateDeliverRequestStatus
  );
}

function* udpateDeliverRequestStatus({ payload }) {
  const url = "/inventory/updatedeliverrequeststatus";
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(updateDeliverRequestStatusError(response.message));
    } else {
      yield put(updateDeliverRequestStatusSuccess(response));
    }
  } catch (error) {
    yield put(updateDeliverRequestStatusError(error));
  }
}

export function* watchCenterStockList() {
  yield takeLatest(CENTER_STOCK_LIST_REQUEST, centerStockList);
}

function* centerStockList({ payload }) {
  const url = `/center/centerstock?${objectToQueryString(payload)}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(centerStockListError(response.message));
    } else {
      yield put(centerStockListSuccess(response));
    }
  } catch (error) {
    yield put(centerStockListError(error));
  }
}

export function* watchManageCenter() {
  yield takeLatest(MANAGE_CENTER_REQUEST, manageCenter);
}

function* manageCenter({ payload, request_type }) {
  let url;
  let response;
  if (request_type == "ADD") {
    url = `/center`;
    response = yield call(POST, url, payload);
  } else if (request_type == "EDIT") {
    url = `/center`;
    response = yield call(PATCH, url, payload);
  } else if (request_type == "LIST") {
    url = `/center`;
    response = yield call(GET, url, payload);
  } else {
    yield put(manageCenterError("Invalid URL"));
  }
  try {
    if (response && response.is_error) {
      yield put(manageCenterError(response.message));
    } else {
      yield put(manageCenterSuccess(response, request_type));
    }
  } catch (error) {
    yield put(manageCenterError(error));
  }
}

export function* watchUploadCenterStock() {
  yield takeLatest(UPLOAD_CENTER_STOCK_REQUEST, uploadCenterStock);
}

function* uploadCenterStock({ payload, request_type }) {
  let url;
  let response;
  if (request_type == "ADD") {
    url = `/center/uploadstockv2`;
    response = yield call(POST, url, payload);
  } else {
    yield put(uploadCenterStockError("Invalid URL"));
  }
  try {
    if (response && response.is_error) {
      yield put(uploadCenterStockError(response.message));
    } else {
      yield put(uploadCenterStockSuccess(response, request_type));
    }
  } catch (error) {
    yield put(uploadCenterStockError(error));
  }
}

export function* watchManageCenterStock() {
  yield takeLatest(MANAGE_CENTER_STOCK_REQUEST, manageCenterStock);
}

function* manageCenterStock({ payload, request_type }) {
  let url;
  let response;
  if (request_type == "INVOICE_ITEM_INFO") {
    url = `/center/invoiceiteminfo?${objectToQueryString(payload)}`;
    response = yield call(GET, url, payload);
  } else if (request_type == "RETURN_ITEM") {
    url = `/center/returnitem`;
    response = yield call(POST, url, payload);
  } else {
    yield put(manageCenterStockError("Invalid URL"));
  }
  try {
    if (response && response.is_error) {
      yield put(manageCenterStockError(response.message));
    } else {
      yield put(manageCenterStockSuccess(response, request_type));
    }
  } catch (error) {
    yield put(manageCenterStockError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchManageStockRequest),
    fork(watchStockRequestList),
    fork(watchStockStatusRequestList),
    fork(watchStockRequestInfo),
    fork(watchManageStockRquestDeliver),
    fork(watchStockRequestDeliverInfo),
    fork(watchManageStock),
    fork(watchUpdateStockRequestStatus),
    fork(watchUpdateDeliverRequestStatus),
    fork(watchCenterStockList),
    fork(watchManageCenter),
    fork(watchUploadCenterStock),
    fork(watchManageCenterStock),
  ]);
}
